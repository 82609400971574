/*
Avatar
*/
.avatar{
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  
  img {
    border-radius: 500px;
    max-width: 100%;
  }

  &.resizer {
    border-radius: 0;
    -webkit-clip-path: inset(0 0 0 0 round 500px);
    clip-path: inset(0 0 0 0 round 500px);
    overflow: hidden; 
    img {
      border-radius: 0;
      max-width: none;
      cursor: move;
    }
  }

  &.inline-initials {
    span.initials {
      position: static;
      top: auto;
      transform: none;
      right: auto;
      left: auto;
    }
  }

  span.initials {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    text-align: center;
  }

  span.img ~ span,
  img ~ span {
    // display: none;
    visibility: hidden;
  }

  span.overlay {
    display: none;
  }

  i{
    position: absolute;
    #{$direction_1}: 0;
    top: 0;
    width:  10px;
    height: 10px;
    margin: 1px;
    border-width:  2px;
    border-style:  solid;
    border-radius: 100%;
    background-color: #fff;
    &.no-border{
      width:  8px;
      height: 8px;
      margin: 2px;
      border-color: rgba(0,0,0,0.1);
    }
    &.avatar-center{
      top:  50%;
      margin: 0;
      margin-top: -4px;
      + img{
        @if ($rtl == true) {
      		margin: 0 24px 0 14px;
      	} @else {
      		margin: 0 14px 0 24px;
      	}
        // width: auto;
      }
    }
    &.avatar-top{
      left: 0;
      right: 0;
      top: 0;
      bottom: auto;
    }
    &.avatar-right{
      left: auto;
      top: 0;
      bottom: 0;
      right: 0;
    }
    &.avatar-bottom{
      left: 0;
      top: auto;
      bottom: 0;
      right: 0;
    }
    &.avatar-left{
      right: auto;
      top: 0;
      bottom: 0;
      left: 0;
    }
    &.on{
      background-color: $success;
    }
    &.off{
      background-color: $light;
    }
    &.away{
      background-color: $warning;
    }
    &.busy{
      background-color: $danger;
    }
  }

  i.pxt.pxt-edit {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    z-index: 9999;
    cursor: pointer;

    font-size: 3.5em;
    width: auto;
    height: auto;

    color: $light;

    background-color: transparent;
    border: none;

    & ~ span.overlay {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      content: '';
      background-color: rgba($grey, .5);
      cursor: pointer;
      visibility: visible;
    }
  }

  &.w-32{
    i{
      margin: 0px;
    }
    line-height: 32px;
  }
  &.w-48{
    i{
      margin: 2px;
    }
  }
  &.w-56{
    i{
      margin: 3px;
    }
  }
  &.w-64{
    i{
      margin: 4px;
    }
  }
  &.w-96{
    i{
      margin: 9px;
    }
  }
  &.w-128{
    i{
      margin: 14px;
    }
  }
}
