/*
Carousel 
*/
.carousel-control .glyphicon{
  position: absolute;
  top: 50%;
  #{$direction_1}: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  margin-#{$direction_1}: -10px;
}
