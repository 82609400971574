.form-group .rtl {

	.mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {
		direction: rtl;
		text-align: right;
	}

	.mce-flow-layout-item {
		margin: 2px 2px 2px 0;
	}

	.mce-colorbutton {
		.mce-open {
			border-left: none;
			border-right: 1px solid transparent
		}
		
		&:hover {
			.mce-open {
				border-color: #ccc;
			}
		}
	}
	.mce-colorbutton
	.mce-btn-group .mce-btn {
		margin-left: 0;
		margin-right: 2px;
	}

	.mce-colorbutton .mce-preview {
		left: 0;
		right: 50%;
		margin-right: -17px;
		margin-left: 0;
	}

	.mce-combobox .mce-btn {
		border: 1px solid #c5c5c5;
		border-right: 0
	}
	.mce-splitbtn {
		.mce-open {
			border-left: none;
			border-right: 1px solid transparent
		}

		&:hover {
			.mce-open {
				border-right-color: none;
				border-right-color: #ccc;
			}
		}
	}

	.mce-btn-group:not(:first-child) {
		border-right: 1px solid #d9d9d9;
		padding-right: 3px;
		margin-right: 3px;

		border-left: none;
		padding-left: initial;
		margin-left: initial;
	}

	.mce-btn-group .mce-first {
		margin-right: 0;
	}

	.mce-container-body .mce-resizehandle {
		right: auto;
		left: 0;
		-moz-transform: scaleX(-1);
		-o-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}

	.mce-wordcount {
		right: auto;
		left: 0;
	}
}