/*
List
*/
.list{
	padding-#{$direction_1}: 0;
	padding-#{$direction_2}: 0;
	border-radius: $border-radius-base;
	&.no-border{
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

.list-item{
	display: block;
	position: relative;
	padding: 12px 16px;
	@include clearfix();
	&:last-child .list-body:after{
		border: none;
	}
	.no-border &{
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.no-padding &{
		padding-#{$direction_1}: 0;
		padding-#{$direction_2}: 0;
	}
	&.active{
		background-color: $active-color;
	}
}

.list-#{$direction_1}{
	float: #{$direction_1};
	> span{
		display: table-cell;
		vertical-align: middle;
	}
	+ .list-body{
		margin-#{$direction_1}: 56px;
	}
}

.list-body{
	h3{
		font-size: 16px;
		margin: 0 0 3px 0;
		font-weight: normal;
	}
	&:after{
		content: "";
		position: absolute;
		#{$direction_1}: 0;
		#{$direction_2}: 0;
		bottom: 0;
		border-bottom: 1px solid $border-color-lt;
	}
	.inset &:after{
		#{$direction_1}: 72px;
	}
	.no-border &:after{
		display: none;
	}
}
