/*
Spacing
*/

@mixin create-spacing ($name, $properties, $important) {

	@if ($important) {
		$name: #{$name}-important;
	} 

	.#{$name} {
		@each $property, $content in $properties {
			@if ($important) {
				$content: #{$content} !important;
			}

			#{$property}: #{$content};
		}
	}
}

@mixin util-spacing($sufix){

	@include create-spacing(m-a#{$sufix}, (margin: ($spacer * 1)), false);
	@include create-spacing(m-t#{$sufix}, (margin-top: ($spacer * 1)), false);
	@include create-spacing(m-r#{$sufix}, (margin-right: ($spacer * 1)), false);
	@include create-spacing(m-b#{$sufix}, (margin-bottom: ($spacer * 1)), false);
	@include create-spacing(m-l#{$sufix}, (margin-left: ($spacer * 1)), false);
	@include create-spacing(m-x#{$sufix}, (margin-right: ($spacer-x * 1), margin-left: ($spacer-x * 1)), false);
	@include create-spacing(m-y#{$sufix}, (margin-top: ($spacer-y * 1), margin-bottom: ($spacer-y * 1)), false);

	@include create-spacing(m-a#{$sufix}, (margin: ($spacer * 1)), true);
	@include create-spacing(m-t#{$sufix}, (margin-top: ($spacer * 1)), true);
	@include create-spacing(m-r#{$sufix}, (margin-right: ($spacer * 1)), true);
	@include create-spacing(m-b#{$sufix}, (margin-bottom: ($spacer * 1)), true);
	@include create-spacing(m-l#{$sufix}, (margin-left: ($spacer * 1)), true);
	@include create-spacing(m-x#{$sufix}, (margin-right: ($spacer-x * 1), margin-left: ($spacer-x * 1)), true);
	@include create-spacing(m-y#{$sufix}, (margin-top: ($spacer-y * 1), margin-bottom: ($spacer-y * 1)), true);

	
	@include create-spacing(m-a-lg#{$sufix}, (margin: ($spacer * 3)), false);
	@include create-spacing(m-t-lg#{$sufix}, (margin-top: ($spacer * 3)), false);
	@include create-spacing(m-r-lg#{$sufix}, (margin-right: ($spacer * 3)), false);
	@include create-spacing(m-b-lg#{$sufix}, (margin-bottom: ($spacer * 3)), false);
	@include create-spacing(m-l-lg#{$sufix}, (margin-left: ($spacer * 3)), false);
	@include create-spacing(m-x-lg#{$sufix}, (margin-right: ($spacer-x * 3), margin-left: ($spacer-x * 3)), false);
	@include create-spacing(m-y-lg#{$sufix}, (margin-top: ($spacer-y * 3), margin-bottom: ($spacer-y * 3)), false);

	@include create-spacing(m-a-lg#{$sufix}, (margin: ($spacer * 3)), true);
	@include create-spacing(m-t-lg#{$sufix}, (margin-top: ($spacer * 3)), true);
	@include create-spacing(m-r-lg#{$sufix}, (margin-right: ($spacer * 3)), true);
	@include create-spacing(m-b-lg#{$sufix}, (margin-bottom: ($spacer * 3)), true);
	@include create-spacing(m-l-lg#{$sufix}, (margin-left: ($spacer * 3)), true);
	@include create-spacing(m-x-lg#{$sufix}, (margin-right: ($spacer-x * 3), margin-left: ($spacer-x * 3)), true);
	@include create-spacing(m-y-lg#{$sufix}, (margin-top: ($spacer-y * 3), margin-bottom: ($spacer-y * 3)), true);


	@include create-spacing(m-a-md#{$sufix}, (margin: ($spacer * 1.5)), false);
	@include create-spacing(m-t-md#{$sufix}, (margin-top: ($spacer * 1.5)), false);
	@include create-spacing(m-r-md#{$sufix}, (margin-right: ($spacer * 1.5)), false);
	@include create-spacing(m-b-md#{$sufix}, (margin-bottom: ($spacer * 1.5)), false);
	@include create-spacing(m-l-md#{$sufix}, (margin-left: ($spacer * 1.5)), false);
	@include create-spacing(m-x-md#{$sufix}, (margin-right: ($spacer-x * 1.5), margin-left: ($spacer-x * 1.5)), false);
	@include create-spacing(m-y-md#{$sufix}, (margin-top: ($spacer-y * 1.5), margin-bottom: ($spacer-y * 1.5)), false);

	@include create-spacing(m-a-md#{$sufix}, (margin: ($spacer * 1.5)), true);
	@include create-spacing(m-t-md#{$sufix}, (margin-top: ($spacer * 1.5)), true);
	@include create-spacing(m-r-md#{$sufix}, (margin-right: ($spacer * 1.5)), true);
	@include create-spacing(m-b-md#{$sufix}, (margin-bottom: ($spacer * 1.5)), true);
	@include create-spacing(m-l-md#{$sufix}, (margin-left: ($spacer * 1.5)), true);
	@include create-spacing(m-x-md#{$sufix}, (margin-right: ($spacer-x * 1.5), margin-left: ($spacer-x * 1.5)), true);
	@include create-spacing(m-y-md#{$sufix}, (margin-top: ($spacer-y * 1.5), margin-bottom: ($spacer-y * 1.5)), true);


	@include create-spacing(m-a-sm#{$sufix}, (margin: ($spacer * 0.5)), false);
	@include create-spacing(m-t-sm#{$sufix}, (margin-top: ($spacer * 0.5)), false);
	@include create-spacing(m-r-sm#{$sufix}, (margin-right: ($spacer * 0.5)), false);
	@include create-spacing(m-b-sm#{$sufix}, (margin-bottom: ($spacer * 0.5)), false);
	@include create-spacing(m-l-sm#{$sufix}, (margin-left: ($spacer * 0.5)), false);
	@include create-spacing(m-x-sm#{$sufix}, (margin-right: ($spacer-x * 0.5), margin-left: ($spacer-x * 0.5)), false);
	@include create-spacing(m-y-sm#{$sufix}, (margin-top: ($spacer-y * 0.5), margin-bottom: ($spacer-y * 0.5)), false);

	@include create-spacing(m-a-sm#{$sufix}, (margin: ($spacer * 0.5)), true);
	@include create-spacing(m-t-sm#{$sufix}, (margin-top: ($spacer * 0.5)), true);
	@include create-spacing(m-r-sm#{$sufix}, (margin-right: ($spacer * 0.5)), true);
	@include create-spacing(m-b-sm#{$sufix}, (margin-bottom: ($spacer * 0.5)), true);
	@include create-spacing(m-l-sm#{$sufix}, (margin-left: ($spacer * 0.5)), true);
	@include create-spacing(m-x-sm#{$sufix}, (margin-right: ($spacer-x * 0.5), margin-left: ($spacer-x * 0.5)), true);
	@include create-spacing(m-y-sm#{$sufix}, (margin-top: ($spacer-y * 0.5), margin-bottom: ($spacer-y * 0.5)), true);


	@include create-spacing(m-a-xs#{$sufix}, (margin: ($spacer * 0.25)), false);
	@include create-spacing(m-t-xs#{$sufix}, (margin-top: ($spacer * 0.25)), false);
	@include create-spacing(m-r-xs#{$sufix}, (margin-right: ($spacer * 0.25)), false);
	@include create-spacing(m-b-xs#{$sufix}, (margin-bottom: ($spacer * 0.25)), false);
	@include create-spacing(m-l-xs#{$sufix}, (margin-left: ($spacer * 0.25)), false);
	@include create-spacing(m-x-xs#{$sufix}, (margin-right: ($spacer-x * 0.25), margin-left: ($spacer-x * 0.25)), false);
	@include create-spacing(m-y-xs#{$sufix}, (margin-top: ($spacer-y * 0.25), margin-bottom: ($spacer-y * 0.25)), false);

	@include create-spacing(m-a-xs#{$sufix}, (margin: ($spacer * 0.25)), true);
	@include create-spacing(m-t-xs#{$sufix}, (margin-top: ($spacer * 0.25)), true);
	@include create-spacing(m-r-xs#{$sufix}, (margin-right: ($spacer * 0.25)), true);
	@include create-spacing(m-b-xs#{$sufix}, (margin-bottom: ($spacer * 0.25)), true);
	@include create-spacing(m-l-xs#{$sufix}, (margin-left: ($spacer * 0.25)), true);
	@include create-spacing(m-x-xs#{$sufix}, (margin-right: ($spacer-x * 0.25), margin-left: ($spacer-x * 0.25)), true);
	@include create-spacing(m-y-xs#{$sufix}, (margin-top: ($spacer-y * 0.25), margin-bottom: ($spacer-y * 0.25)), true);


	@include create-spacing(m-a-0#{$sufix}, (margin: ($spacer * 0)), false);
	@include create-spacing(m-t-0#{$sufix}, (margin-top: ($spacer * 0)), false);
	@include create-spacing(m-r-0#{$sufix}, (margin-right: ($spacer * 0)), false);
	@include create-spacing(m-b-0#{$sufix}, (margin-bottom: ($spacer * 0)), false);
	@include create-spacing(m-l-0#{$sufix}, (margin-left: ($spacer * 0)), false);
	@include create-spacing(m-x-0#{$sufix}, (margin-right: ($spacer-x * 0), margin-left: ($spacer-x * 0)), false);
	@include create-spacing(m-y-0#{$sufix}, (margin-top: ($spacer-y * 0), margin-bottom: ($spacer-y * 0)), false);

	@include create-spacing(m-a-0#{$sufix}, (margin: ($spacer * 0)), true);
	@include create-spacing(m-t-0#{$sufix}, (margin-top: ($spacer * 0)), true);
	@include create-spacing(m-r-0#{$sufix}, (margin-right: ($spacer * 0)), true);
	@include create-spacing(m-b-0#{$sufix}, (margin-bottom: ($spacer * 0)), true);
	@include create-spacing(m-l-0#{$sufix}, (margin-left: ($spacer * 0)), true);
	@include create-spacing(m-x-0#{$sufix}, (margin-right: ($spacer-x * 0), margin-left: ($spacer-x * 0)), true);
	@include create-spacing(m-y-0#{$sufix}, (margin-top: ($spacer-y * 0), margin-bottom: ($spacer-y * 0)), true);



	@include create-spacing(p-a#{$sufix}, (padding: ($spacer * 1)), false);
	@include create-spacing(p-t#{$sufix}, (padding-top: ($spacer * 1)), false);
	@include create-spacing(p-r#{$sufix}, (padding-right: ($spacer * 1)), false);
	@include create-spacing(p-b#{$sufix}, (padding-bottom: ($spacer * 1)), false);
	@include create-spacing(p-l#{$sufix}, (padding-left: ($spacer * 1)), false);
	@include create-spacing(p-x#{$sufix}, (padding-right: ($spacer-x * 1), padding-left: ($spacer-x * 1)), false);
	@include create-spacing(p-y#{$sufix}, (padding-top: ($spacer-y * 1), padding-bottom: ($spacer-y * 1)), false);

	@include create-spacing(p-a#{$sufix}, (padding: ($spacer * 1)), true);
	@include create-spacing(p-t#{$sufix}, (padding-top: ($spacer * 1)), true);
	@include create-spacing(p-r#{$sufix}, (padding-right: ($spacer * 1)), true);
	@include create-spacing(p-b#{$sufix}, (padding-bottom: ($spacer * 1)), true);
	@include create-spacing(p-l#{$sufix}, (padding-left: ($spacer * 1)), true);
	@include create-spacing(p-x#{$sufix}, (padding-right: ($spacer-x * 1), padding-left: ($spacer-x * 1)), true);
	@include create-spacing(p-y#{$sufix}, (padding-top: ($spacer-y * 1), padding-bottom: ($spacer-y * 1)), true);


	@include create-spacing(p-a-lg#{$sufix}, (padding: ($spacer * 3)), false);
	@include create-spacing(p-t-lg#{$sufix}, (padding-top: ($spacer * 3)), false);
	@include create-spacing(p-r-lg#{$sufix}, (padding-right: ($spacer * 3)), false);
	@include create-spacing(p-b-lg#{$sufix}, (padding-bottom: ($spacer * 3)), false);
	@include create-spacing(p-l-lg#{$sufix}, (padding-left: ($spacer * 3)), false);
	@include create-spacing(p-x-lg#{$sufix}, (padding-right: ($spacer-x * 3), padding-left: ($spacer-x * 3)), false);
	@include create-spacing(p-y-lg#{$sufix}, (padding-top: ($spacer-y * 3), padding-bottom: ($spacer-y * 3)), false);

	@include create-spacing(p-a-lg#{$sufix}, (padding: ($spacer * 3)), true);
	@include create-spacing(p-t-lg#{$sufix}, (padding-top: ($spacer * 3)), true);
	@include create-spacing(p-r-lg#{$sufix}, (padding-right: ($spacer * 3)), true);
	@include create-spacing(p-b-lg#{$sufix}, (padding-bottom: ($spacer * 3)), true);
	@include create-spacing(p-l-lg#{$sufix}, (padding-left: ($spacer * 3)), true);
	@include create-spacing(p-x-lg#{$sufix}, (padding-right: ($spacer-x * 3), padding-left: ($spacer-x * 3)), true);
	@include create-spacing(p-y-lg#{$sufix}, (padding-top: ($spacer-y * 3), padding-bottom: ($spacer-y * 3)), true);


	@include create-spacing(p-a-md#{$sufix}, (padding: ($spacer * 1.5)), false);
	@include create-spacing(p-t-md#{$sufix}, (padding-top: ($spacer * 1.5)), false);
	@include create-spacing(p-r-md#{$sufix}, (padding-right: ($spacer * 1.5)), false);
	@include create-spacing(p-b-md#{$sufix}, (padding-bottom: ($spacer * 1.5)), false);
	@include create-spacing(p-l-md#{$sufix}, (padding-left: ($spacer * 1.5)), false);
	@include create-spacing(p-x-md#{$sufix}, (padding-right: ($spacer-x * 1.5), padding-left: ($spacer-x * 1.5)), false);
	@include create-spacing(p-y-md#{$sufix}, (padding-top: ($spacer-y * 1.5), padding-bottom: ($spacer-y * 1.5)), false);

	@include create-spacing(p-a-md#{$sufix}, (padding: ($spacer * 1.5)), true);
	@include create-spacing(p-t-md#{$sufix}, (padding-top: ($spacer * 1.5)), true);
	@include create-spacing(p-r-md#{$sufix}, (padding-right: ($spacer * 1.5)), true);
	@include create-spacing(p-b-md#{$sufix}, (padding-bottom: ($spacer * 1.5)), true);
	@include create-spacing(p-l-md#{$sufix}, (padding-left: ($spacer * 1.5)), true);
	@include create-spacing(p-x-md#{$sufix}, (padding-right: ($spacer-x * 1.5), padding-left: ($spacer-x * 1.5)), true);
	@include create-spacing(p-y-md#{$sufix}, (padding-top: ($spacer-y * 1.5), padding-bottom: ($spacer-y * 1.5)), true);


	@include create-spacing(p-a-sm#{$sufix}, (padding: ($spacer * 0.5)), false);
	@include create-spacing(p-t-sm#{$sufix}, (padding-top: ($spacer * 0.5)), false);
	@include create-spacing(p-r-sm#{$sufix}, (padding-right: ($spacer * 0.5)), false);
	@include create-spacing(p-b-sm#{$sufix}, (padding-bottom: ($spacer * 0.5)), false);
	@include create-spacing(p-l-sm#{$sufix}, (padding-left: ($spacer * 0.5)), false);
	@include create-spacing(p-x-sm#{$sufix}, (padding-right: ($spacer-x * 0.5), padding-left: ($spacer-x * 0.5)), false);
	@include create-spacing(p-y-sm#{$sufix}, (padding-top: ($spacer-y * 0.5), padding-bottom: ($spacer-y * 0.5)), false);

	@include create-spacing(p-a-sm#{$sufix}, (padding: ($spacer * 0.5)), true);
	@include create-spacing(p-t-sm#{$sufix}, (padding-top: ($spacer * 0.5)), true);
	@include create-spacing(p-r-sm#{$sufix}, (padding-right: ($spacer * 0.5)), true);
	@include create-spacing(p-b-sm#{$sufix}, (padding-bottom: ($spacer * 0.5)), true);
	@include create-spacing(p-l-sm#{$sufix}, (padding-left: ($spacer * 0.5)), true);
	@include create-spacing(p-x-sm#{$sufix}, (padding-right: ($spacer-x * 0.5), padding-left: ($spacer-x * 0.5)), true);
	@include create-spacing(p-y-sm#{$sufix}, (padding-top: ($spacer-y * 0.5), padding-bottom: ($spacer-y * 0.5)), true);


	@include create-spacing(p-a-xs#{$sufix}, (padding: ($spacer * 0.25)), false);
	@include create-spacing(p-t-xs#{$sufix}, (padding-top: ($spacer * 0.25)), false);
	@include create-spacing(p-r-xs#{$sufix}, (padding-right: ($spacer * 0.25)), false);
	@include create-spacing(p-b-xs#{$sufix}, (padding-bottom: ($spacer * 0.25)), false);
	@include create-spacing(p-l-xs#{$sufix}, (padding-left: ($spacer * 0.25)), false);
	@include create-spacing(p-x-xs#{$sufix}, (padding-right: ($spacer-x * 0.25), padding-left: ($spacer-x * 0.25)), false);
	@include create-spacing(p-y-xs#{$sufix}, (padding-top: ($spacer-y * 0.25), padding-bottom: ($spacer-y * 0.25)), false);

	@include create-spacing(p-a-xs#{$sufix}, (padding: ($spacer * 0.25)), true);
	@include create-spacing(p-t-xs#{$sufix}, (padding-top: ($spacer * 0.25)), true);
	@include create-spacing(p-r-xs#{$sufix}, (padding-right: ($spacer * 0.25)), true);
	@include create-spacing(p-b-xs#{$sufix}, (padding-bottom: ($spacer * 0.25)), true);
	@include create-spacing(p-l-xs#{$sufix}, (padding-left: ($spacer * 0.25)), true);
	@include create-spacing(p-x-xs#{$sufix}, (padding-right: ($spacer-x * 0.25), padding-left: ($spacer-x * 0.25)), true);
	@include create-spacing(p-y-xs#{$sufix}, (padding-top: ($spacer-y * 0.25), padding-bottom: ($spacer-y * 0.25)), true);


	@include create-spacing(p-a-0#{$sufix}, (padding: ($spacer * 0)), false);
	@include create-spacing(p-t-0#{$sufix}, (padding-top: ($spacer * 0)), false);
	@include create-spacing(p-r-0#{$sufix}, (padding-right: ($spacer * 0)), false);
	@include create-spacing(p-b-0#{$sufix}, (padding-bottom: ($spacer * 0)), false);
	@include create-spacing(p-l-0#{$sufix}, (padding-left: ($spacer * 0)), false);
	@include create-spacing(p-x-0#{$sufix}, (padding-right: ($spacer-x * 0), padding-left: ($spacer-x * 0)), false);
	@include create-spacing(p-y-0#{$sufix}, (padding-top: ($spacer-y * 0), padding-bottom: ($spacer-y * 0)), false);

	@include create-spacing(p-a-0#{$sufix}, (padding: ($spacer * 0)), true);
	@include create-spacing(p-t-0#{$sufix}, (padding-top: ($spacer * 0)), true);
	@include create-spacing(p-r-0#{$sufix}, (padding-right: ($spacer * 0)), true);
	@include create-spacing(p-b-0#{$sufix}, (padding-bottom: ($spacer * 0)), true);
	@include create-spacing(p-l-0#{$sufix}, (padding-left: ($spacer * 0)), true);
	@include create-spacing(p-x-0#{$sufix}, (padding-right: ($spacer-x * 0), padding-left: ($spacer-x * 0)), true);
	@include create-spacing(p-y-0#{$sufix}, (padding-top: ($spacer-y * 0), padding-bottom: ($spacer-y * 0)), true);


	@include create-spacing(pull-rlt-up#{$sufix}, (position: relative, top: -8px, margin-bottom: -8px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-xs#{$sufix}, (position: relative, top: -2px, margin-bottom: -2px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-sm#{$sufix}, (position: relative, top: -4px, margin-bottom: -4px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-md#{$sufix}, (position: relative, top: -6px, margin-bottom: -6px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-lg#{$sufix}, (position: relative, top: -10px, margin-bottom: -10px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-12#{$sufix}, (position: relative, top: -12px, margin-bottom: -12px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-14#{$sufix}, (position: relative, top: -14px, margin-bottom: -14px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-16#{$sufix}, (position: relative, top: -16px, margin-bottom: -16px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-18#{$sufix}, (position: relative, top: -18px, margin-bottom: -18px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-20#{$sufix}, (position: relative, top: -20px, margin-bottom: -20px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-25#{$sufix}, (position: relative, top: -25px, margin-bottom: -25px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-30#{$sufix}, (position: relative, top: -30px, margin-bottom: -30px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-40#{$sufix}, (position: relative, top: -40px, margin-bottom: -40px, bottom: auto), false);
	@include create-spacing(pull-rlt-up-50#{$sufix}, (position: relative, top: -50px, margin-bottom: -50px, bottom: auto), false);

	@include create-spacing(pull-rlt-up#{$sufix}, (position: relative, top: -8px, margin-bottom: -8px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-xs#{$sufix}, (position: relative, top: -2px, margin-bottom: -2px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-sm#{$sufix}, (position: relative, top: -4px, margin-bottom: -4px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-md#{$sufix}, (position: relative, top: -6px, margin-bottom: -6px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-lg#{$sufix}, (position: relative, top: -10px, margin-bottom: -10px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-12#{$sufix}, (position: relative, top: -12px, margin-bottom: -12px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-14#{$sufix}, (position: relative, top: -14px, margin-bottom: -14px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-16#{$sufix}, (position: relative, top: -16px, margin-bottom: -16px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-18#{$sufix}, (position: relative, top: -18px, margin-bottom: -18px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-20#{$sufix}, (position: relative, top: -20px, margin-bottom: -20px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-25#{$sufix}, (position: relative, top: -25px, margin-bottom: -25px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-30#{$sufix}, (position: relative, top: -30px, margin-bottom: -30px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-40#{$sufix}, (position: relative, top: -40px, margin-bottom: -40px, bottom: auto), true);
	@include create-spacing(pull-rlt-up-50#{$sufix}, (position: relative, top: -50px, margin-bottom: -50px, bottom: auto), true);


	@include create-spacing(pull-rlt-down#{$sufix}, (position: relative, bottom: -8px, margin-top: -8px, top: auto), false);
	@include create-spacing(pull-rlt-down-xs#{$sufix}, (position: relative, bottom: -2px, margin-top: -2px, top: auto), false);
	@include create-spacing(pull-rlt-down-sm#{$sufix}, (position: relative, bottom: -4px, margin-top: -4px, top: auto), false);
	@include create-spacing(pull-rlt-down-md#{$sufix}, (position: relative, bottom: -6px, margin-top: -6px, top: auto), false);
	@include create-spacing(pull-rlt-down-lg#{$sufix}, (position: relative, bottom: -10px, margin-top: -10px, top: auto), false);
	@include create-spacing(pull-rlt-down-12#{$sufix}, (position: relative, bottom: -12px, margin-top: -12px, top: auto), false);
	@include create-spacing(pull-rlt-down-14#{$sufix}, (position: relative, bottom: -14px, margin-top: -14px, top: auto), false);
	@include create-spacing(pull-rlt-down-16#{$sufix}, (position: relative, bottom: -16px, margin-top: -16px, top: auto), false);
	@include create-spacing(pull-rlt-down-18#{$sufix}, (position: relative, bottom: -18px, margin-top: -18px, top: auto), false);
	@include create-spacing(pull-rlt-down-20#{$sufix}, (position: relative, bottom: -20px, margin-top: -20px, top: auto), false);
	@include create-spacing(pull-rlt-down-25#{$sufix}, (position: relative, bottom: -25px, margin-top: -25px, top: auto), false);
	@include create-spacing(pull-rlt-down-30#{$sufix}, (position: relative, bottom: -30px, margin-top: -30px, top: auto), false);
	@include create-spacing(pull-rlt-down-40#{$sufix}, (position: relative, bottom: -40px, margin-top: -40px, top: auto), false);
	@include create-spacing(pull-rlt-down-50#{$sufix}, (position: relative, bottom: -50px, margin-top: -50px, top: auto), false);


	@include create-spacing(pull-rlt-down#{$sufix}, (position: relative, bottom: -8px, margin-top: -8px, top: auto), true);
	@include create-spacing(pull-rlt-down-xs#{$sufix}, (position: relative, bottom: -2px, margin-top: -2px, top: auto), true);
	@include create-spacing(pull-rlt-down-sm#{$sufix}, (position: relative, bottom: -4px, margin-top: -4px, top: auto), true);
	@include create-spacing(pull-rlt-down-md#{$sufix}, (position: relative, bottom: -6px, margin-top: -6px, top: auto), true);
	@include create-spacing(pull-rlt-down-lg#{$sufix}, (position: relative, bottom: -10px, margin-top: -10px, top: auto), true);
	@include create-spacing(pull-rlt-down-12#{$sufix}, (position: relative, bottom: -12px, margin-top: -12px, top: auto), true);
	@include create-spacing(pull-rlt-down-14#{$sufix}, (position: relative, bottom: -14px, margin-top: -14px, top: auto), true);
	@include create-spacing(pull-rlt-down-16#{$sufix}, (position: relative, bottom: -16px, margin-top: -16px, top: auto), true);
	@include create-spacing(pull-rlt-down-18#{$sufix}, (position: relative, bottom: -18px, margin-top: -18px, top: auto), true);
	@include create-spacing(pull-rlt-down-20#{$sufix}, (position: relative, bottom: -20px, margin-top: -20px, top: auto), true);
	@include create-spacing(pull-rlt-down-25#{$sufix}, (position: relative, bottom: -25px, margin-top: -25px, top: auto), true);
	@include create-spacing(pull-rlt-down-30#{$sufix}, (position: relative, bottom: -30px, margin-top: -30px, top: auto), true);
	@include create-spacing(pull-rlt-down-40#{$sufix}, (position: relative, bottom: -40px, margin-top: -40px, top: auto), true);
	@include create-spacing(pull-rlt-down-50#{$sufix}, (position: relative, bottom: -50px, margin-top: -50px, top: auto), true);


	@include create-spacing(pull-rlt-left#{$sufix}, (position: relative, left: -8px, margin-right: -8px, right: auto), false);
	@include create-spacing(pull-rlt-left-xs#{$sufix}, (position: relative, left: -2px, margin-right: -2px, right: auto), false);
	@include create-spacing(pull-rlt-left-sm#{$sufix}, (position: relative, left: -4px, margin-right: -4px, right: auto), false);
	@include create-spacing(pull-rlt-left-md#{$sufix}, (position: relative, left: -6px, margin-right: -6px, right: auto), false);
	@include create-spacing(pull-rlt-left-lg#{$sufix}, (position: relative, left: -10px, margin-right: -10px, right: auto), false);
	@include create-spacing(pull-rlt-left-12#{$sufix}, (position: relative, left: -12px, margin-right: -12px, right: auto), false);
	@include create-spacing(pull-rlt-left-14#{$sufix}, (position: relative, left: -14px, margin-right: -14px, right: auto), false);
	@include create-spacing(pull-rlt-left-16#{$sufix}, (position: relative, left: -16px, margin-right: -16px, right: auto), false);
	@include create-spacing(pull-rlt-left-18#{$sufix}, (position: relative, left: -18px, margin-right: -18px, right: auto), false);
	@include create-spacing(pull-rlt-left-20#{$sufix}, (position: relative, left: -20px, margin-right: -20px, right: auto), false);
	@include create-spacing(pull-rlt-left-25#{$sufix}, (position: relative, left: -25px, margin-right: -25px, right: auto), false);
	@include create-spacing(pull-rlt-left-30#{$sufix}, (position: relative, left: -30px, margin-right: -30px, right: auto), false);
	@include create-spacing(pull-rlt-left-40#{$sufix}, (position: relative, left: -40px, margin-right: -40px, right: auto), false);
	@include create-spacing(pull-rlt-left-50#{$sufix}, (position: relative, left: -50px, margin-right: -50px, right: auto), false);

	@include create-spacing(pull-rlt-left#{$sufix}, (position: relative, left: -8px, margin-right: -8px, right: auto), true);
	@include create-spacing(pull-rlt-left-xs#{$sufix}, (position: relative, left: -2px, margin-right: -2px, right: auto), true);
	@include create-spacing(pull-rlt-left-sm#{$sufix}, (position: relative, left: -4px, margin-right: -4px, right: auto), true);
	@include create-spacing(pull-rlt-left-md#{$sufix}, (position: relative, left: -6px, margin-right: -6px, right: auto), true);
	@include create-spacing(pull-rlt-left-lg#{$sufix}, (position: relative, left: -10px, margin-right: -10px, right: auto), true);
	@include create-spacing(pull-rlt-left-12#{$sufix}, (position: relative, left: -12px, margin-right: -12px, right: auto), true);
	@include create-spacing(pull-rlt-left-14#{$sufix}, (position: relative, left: -14px, margin-right: -14px, right: auto), true);
	@include create-spacing(pull-rlt-left-16#{$sufix}, (position: relative, left: -16px, margin-right: -16px, right: auto), true);
	@include create-spacing(pull-rlt-left-18#{$sufix}, (position: relative, left: -18px, margin-right: -18px, right: auto), true);
	@include create-spacing(pull-rlt-left-20#{$sufix}, (position: relative, left: -20px, margin-right: -20px, right: auto), true);
	@include create-spacing(pull-rlt-left-25#{$sufix}, (position: relative, left: -25px, margin-right: -25px, right: auto), true);
	@include create-spacing(pull-rlt-left-30#{$sufix}, (position: relative, left: -30px, margin-right: -30px, right: auto), true);
	@include create-spacing(pull-rlt-left-40#{$sufix}, (position: relative, left: -40px, margin-right: -40px, right: auto), true);
	@include create-spacing(pull-rlt-left-50#{$sufix}, (position: relative, left: -50px, margin-right: -50px, right: auto), true);


	@include create-spacing(pull-rlt-right#{$sufix}, (position: relative, right: -8px, margin-left: -8px, left: auto), false);
	@include create-spacing(pull-rlt-right-xs#{$sufix}, (position: relative, right: -2px, margin-left: -2px, left: auto), false);
	@include create-spacing(pull-rlt-right-sm#{$sufix}, (position: relative, right: -4px, margin-left: -4px, left: auto), false);
	@include create-spacing(pull-rlt-right-md#{$sufix}, (position: relative, right: -6px, margin-left: -6px, left: auto), false);
	@include create-spacing(pull-rlt-right-lg#{$sufix}, (position: relative, right: -10px, margin-left: -10px, left: auto), false);
	@include create-spacing(pull-rlt-right-12#{$sufix}, (position: relative, right: -12px, margin-left: -12px, left: auto), false);
	@include create-spacing(pull-rlt-right-14#{$sufix}, (position: relative, right: -14px, margin-left: -14px, left: auto), false);
	@include create-spacing(pull-rlt-right-16#{$sufix}, (position: relative, right: -16px, margin-left: -16px, left: auto), false);
	@include create-spacing(pull-rlt-right-18#{$sufix}, (position: relative, right: -18px, margin-left: -18px, left: auto), false);
	@include create-spacing(pull-rlt-right-20#{$sufix}, (position: relative, right: -20px, margin-left: -20px, left: auto), false);
	@include create-spacing(pull-rlt-right-25#{$sufix}, (position: relative, right: -25px, margin-left: -25px, left: auto), false);
	@include create-spacing(pull-rlt-right-30#{$sufix}, (position: relative, right: -30px, margin-left: -30px, left: auto), false);
	@include create-spacing(pull-rlt-right-40#{$sufix}, (position: relative, right: -40px, margin-left: -40px, left: auto), false);
	@include create-spacing(pull-rlt-right-50#{$sufix}, (position: relative, right: -50px, margin-left: -50px, left: auto), false);

	@include create-spacing(pull-rlt-right#{$sufix}, (position: relative, right: -8px, margin-left: -8px, left: auto), true);
	@include create-spacing(pull-rlt-right-xs#{$sufix}, (position: relative, right: -2px, margin-left: -2px, left: auto), true);
	@include create-spacing(pull-rlt-right-sm#{$sufix}, (position: relative, right: -4px, margin-left: -4px, left: auto), true);
	@include create-spacing(pull-rlt-right-md#{$sufix}, (position: relative, right: -6px, margin-left: -6px, left: auto), true);
	@include create-spacing(pull-rlt-right-lg#{$sufix}, (position: relative, right: -10px, margin-left: -10px, left: auto), true);
	@include create-spacing(pull-rlt-right-12#{$sufix}, (position: relative, right: -12px, margin-left: -12px, left: auto), true);
	@include create-spacing(pull-rlt-right-14#{$sufix}, (position: relative, right: -14px, margin-left: -14px, left: auto), true);
	@include create-spacing(pull-rlt-right-16#{$sufix}, (position: relative, right: -16px, margin-left: -16px, left: auto), true);
	@include create-spacing(pull-rlt-right-18#{$sufix}, (position: relative, right: -18px, margin-left: -18px, left: auto), true);
	@include create-spacing(pull-rlt-right-20#{$sufix}, (position: relative, right: -20px, margin-left: -20px, left: auto), true);
	@include create-spacing(pull-rlt-right-25#{$sufix}, (position: relative, right: -25px, margin-left: -25px, left: auto), true);
	@include create-spacing(pull-rlt-right-30#{$sufix}, (position: relative, right: -30px, margin-left: -30px, left: auto), true);
	@include create-spacing(pull-rlt-right-40#{$sufix}, (position: relative, right: -40px, margin-left: -40px, left: auto), true);
	@include create-spacing(pull-rlt-right-50#{$sufix}, (position: relative, right: -50px, margin-left: -50px, left: auto), true);
}


@include util-spacing('');



.padding { padding: 1.5rem 1.5rem; }
.margin{ margin-bottom: 1.5rem; }
.padding-out { margin: -1.5rem -1.5rem; }

@include media-breakpoint-down(md) {
	.padding{
		padding: 1.5rem 1rem;
	}

	.padding-out{
		margin: -1.5rem -1rem;
	}

	.margin{
		margin-bottom: 1rem;
	}

	.box,
	.box-color{
		margin-bottom: 1rem;
	}

	.no-padding-md{
		padding: 0
	}

	.no-margin-md{
		margin: 0
	}


	@include util-spacing('-down-md');
}

@include media-breakpoint-down(sm) {
	.padding{
		padding: 1.5rem 0.5rem;
	}

	.padding-out{
		margin: -1.5rem -0.5rem;
	}

	.margin{
		margin-bottom: 0.5rem;
	}

	.box,
	.box-color{
		margin-bottom: 0.5rem;
	}

	.no-padding-sm{
		padding: 0
	}

	.no-margin-sm{
		margin: 0
	}

	@include util-spacing('-down-sm');
}

@include media-breakpoint-down(xs) {
	.no-padding-xs{
		padding: 0
	}

	.no-margin-xs{
		margin: 0
	}

	@include util-spacing('-down-xs');

}
