
$md-colors: (
	'red': (#f44336, $dark-white),
	'red-50': (#ffebee, $dark-black),
	'red-100': (#ffcdd2, $dark-black),
	'red-200': (#ef9a9a, $dark-black),
	'red-300': (#e57373, $dark-black),
	'red-400': (#ef5350, $dark-black),
	'red-500': (#f44336, $dark-white),
	'red-600': (#e53935, $dark-white),
	'red-700': (#d32f2f, $dark-white),
	'red-800': (#c62828, $dark-white),
	'red-900': (#b71c1c, $dark-white),
	'red-A100': (#ff8a80, $dark-black),
	'red-A200': (#ff5252, $dark-white),
	'red-A400': (#ff1744, $dark-white),
	'red-A700': (#d50000, $dark-white),

	'pink': (#e91e63, $dark-white),
	'pink-50': (#fce4ec, $dark-black),
	'pink-100': (#f8bbd0, $dark-black),
	'pink-200': (#f48fb1, $dark-black),
	'pink-300': (#f06292, $dark-black),
	'pink-400': (#ec407a, $dark-black),
	'pink-500': (#e91e63, $dark-white),
	'pink-600': (#d81b60, $dark-white),
	'pink-700': (#c2185b, $dark-white),
	'pink-800': (#ad1457, $dark-white),
	'pink-900': (#880e4f, $dark-white),
	'pink-A100': (#ff80ab, $dark-black),
	'pink-A200': (#ff4081, $dark-white),
	'pink-A400': (#f50057, $dark-white),
	'pink-A700': (#c51162, $dark-white),

	'purple': (#9c27b0, $dark-white),
	'purple-50': (#f3e5f5, $dark-black),
	'purple-100': (#e1bee7, $dark-black),
	'purple-200': (#ce93d8, $dark-black),
	'purple-300': (#ba68c8, $dark-white),
	'purple-400': (#ab47bc, $dark-white),
	'purple-500': (#9c27b0, $dark-white),
	'purple-600': (#8e24aa, $dark-white),
	'purple-700': (#7b1fa2, $dark-white),
	'purple-800': (#6a1b9a, $dark-white),
	'purple-900': (#4a148c, $dark-white),
	'purple-A100': (#ea80fc, $dark-black),
	'purple-A200': (#e040fb, $dark-white),
	'purple-A400': (#d500f9, $dark-white),
	'purple-A700': (#aa00ff, $dark-white),

	'deep-purple': (#673ab7, $dark-white),
	'deep-purple-50': (#ede7f6, $dark-black),
	'deep-purple-100': (#d1c4e9, $dark-black),
	'deep-purple-200': (#b39ddb, $dark-black),
	'deep-purple-300': (#9575cd, $dark-white),
	'deep-purple-400': (#7e57c2, $dark-white),
	'deep-purple-500': (#673ab7, $dark-white),
	'deep-purple-600': (#5e35b1, $dark-white),
	'deep-purple-700': (#512da8, $dark-white),
	'deep-purple-800': (#4527a0, $dark-white),
	'deep-purple-900': (#311b92, $dark-white),
	'deep-purple-A100': (#b388ff, $dark-black),
	'deep-purple-A200': (#7c4dff, $dark-white),
	'deep-purple-A400': (#651fff, $dark-white),
	'deep-purple-A700': (#6200ea, $dark-white),

	'indigo': (#3f51b5, $dark-white),
	'indigo-50': (#e8eaf6, $dark-black),
	'indigo-100': (#c5cae9, $dark-black),
	'indigo-200': (#9fa8da, $dark-black),
	'indigo-300': (#7986cb, $dark-white),
	'indigo-400': (#5c6bc0, $dark-white),
	'indigo-500': (#3f51b5, $dark-white),
	'indigo-600': (#3949ab, $dark-white),
	'indigo-700': (#303f9f, $dark-white),
	'indigo-800': (#283593, $dark-white),
	'indigo-900': (#1a237e, $dark-white),
	'indigo-A100': (#8c9eff, $dark-black),
	'indigo-A200': (#536dfe, $dark-white),
	'indigo-A400': (#3d5afe, $dark-white),
	'indigo-A700': (#304ffe, $dark-white),

	'blue': (#2196f3, $dark-white),
	'blue-50': (#e3f2fd, $dark-black),
	'blue-100': (#bbdefb, $dark-black),
	'blue-200': (#90caf9, $dark-black),
	'blue-300': (#64b5f6, $dark-black),
	'blue-400': (#42a5f5, $dark-black),
	'blue-500': (#2196f3, $dark-white),
	'blue-600': (#1e88e5, $dark-white),
	'blue-700': (#1976d2, $dark-white),
	'blue-800': (#1565c0, $dark-white),
	'blue-900': (#0d47a1, $dark-white),
	'blue-A100': (#82b1ff, $dark-black),
	'blue-A200': (#448aff, $dark-white),
	'blue-A400': (#2979ff, $dark-white),
	'blue-A700': (#2962ff, $dark-white),

	'light-blue': (#03a9f4, $dark-white),
	'light-blue-50': (#e1f5fe, $dark-black),
	'light-blue-100': (#b3e5fc, $dark-black),
	'light-blue-200': (#81d4fa, $dark-black),
	'light-blue-300': (#4fc3f7, $dark-black),
	'light-blue-400': (#29b6f6, $dark-black),
	'light-blue-500': (#03a9f4, $dark-white),
	'light-blue-600': (#039be5, $dark-white),
	'light-blue-700': (#0288d1, $dark-white),
	'light-blue-800': (#0277bd, $dark-white),
	'light-blue-900': (#01579b, $dark-white),
	'light-blue-A100': (#80d8ff, $dark-black),
	'light-blue-A200': (#40c4ff, $dark-black),
	'light-blue-A400': (#00b0ff, $dark-black),
	'light-blue-A700': (#0091ea, $dark-white),

	'cyan': (#00bcd4, $dark-white),
	'cyan-50': (#e0f7fa, $dark-black),
	'cyan-100': (#b2ebf2, $dark-black),
	'cyan-200': (#80deea, $dark-black),
	'cyan-300': (#4dd0e1, $dark-black),
	'cyan-400': (#26c6da, $dark-black),
	'cyan-500': (#00bcd4, $dark-white),
	'cyan-600': (#00acc1, $dark-white),
	'cyan-700': (#0097a7, $dark-white),
	'cyan-800': (#00838f, $dark-white),
	'cyan-900': (#006064, $dark-white),
	'cyan-A100': (#84ffff, $dark-black),
	'cyan-A200': (#18ffff, $dark-black),
	'cyan-A400': (#00e5ff, $dark-black),
	'cyan-A700': (#00b8d4, $dark-black),

	'teal': (#009688, $dark-white),
	'teal-50': (#e0f2f1, $dark-black),
	'teal-100': (#b2dfdb, $dark-black),
	'teal-200': (#80cbc4, $dark-black),
	'teal-300': (#4db6ac, $dark-black),
	'teal-400': (#26a69a, $dark-black),
	'teal-500': (#009688, $dark-white),
	'teal-600': (#00897b, $dark-white),
	'teal-700': (#00796b, $dark-white),
	'teal-800': (#00695c, $dark-white),
	'teal-900': (#004d40, $dark-white),
	'teal-A100': (#a7ffeb, $dark-black),
	'teal-A200': (#64ffda, $dark-black),
	'teal-A400': (#1de9b6, $dark-black),
	'teal-A700': (#00bfa5, $dark-black),

	'green': (#4caf50, $dark-white),
	'green-50': (#e8f5e9, $dark-black),
	'green-100': (#c8e6c9, $dark-black),
	'green-200': (#a5d6a7, $dark-black),
	'green-300': (#81c784, $dark-black),
	'green-400': (#66bb6a, $dark-black),
	'green-500': (#4caf50, $dark-white),
	'green-600': (#43a047, $dark-white),
	'green-700': (#388e3c, $dark-white),
	'green-800': (#2e7d32, $dark-white),
	'green-900': (#1b5e20, $dark-white),
	'green-A100': (#b9f6ca, $dark-black),
	'green-A200': (#69f0ae, $dark-black),
	'green-A400': (#00e676, $dark-black),
	'green-A700': (#00c853, $dark-black),

	'light-green': (#8bc34a, $dark-black),
	'light-green-50': (#f1f8e9, $dark-black),
	'light-green-100': (#dcedc8, $dark-black),
	'light-green-200': (#c5e1a5, $dark-black),
	'light-green-300': (#aed581, $dark-black),
	'light-green-400': (#9ccc65, $dark-black),
	'light-green-500': (#8bc34a, $dark-black),
	'light-green-600': (#7cb342, $dark-black),
	'light-green-700': (#689f38, $dark-black),
	'light-green-800': (#558b2f, $dark-white),
	'light-green-900': (#33691e, $dark-white),
	'light-green-A100': (#ccff90, $dark-black),
	'light-green-A200': (#b2ff59, $dark-black),
	'light-green-A400': (#76ff03, $dark-black),
	'light-green-A700': (#64dd17, $dark-black),

	'lime': (#cddc39, $dark-black),
	'lime-50': (#f9fbe7, $dark-black),
	'lime-100': (#f0f4c3, $dark-black),
	'lime-200': (#e6ee9c, $dark-black),
	'lime-300': (#dce775, $dark-black),
	'lime-400': (#d4e157, $dark-black),
	'lime-500': (#cddc39, $dark-black),
	'lime-600': (#c0ca33, $dark-black),
	'lime-700': (#afb42b, $dark-black),
	'lime-800': (#9e9d24, $dark-black),
	'lime-900': (#827717, $dark-white),
	'lime-A100': (#f4ff81, $dark-black),
	'lime-A200': (#eeff41, $dark-black),
	'lime-A400': (#c6ff00, $dark-black),
	'lime-A700': (#aeea00, $dark-black),

	'yellow': (#ffeb3b, $dark-black),
	'yellow-50': (#fffde7, $dark-black),
	'yellow-100': (#fff9c4, $dark-black),
	'yellow-200': (#fff59d, $dark-black),
	'yellow-300': (#fff176, $dark-black),
	'yellow-400': (#ffee58, $dark-black),
	'yellow-500': (#ffeb3b, $dark-black),
	'yellow-600': (#fdd835, $dark-black),
	'yellow-700': (#fbc02d, $dark-black),
	'yellow-800': (#f9a825, $dark-black),
	'yellow-900': (#f57f17, $dark-black),
	'yellow-A100': (#ffff8d, $dark-black),
	'yellow-A200': (#ffff00, $dark-black),
	'yellow-A400': (#ffea00, $dark-black),
	'yellow-A700': (#ffd600, $dark-black),

	'amber': (#ffc107, $dark-black),
	'amber-50': (#fff8e1, $dark-black),
	'amber-100': (#ffecb3, $dark-black),
	'amber-200': (#ffe082, $dark-black),
	'amber-300': (#ffd54f, $dark-black),
	'amber-400': (#ffca28, $dark-black),
	'amber-500': (#ffc107, $dark-black),
	'amber-600': (#ffb300, $dark-black),
	'amber-700': (#ffa000, $dark-black),
	'amber-800': (#ff8f00, $dark-black),
	'amber-900': (#ff6f00, $dark-black),
	'amber-A100': (#ffe57f, $dark-black),
	'amber-A200': (#ffd740, $dark-black),
	'amber-A400': (#ffc400, $dark-black),
	'amber-A700': (#ffab00, $dark-black),

	'orange': (#ff9800, $dark-black),
	'orange-50': (#fff3e0, $dark-black),
	'orange-100': (#ffe0b2, $dark-black),
	'orange-200': (#ffcc80, $dark-black),
	'orange-300': (#ffb74d, $dark-black),
	'orange-400': (#ffa726, $dark-black),
	'orange-500': (#ff9800, $dark-black),
	'orange-600': (#fb8c00, $dark-black),
	'orange-700': (#f57c00, $dark-black),
	'orange-800': (#ef6c00, $dark-white),
	'orange-900': (#e65100, $dark-white),
	'orange-A100': (#ffd180, $dark-black),
	'orange-A200': (#ffab40, $dark-black),
	'orange-A400': (#ff9100, $dark-black),
	'orange-A700': (#ff6d00, $dark-black),

	'deep-orange': (#ff5722, $dark-white),
	'deep-orange-50': (#fbe9e7, $dark-black),
	'deep-orange-100': (#ffccbc, $dark-black),
	'deep-orange-200': (#ffab91, $dark-black),
	'deep-orange-300': (#ff8a65, $dark-black),
	'deep-orange-400': (#ff7043, $dark-black),
	'deep-orange-500': (#ff5722, $dark-white),
	'deep-orange-600': (#f4511e, $dark-white),
	'deep-orange-700': (#e64a19, $dark-white),
	'deep-orange-800': (#d84315, $dark-white),
	'deep-orange-900': (#bf360c, $dark-white),
	'deep-orange-A100': (#ff9e80, $dark-black),
	'deep-orange-A200': (#ff6e40, $dark-black),
	'deep-orange-A400': (#ff3d00, $dark-white),
	'deep-orange-A700': (#dd2c00, $dark-white),

	'brown': (#795548, $dark-white),
	'brown-50': (#efebe9, $dark-black),
	'brown-100': (#d7ccc8, $dark-black),
	'brown-200': (#bcaaa4, $dark-black),
	'brown-300': (#a1887f, $dark-white),
	'brown-400': (#8d6e63, $dark-white),
	'brown-500': (#795548, $dark-white),
	'brown-600': (#6d4c41, $dark-white),
	'brown-700': (#5d4037, $dark-white),
	'brown-800': (#4e342e, $dark-white),
	'brown-900': (#3e2723, $dark-white),

	'blue-grey': (#607d8b, $dark-white),
	'blue-grey-50': (#eceff1, $dark-black),
	'blue-grey-100': (#cfd8dc, $dark-black),
	'blue-grey-200': (#b0bec5, $dark-black),
	'blue-grey-300': (#90a4ae, $dark-black),
	'blue-grey-400': (#78909c, $dark-white),
	'blue-grey-500': (#607d8b, $dark-white),
	'blue-grey-600': (#546e7a, $dark-white),
	'blue-grey-700': (#455a64, $dark-white),
	'blue-grey-800': (#37474f, $dark-white),
	'blue-grey-900': (#263238, $dark-white),

	'grey': (#fafafa, $dark-black),
	'grey-100': (#f5f5f5, $dark-black),
	'grey-200': (#eeeeee, $dark-black),
	'grey-300': (#e0e0e0, $dark-black),
	'grey-400': (#bdbdbd, $dark-black),
	'grey-500': (#9e9e9e, $dark-black),
	'grey-600': (#757575, $dark-white),
	'grey-700': (#616161, $dark-white),
	'grey-800': (#424242, $dark-white),
	'grey-900': (#212121, $dark-white),
);



$red:   	#f44336;
$red-50:    #ffebee;
$red-100:   #ffcdd2;
$red-200:   #ef9a9a;
$red-300:   #e57373;
$red-400:   #ef5350;
$red-500:   #f44336;
$red-600:   #e53935;
$red-700:   #d32f2f;
$red-800:   #c62828;
$red-900:   #b71c1c;
$red-A100:  #ff8a80;
$red-A200:  #ff5252;
$red-A400:  #ff1744;
$red-A700:  #d50000;

$pink:  	#e91e63;
$pink-50:   #fce4ec;
$pink-100:  #f8bbd0;
$pink-200:  #f48fb1;
$pink-300:  #f06292;
$pink-400:  #ec407a;
$pink-500:  #e91e63;
$pink-600:  #d81b60;
$pink-700:  #c2185b;
$pink-800:  #ad1457;
$pink-900:  #880e4f;
$pink-A100: #ff80ab;
$pink-A200: #ff4081;
$pink-A400: #f50057;
$pink-A700: #c51162;

$purple:      #9c27b0;
$purple-50:   #f3e5f5;
$purple-100:  #e1bee7;
$purple-200:  #ce93d8;
$purple-300:  #ba68c8;
$purple-400:  #ab47bc;
$purple-500:  #9c27b0;
$purple-600:  #8e24aa;
$purple-700:  #7b1fa2;
$purple-800:  #6a1b9a;
$purple-900:  #4a148c;
$purple-A100: #ea80fc;
$purple-A200: #e040fb;
$purple-A400: #d500f9;
$purple-A700: #aa00ff;

$deep-purple:   	#673ab7;
$deep-purple-50:    #ede7f6;
$deep-purple-100:   #d1c4e9;
$deep-purple-200:   #b39ddb;
$deep-purple-300:   #9575cd;
$deep-purple-400:   #7e57c2;
$deep-purple-500:   #673ab7;
$deep-purple-600:   #5e35b1;
$deep-purple-700:   #512da8;
$deep-purple-800:   #4527a0;
$deep-purple-900:   #311b92;
$deep-purple-A100:  #b388ff;
$deep-purple-A200:  #7c4dff;
$deep-purple-A400:  #651fff;
$deep-purple-A700:  #6200ea;

$indigo:      #3f51b5;
$indigo-50:   #e8eaf6;
$indigo-100:  #c5cae9;
$indigo-200:  #9fa8da;
$indigo-300:  #7986cb;
$indigo-400:  #5c6bc0;
$indigo-500:  #3f51b5;
$indigo-600:  #3949ab;
$indigo-700:  #303f9f;
$indigo-800:  #283593;
$indigo-900:  #1a237e;
$indigo-A100: #8c9eff;
$indigo-A200: #536dfe;
$indigo-A400: #3d5afe;
$indigo-A700: #304ffe;

$blue:  	#2196f3;
$blue-50:   #e3f2fd;
$blue-100:  #bbdefb;
$blue-200:  #90caf9;
$blue-300:  #64b5f6;
$blue-400:  #42a5f5;
$blue-500:  #2196f3;
$blue-600:  #1e88e5;
$blue-700:  #1976d2;
$blue-800:  #1565c0;
$blue-900:  #0d47a1;
$blue-A100: #82b1ff;
$blue-A200: #448aff;
$blue-A400: #2979ff;
$blue-A700: #2962ff;

$light-blue:  	  #03a9f4;
$light-blue-50:   #e1f5fe;
$light-blue-100:  #b3e5fc;
$light-blue-200:  #81d4fa;
$light-blue-300:  #4fc3f7;
$light-blue-400:  #29b6f6;
$light-blue-500:  #03a9f4;
$light-blue-600:  #039be5;
$light-blue-700:  #0288d1;
$light-blue-800:  #0277bd;
$light-blue-900:  #01579b;
$light-blue-A100: #80d8ff;
$light-blue-A200: #40c4ff;
$light-blue-A400: #00b0ff;
$light-blue-A700: #0091ea;

$cyan:  	#00bcd4;
$cyan-50:   #e0f7fa;
$cyan-100:  #b2ebf2;
$cyan-200:  #80deea;
$cyan-300:  #4dd0e1;
$cyan-400:  #26c6da;
$cyan-500:  #00bcd4;
$cyan-600:  #00acc1;
$cyan-700:  #0097a7;
$cyan-800:  #00838f;
$cyan-900:  #006064;
$cyan-A100: #84ffff;
$cyan-A200: #18ffff;
$cyan-A400: #00e5ff;
$cyan-A700: #00b8d4;

$teal:  	#009688;
$teal-50:   #e0f2f1;
$teal-100:  #b2dfdb;
$teal-200:  #80cbc4;
$teal-300:  #4db6ac;
$teal-400:  #26a69a;
$teal-500:  #009688;
$teal-600:  #00897b;
$teal-700:  #00796b;
$teal-800:  #00695c;
$teal-900:  #004d40;
$teal-A100: #a7ffeb;
$teal-A200: #64ffda;
$teal-A400: #1de9b6;
$teal-A700: #00bfa5;

$green:   	  #4caf50;
$green-50:    #e8f5e9;
$green-100:   #c8e6c9;
$green-200:   #a5d6a7;
$green-300:   #81c784;
$green-400:   #66bb6a;
$green-500:   #4caf50;
$green-600:   #43a047;
$green-700:   #388e3c;
$green-800:   #2e7d32;
$green-900:   #1b5e20;
$green-A100:  #b9f6ca;
$green-A200:  #69f0ae;
$green-A400:  #00e676;
$green-A700:  #00c853;

$light-green:   	#8bc34a;
$light-green-50:    #f1f8e9;
$light-green-100:   #dcedc8;
$light-green-200:   #c5e1a5;
$light-green-300:   #aed581;
$light-green-400:   #9ccc65;
$light-green-500:   #8bc34a;
$light-green-600:   #7cb342;
$light-green-700:   #689f38;
$light-green-800:   #558b2f;
$light-green-900:   #33691e;
$light-green-A100:  #ccff90;
$light-green-A200:  #b2ff59;
$light-green-A400:  #76ff03;
$light-green-A700:  #64dd17;

$lime:  	#cddc39;
$lime-50:   #f9fbe7;
$lime-100:  #f0f4c3;
$lime-200:  #e6ee9c;
$lime-300:  #dce775;
$lime-400:  #d4e157;
$lime-500:  #cddc39;
$lime-600:  #c0ca33;
$lime-700:  #afb42b;
$lime-800:  #9e9d24;
$lime-900:  #827717;
$lime-A100: #f4ff81;
$lime-A200: #eeff41;
$lime-A400: #c6ff00;
$lime-A700: #aeea00;

$yellow:  	  #ffeb3b;
$yellow-50:   #fffde7;
$yellow-100:  #fff9c4;
$yellow-200:  #fff59d;
$yellow-300:  #fff176;
$yellow-400:  #ffee58;
$yellow-500:  #ffeb3b;
$yellow-600:  #fdd835;
$yellow-700:  #fbc02d;
$yellow-800:  #f9a825;
$yellow-900:  #f57f17;
$yellow-A100: #ffff8d;
$yellow-A200: #ffff00;
$yellow-A400: #ffea00;
$yellow-A700: #ffd600;

$amber:   	  #ffc107;
$amber-50:    #fff8e1;
$amber-100:   #ffecb3;
$amber-200:   #ffe082;
$amber-300:   #ffd54f;
$amber-400:   #ffca28;
$amber-500:   #ffc107;
$amber-600:   #ffb300;
$amber-700:   #ffa000;
$amber-800:   #ff8f00;
$amber-900:   #ff6f00;
$amber-A100:  #ffe57f;
$amber-A200:  #ffd740;
$amber-A400:  #ffc400;
$amber-A700:  #ffab00;

$orange:  	  #ff9800;
$orange-50:   #fff3e0;
$orange-100:  #ffe0b2;
$orange-200:  #ffcc80;
$orange-300:  #ffb74d;
$orange-400:  #ffa726;
$orange-500:  #ff9800;
$orange-600:  #fb8c00;
$orange-700:  #f57c00;
$orange-800:  #ef6c00;
$orange-900:  #e65100;
$orange-A100: #ffd180;
$orange-A200: #ffab40;
$orange-A400: #ff9100;
$orange-A700: #ff6d00;

$deep-orange:   	#ff5722;
$deep-orange-50:    #fbe9e7;
$deep-orange-100:   #ffccbc;
$deep-orange-200:   #ffab91;
$deep-orange-300:   #ff8a65;
$deep-orange-400:   #ff7043;
$deep-orange-500:   #ff5722;
$deep-orange-600:   #f4511e;
$deep-orange-700:   #e64a19;
$deep-orange-800:   #d84315;
$deep-orange-900:   #bf360c;
$deep-orange-A100:  #ff9e80;
$deep-orange-A200:  #ff6e40;
$deep-orange-A400:  #ff3d00;
$deep-orange-A700:  #dd2c00;

$brown:  	 #795548;
$brown-50:   #efebe9;
$brown-100:  #d7ccc8;
$brown-200:  #bcaaa4;
$brown-300:  #a1887f;
$brown-400:  #8d6e63;
$brown-500:  #795548;
$brown-600:  #6d4c41;
$brown-700:  #5d4037;
$brown-800:  #4e342e;
$brown-900:  #3e2723;

$blue-grey:  	 #607d8b;
$blue-grey-50:   #eceff1;
$blue-grey-100:  #cfd8dc;
$blue-grey-200:  #b0bec5;
$blue-grey-300:  #90a4ae;
$blue-grey-400:  #78909c;
$blue-grey-500:  #607d8b;
$blue-grey-600:  #546e7a;
$blue-grey-700:  #455a64;
$blue-grey-800:  #37474f;
$blue-grey-900:  #263238;

$grey-50:   #fafafa;
$grey-100:  #f5f5f5;
$grey-200:  #eeeeee;
$grey-300:  #e0e0e0;
$grey-400:  #bdbdbd;
$grey-500:  #9e9e9e;
$grey-600:  #757575;
$grey-700:  #616161;
$grey-800:  #424242;
$grey-900:  #212121;
