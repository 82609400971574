/*
Row 
*/
.col-0{
  clear:#{$direction_1};
}

.row.no-gutter{
  margin-#{$direction_1}: 0;
  margin-#{$direction_2}: 0;
  [class*="col-"]{
    padding-#{$direction_1}: 0;
    padding-#{$direction_2}: 0;
  }
}

.row{
  margin-#{$direction_1}:  -12px;
  margin-#{$direction_2}: -12px;
  [class*="col-"]{
    padding-#{$direction_1}: 12px;
    padding-#{$direction_2}: 12px;
  }
}

.row-lg{
  margin-#{$direction_1}: -20px;
  margin-#{$direction_2}: -20px;
  [class*="col-"]{
    padding-#{$direction_1}: 20px;
    padding-#{$direction_2}: 20px;
  }
}

.row-md{
  margin-#{$direction_1}: -16px;
  margin-#{$direction_2}: -16px;
  [class*="col-"]{
    padding-#{$direction_1}: 16px;
    padding-#{$direction_2}: 16px;
  }
}

.row-sm{
  margin-#{$direction_1}: -8px;
  margin-#{$direction_2}: -8px;
  [class*="col-"]{
    padding-#{$direction_1}: 8px;
    padding-#{$direction_2}: 8px;
  }
}

.row-xs{
  margin-#{$direction_1}: -4px;
  margin-#{$direction_2}: -4px;
  [class*="col-"]{
    padding-#{$direction_1}: 4px;
    padding-#{$direction_2}: 4px;
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-2-4{
    width: 20.000%;
    float: #{$direction_1};
  }
}

@include media-breakpoint-down(md) {
  .row{
    margin-#{$direction_1}: -8px;
    margin-#{$direction_2}: -8px;
    [class*="col-"]{
      padding-#{$direction_1}: 8px;
      padding-#{$direction_2}: 8px;
    }
  }
  .row-2 [class*="col"]{
    width: 50%;
    float: #{$direction_1};
  }
  .row-2 .col-0{
    clear: none;
  }
  .row-2 li:nth-child(odd) { 
    clear: #{$direction_1};
    margin-#{$direction_1}: 0;
  }
}

@include media-breakpoint-down(sm) {
  .row{
    margin-#{$direction_1}: -4px;
    margin-#{$direction_2}: -4px;
    [class*="col-"]{
      padding-#{$direction_1}: 4px;
      padding-#{$direction_2}: 4px;
    }
  }
}
