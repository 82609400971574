$rangeslider:               ".rangeslider";
$rangeslider--horizontal:   ".rangeslider--horizontal";
$rangeslider--vertical:     ".rangeslider--vertical";
$rangeslider--disabled:     ".rangeslider--disabled";
$rangeslider--active:       ".rangeslider--active";
$rangeslider__fill:         ".rangeslider__fill";
$rangeslider__handle:       ".rangeslider__handle";

#{$rangeslider},
#{$rangeslider__fill} {
    display: block;
    @include box-shadow(inset 0px 1px 3px rgba(0,0,0,0.3));
    @include border-radius(10px);
}

#{$rangeslider} {
    background: #e6e6e6;
    position: relative;
}

#{$rangeslider--horizontal} {
    height: 20px;
    width: 100%;
}

#{$rangeslider--vertical} {
    width: 20px;
    min-height: 150px;
    max-height: 100%;
}

#{$rangeslider--disabled} {
    @include opacity(.4);
}

#{$rangeslider__fill} {
    background: #00ff00;
    position: absolute;

    #{$rangeslider--horizontal} & {
        top: 0;
        height: 100%;
    }

    #{$rangeslider--vertical} & {
        bottom: 0;
        width: 100%;
    }
}

#{$rangeslider__handle} {
    background: white;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    position: absolute;
    @include background-image(linear-gradient(rgba(white, 0), rgba(black, .10)));
    @include box-shadow(0 0 8px rgba(black, .3));
    @include border-radius(50%);

    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include background-image(linear-gradient(rgba(black, .13), rgba(white, 0)));
        @include border-radius(50%);
    }

    &:active,
    #{$rangeslider--active} & {
        @include background-image(linear-gradient(rgba(black, .10), rgba(black, .12)));
    }

    #{$rangeslider--horizontal} & {
        top: -10px;
        touch-action: pan-y;
        -ms-touch-action: pan-y;
    }

    #{$rangeslider--vertical} & {
        left: -10px;
        touch-action: pan-x;
        -ms-touch-action: pan-x;
    }
}

input[type="range"]:focus + #{$rangeslider} #{$rangeslider__handle} {
    @include box-shadow(0 0 8px rgba(#ff00ff, .9));
}