// base styles

$selectize-font-family: inherit !default;
$selectize-font-smoothing: inherit !default;
$selectize-font-size: 13px !default;
$selectize-line-height: 18px !default;

$selectize-color-text: #303030 !default;
$selectize-color-border: #d0d0d0 !default;
$selectize-color-highlight: rgba(125,168,208,0.2) !default;
$selectize-color-input: #fff !default;
$selectize-color-input-full: $selectize-color-input !default;
$selectize-color-disabled: #fafafa !default;
$selectize-color-item: #f2f2f2 !default;
$selectize-color-item-text: $selectize-color-text !default;
$selectize-color-item-border: #d0d0d0 !default;
$selectize-color-item-active: #e8e8e8 !default;
$selectize-color-item-active-text: $selectize-color-text !default;
$selectize-color-item-active-border: #cacaca !default;
$selectize-color-dropdown: #fff !default;
$selectize-color-dropdown-dark: #16202f !default;
$selectize-color-dropdown-border: $selectize-color-border !default;
$selectize-color-dropdown-border-top: #f0f0f0 !default;
$selectize-color-dropdown-item-active: #f5fafd !default;
$selectize-color-dropdown-item-active-text: #495c68 !default;

$selectize-color-dropdown-item-active-dark: $min-white !default;
$selectize-color-dropdown-item-active-text-dark: $light !default;

$selectize-color-dropdown-item-create-text: rgba(red($selectize-color-text), green($selectize-color-text), blue($selectize-color-text), 0.5) !default;
$selectize-color-dropdown-item-create-active-text: $selectize-color-dropdown-item-active-text !default;

$selectize-color-dropdown-item-create-active-text-dark: $selectize-color-dropdown-item-active-text-dark !default;

$selectize-color-optgroup: $selectize-color-dropdown !default;
$selectize-color-optgroup-text: $selectize-color-text !default;
$selectize-lighten-disabled-item: 30% !default;
$selectize-lighten-disabled-item-text: 30% !default;
$selectize-lighten-disabled-item-border: 30% !default;
$selectize-opacity-disabled: 0.5 !default;

$selectize-shadow-input: inset 0 1px 1px rgba(0,0,0,0.1) !default;
$selectize-shadow-input-focus: inset 0 1px 2px rgba(0,0,0,0.15) !default;
$selectize-border: 1px solid $selectize-color-border !default;
$selectize-dropdown-border: 1px solid $selectize-color-dropdown-border !default;
$selectize-border-radius: 3px !default;

$selectize-width-item-border: 0 !default;
$selectize-max-height-dropdown: 200px !default;

$selectize-padding-x: 8px !default;
$selectize-padding-y: 8px !default;
$selectize-padding-item-x: 6px !default;
$selectize-padding-item-y: 2px !default;
$selectize-padding-dropdown-item-x: $selectize-padding-x !default;
$selectize-padding-dropdown-item-y: 5px !default;
$selectize-margin-item-x: 3px !default;
$selectize-margin-item-y: 3px !default;

$selectize-arrow-size: 5px !default;
$selectize-arrow-color: #808080 !default;
$selectize-arrow-color-dark: rgba(225, 235, 245, 0.87) !default;
$selectize-arrow-offset: 15px !default;

$selectize-caret-margin: 0 2px 0 0 !default;
$selectize-caret-margin-rtl: 0 4px 0 -2px !default;

@mixin selectize-border-radius($radii)
{
	-webkit-border-radius: $radii;
	-moz-border-radius: $radii;
	border-radius: $radii;
}
@mixin selectize-unselectable()
{
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
@mixin box-shadow($shadow)
{
	-webkit-box-shadow: $shadow;
	box-shadow: $shadow;
}
@mixin selectize-box-sizing($type: border-box)
{
	-webkit-box-sizing: $type;
	-moz-box-sizing: $type;
	box-sizing: $type;
}
@mixin selectize-vertical-gradient($color-top, $color-bottom)
{
    background-color: mix($color-top, $color-bottom, 60%);
    background-image: -moz-linear-gradient(top, $color-top, $color-bottom); // FF 3.6+
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($color-top), to($color-bottom)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, $color-top, $color-bottom); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, $color-top, $color-bottom); // Opera 11.10
    background-image: linear-gradient(to bottom, $color-top, $color-bottom); // Standard, IE10
    background-repeat: repeat-x;
    //filter: e("progid:DXImageTransform.Microsoft@include gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb($color-top),argb($color-bottom)); // IE9 and down
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
    border-color: $dark;
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
    border-color: $grey;
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
    border-color: $dark;
  }
}

$size: 15px;

@keyframes loader-inner {
  0% {
    height: 0px;
  }
  
  25% {
    height: 0px;
  }
  
  50% {
    height: $size;
  }
  
  75% {
    height: $size;
  }
  
  100% {
    height: 0;
  }
}

.selectize-control {
	position: relative;

	&.loading {
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			margin-top: -$size / 2;

			#{$direction_2}: 2rem;
			height: $size;
			width: $size;

			display: inline-block;
			border: 4px solid $dark;
			animation: loader 2s infinite ease;
			  
			z-index: 11;
		}

		&:before {
			content:  '';

			position: absolute;
			bottom: 50%;
			margin-bottom: -$size / 2;
			#{$direction_2}: 2rem;
			height: $size;
			width: $size;

			vertical-align: top;
			display: inline-block;
			background-color: $grey;
			animation: loader 2s infinite ease , loader-inner 2s infinite ease-in,;

			z-index: 10;
		}
	}
}

.selectize-dropdown, .selectize-input, .selectize-input input {
	color: $selectize-color-text;
	font-family: $selectize-font-family;
	font-size: $selectize-font-size;
	line-height: $selectize-line-height;
	-webkit-font-smoothing: $selectize-font-smoothing;
}

.dark , .black , .grey {
	.selectize-dropdown, .selectize-dropdown.form-control {
		background: $selectize-color-dropdown-dark;
		background-color: $selectize-color-dropdown-dark;
		color: inherit;
	}
}

.selectize-input, .selectize-control.single .selectize-input.input-active {
	background: $selectize-color-input;
	cursor: text;
	display: inline-block;
}

.selectize-input {
	border: $selectize-border;
	padding: $selectize-padding-y $selectize-padding-x;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	@include selectize-box-sizing(border-box);
	@include box-shadow($selectize-shadow-input);
	@include selectize-border-radius($selectize-border-radius);

	.selectize-control.multi &.has-items {
		$padding-x: $selectize-padding-x;
		$padding-top: $selectize-padding-y - $selectize-padding-item-y - $selectize-width-item-border;
		$padding-bottom: $selectize-padding-y - $selectize-padding-item-y - $selectize-margin-item-y - $selectize-width-item-border;
		padding: $padding-top $padding-x $padding-bottom;
	}

	&.full {
		background-color: $selectize-color-input-full;
	}
	&.disabled, &.disabled * {
		cursor: default !important;
	}
	&.focus {
		//@include box-shadow($selectize-shadow-input-focus);
		@include box-shadow($input-box-shadow);
	}
	&.dropdown-active {
		@include box-shadow($input-box-shadow);
		//@include selectize-border-radius($selectize-border-radius $selectize-border-radius 0 0);
	}

	> * {
		vertical-align: baseline;
		display: -moz-inline-stack;
		display: inline-block;
		zoom: 1;
		float: $direction_1;
	}
	.selectize-control.multi & > div {
		cursor: pointer;
		margin: 0 $selectize-margin-item-x $selectize-margin-item-y 0;
		padding: $selectize-padding-item-y $selectize-padding-item-x;
		background: $selectize-color-item;
		color: $selectize-color-item-text;
		border: $selectize-width-item-border solid $selectize-color-item-border;

		&.active {
			background: $selectize-color-item-active;
			color: $selectize-color-item-active-text;
			border: $selectize-width-item-border solid $selectize-color-item-active-border;
		}
	}
	.selectize-control.multi &.disabled > div {
		&, &.active {
			color: lighten(desaturate($selectize-color-item-text, 100%), $selectize-lighten-disabled-item-text);
			background: lighten(desaturate($selectize-color-item, 100%), $selectize-lighten-disabled-item);
			border: $selectize-width-item-border solid lighten(desaturate($selectize-color-item-border, 100%), $selectize-lighten-disabled-item-border);
		}
	}
	> input {
		&::-ms-clear {
			display: none;
		}
		display: inline-block !important;
		padding: 0 !important;
		min-height: 0 !important;
		max-height: none !important;
		max-width: 100% !important;
		min-width: 20px !important;
		margin: $selectize-caret-margin !important;
		text-indent: 0 !important;
		border: 0 none !important;
		background: none !important;
		line-height: inherit !important;
		-webkit-user-select: auto !important;
		@include box-shadow(none);
		&:focus { outline: none !important; }

		&::-webkit-contacts-auto-fill-button {
			visibility: hidden;
			display: none !important;
			pointer-events: none;
			position: absolute;
			right: 0;
		}
	}
}

.dark , .black , .grey {
	.selectize-input, .selectize-control.single .selectize-input.input-active, .selectize-input.full {
		background: transparent;
		background-color: transparent;
		color: inherit;
	}
}

.selectize-input::after {
	content: ' ';
	display: block;
	clear: left;
}

.selectize-input.dropdown-active::before {
	content: ' ';
	display: block;
	position: absolute;
	background: $selectize-color-dropdown-border-top;
	height: 1px;
	bottom: 0;
	left: 0;
	right: 0;
}

.selectize-dropdown {
	position: absolute;
	z-index: 10;
	border: $selectize-dropdown-border;
	background: $selectize-color-dropdown;
	margin: -1px 0 0 0;
	border-top: 0 none;
	@include selectize-box-sizing(border-box);
	@include box-shadow(0 1px 3px rgba(0,0,0,0.1));
	@include selectize-border-radius(0 0 $selectize-border-radius $selectize-border-radius);

	[data-selectable] {
		cursor: pointer;
		overflow: hidden;
		.highlight {
			background: $selectize-color-highlight;
			@include selectize-border-radius(1px);
		}
	}
	[data-selectable], .optgroup-header {
		padding: $selectize-padding-dropdown-item-y $selectize-padding-dropdown-item-x;
	}
	.optgroup:first-child .optgroup-header {
		border-top: 0 none;
	}
	.optgroup-header {
		color: $selectize-color-optgroup-text;
		background: $selectize-color-optgroup;
		cursor: default;
	}
	.active {
		background-color: $selectize-color-dropdown-item-active;
		color: $selectize-color-dropdown-item-active-text;
		&.create {
			color: $selectize-color-dropdown-item-create-active-text;
		}
	}
	.create {
		color: $selectize-color-dropdown-item-create-text;
	}
}

.dark , .black , .grey {
	.selectize-dropdown {
		.active {
			background-color: $selectize-color-dropdown-item-active-dark;
			color: $selectize-color-dropdown-item-active-text-dark;
			&.create {
				color: $selectize-color-dropdown-item-create-active-text-dark;
			}
		}
	}
}

.selectize-dropdown-content {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: $selectize-max-height-dropdown;
	-webkit-overflow-scrolling: touch;
}

.selectize-control.single .selectize-input {
	&, input { cursor: pointer; }
	&.input-active, &.input-active input { cursor: text; }

	&:after {
		content: ' ';
		display: block;
		position: absolute;
		top: 50%;
		right: $selectize-arrow-offset;
		transform: translateY(-50%);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: $selectize-arrow-size $selectize-arrow-size 0 $selectize-arrow-size;
		border-color: $selectize-arrow-color transparent transparent transparent;
	}
	&.dropdown-active:after {
		border-width: 0 $selectize-arrow-size $selectize-arrow-size $selectize-arrow-size;
		border-color: transparent transparent $selectize-arrow-color transparent;
	}
}


.dark , .black , .grey {
	.selectize-control.single .selectize-input {
		&:after {
			border-color: $selectize-arrow-color-dark transparent transparent transparent;
		}
		&.dropdown-active:after {
			border-color: transparent transparent $selectize-arrow-color-dark transparent;
		}
	}
}

.selectize-control.rtl {
	&.single .selectize-input:after {
		left: $selectize-arrow-offset;
		right: auto;
	}
	.selectize-input > input {
		margin: $selectize-caret-margin-rtl !important;
	}
}

.selectize-control .selectize-input.disabled {
	opacity: $selectize-opacity-disabled;
	background-color: $selectize-color-disabled;
}