@mixin hover-animate-wariant($direction, $distance, $name: false){

  @if($name != false) {
    $name: #{$direction}-#{$name};
  } @else {
    $name: $direction;
  }

  .hover-animate-#{$name} {
    @include transition(all .3s ease-in-out);

    &:focus,
    &:hover {
      @if ($direction == 'top') {
        transform: translateY(-$distance);
      } @else if ($direction == 'right') {
        @if ($rtl) {
          transform: translateX(-$distance);
        } @else {
          transform: translateX($distance);
        }

      } @else if ($direction == 'bottom') {
        transform: translateY($distance);
      } @else if ($direction == 'left') {
        @if ($rtl) {
          transform: translateX($distance);
        } @else {
          transform: translateX(-$distance);
        }
      }
    }
  }
}
/*
Hover
*/
.hover-action{
  display: none;    
}
.hover-rotate{
  @include transition(all .3s ease-in-out)
}
.hover-anchor:hover,
.hover-anchor:focus,
.hover-anchor:active{
  .hover-action{
    display: inherit;
  }
  .hover-rotate{
    @include rotate(45deg)
  }
}

@include hover-animate-wariant('top', $spacer);
@include hover-animate-wariant('right', $spacer);
@include hover-animate-wariant('bottom', $spacer);
@include hover-animate-wariant('left', $spacer);

@include hover-animate-wariant('top', $spacer * 3, 'lg');
@include hover-animate-wariant('right', $spacer * 3, 'lg');
@include hover-animate-wariant('bottom', $spacer * 3, 'lg');
@include hover-animate-wariant('left', $spacer * 3, 'lg');

@include hover-animate-wariant('top', $spacer * 1.5, 'md');
@include hover-animate-wariant('right', $spacer * 1.5, 'md');
@include hover-animate-wariant('bottom', $spacer * 1.5, 'md');
@include hover-animate-wariant('left', $spacer * 1.5, 'md');

@include hover-animate-wariant('top', $spacer * 0.5, 'sm');
@include hover-animate-wariant('right', $spacer * 0.5, 'sm');
@include hover-animate-wariant('bottom', $spacer * 0.5, 'sm');
@include hover-animate-wariant('left', $spacer * 0.5, 'sm');

@include hover-animate-wariant('top', $spacer * 0.25, 'xs');
@include hover-animate-wariant('right', $spacer * 0.25, 'xs');
@include hover-animate-wariant('bottom', $spacer * 0.25, 'xs');
@include hover-animate-wariant('left', $spacer * 0.25, 'xs');

