@mixin positions($size, $sufix, $important) {

  @if($important != false) {
    $sufix: #{$sufix}-important;
  }

  .pos-stc#{$size}#{$sufix}{
    @if($important) {
      position: static !important;
    } @else {
      position: static;
    }
  }
  .pos-rlt#{$size}#{$sufix}{
    @if($important) {
      position: relative !important;
      z-index: 1 !important;
    } @else {
      position: relative;
      z-index: 1;
    }
  }
  .pos-rlt-10#{$size}#{$sufix}{
    @if($important) {
      position: relative !important;
      z-index: 10 !important;
    } @else {
      position: relative;
      z-index: 10;
    }
  }

  .pos-rlt-100-#{$size}#{$sufix}{
    @if($important) {
      position: relative !important;
      z-index: 100 !important;
    } @else {
      position: relative;
      z-index: 100;
    }
  }

  .pos-rlt-1000#{$size}#{$sufix}{
    @if($important) {
      position: relative !important;
      z-index: 1000 !important;
    } @else {
      position: relative;
      z-index: 1000;
    }
  }

  .pos-abt#{$size}#{$sufix}{
    @if($important) {
      position: absolute !important;
    } @else {
      position: absolute;
    }
  }

  .pos-fix#{$size}#{$sufix}{
    @if($important) {
      position: fixed !important;
    } @else {
      position: fixed;
    }
  }

  .block#{$size}#{$sufix}{
    @if($important) {
      display: block !important;
    } @else {
      display: block;
    }
  }
  .block#{$size}#{$sufix}.hide{
    @if($important) {
      display: none !important;
    } @else {
      display: none;
    }
  }

  .inline-16#{$size}#{$sufix}{
    @if($important) {
      width: 16px !important;
      height: 16px !important;
    } @else {
      width: 16px;
      height: 16px;
    }
  }

  .inline-24#{$size}#{$sufix}{
    @if($important) {
      width: 24px !important;
      height: 24px !important;
    } @else {
      width: 24px;
      height: 24px;
    }
  }
  .pull-none#{$size}#{$sufix}{
    @if($important) {
      float: none !important;
    } @else {
      float: none;
    }
  }
  .inline#{$size}#{$sufix}{
    @if($important) {
      display: inline-block !important;
    } @else {
      display: inline-block;
    }
  }
}


/*
Base
*/
.dir-ltr {
  direction: ltr;
  display: inline-block;
}

.dir-rtl {
  direction: rtl;
  display: inline-block;
}

.none{
  display: none;
}
.active > .inline, 
.active > .auto .inline{
  display: none;
}
.active > .none, 
.active > .auto .none{
  display: inline-block;
}

.hide {
  display: none !important;
}
.show {
  display: block !important;
}

.rounded,
.circle{
  border-radius: 500px;
}

.clear{
  display:block;
  overflow: hidden;
}

.o-hidden {
  overflow: hidden;
}
.o-x-hidden {
  overflow-x: hidden;
}
.o-y-hidden {
  overflow-y: hidden;
}

.o-scroll {
  overflow: scroll;
}
.o-x-scroll {
  overflow-x: scroll;
}
.o-y-scroll {
  overflow-y: scroll;
}

.o-auto {
  overflow: auto;
}
.o-x-auto {
  overflow-x: auto;
}
.o-y-auto {
  overflow-y: auto;
}

.no-shadow{
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@include positions('', '', false);
@include positions('', '', true);

@include media-breakpoint-down(lg) {
  @include positions('-lg', '', false);
  @include positions('-lg', '', true);
}

@include media-breakpoint-down(md) {
  @include positions('-md', '', false);
  @include positions('-md', '', true);
}

@include media-breakpoint-down(sm) {
  @include positions('-sm', '', false);
  @include positions('-sm', '', true);
}

@include media-breakpoint-down(xs) {
  @include positions('-xs', '', false);
  @include positions('-xs', '', true);
}


@include media-breakpoint-up(lg) {
  @include positions('-lg', '-up', false);
  @include positions('-lg', '-up', true);
}

@include media-breakpoint-up(md) {
  @include positions('-md', '-up', false);
  @include positions('-md', '-up', true);
}
@include media-breakpoint-up(sm) {
  @include positions('-sm', '-up', false);
  @include positions('-sm', '-up', true);
}

@include media-breakpoint-up(xs) {
  @include positions('-xs', '-up', false);
  @include positions('-xs', '-up', true);
}