/*
Buttons 
*/
@mixin btn-outline($color, $color-color){

	&:not([href]):not([tabindex]),
	&:hover,
	&:focus{
		color: $color-color !important;
		background-color: $color;
	}
}

.btn{
	font-weight: 500;
	outline: 0!important;
	&:not([disabled]):hover,
	&:not([disabled]):focus,
	&:not([disabled]).active{
		box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
	}
	> i{
		&.pull-#{$direction_1},
		&.pull-#{$direction_2}{
			line-height: 1.5;
		}
	}
	&.rounded{
		padding-#{$direction_1}: 1.2em;
		padding-#{$direction_2}: 1.2em;
	}
	&.btn-cornered {
		border-radius: 0;
	}
	&.btn-no-border {
		border: none;
	}
}

.btn-lg{
	
}

.btn-md{
	padding: .4695rem .75rem;
	font-size: 0.9rem;
}

.btn-sm{
	
}

.btn-xs{
	padding: .2195rem .5rem;
	font-size: 0.8rem;
}

.btn-outline{
	border-width: 1px;
	background-color: transparent;
	box-shadow: none !important;
}

.btn{
	&.b-primary,
	&.primary{
		@include btn-outline($primary, $primary-color);
	}
	&.b-accent,
	&.accent{
		@include btn-outline($accent, $accent-color);
	}
	&.b-warn,
	&.warn{
		@include btn-outline($warn, $warn-color);
	}
	&.b-success,
	&.success{
		@include btn-outline($success, $success-color);
	}
	&.b-info,
	&.info{
		@include btn-outline($info, $info-color);
	}
	&.b-warning,
	&.warning{
		@include btn-outline($warning, $warning-color);
	}
	&.b-danger,
	&.danger{
		@include btn-outline($danger, $danger-color);
	}
	&.b-dark,
	&.dark{
		@include btn-outline($dark, $dark-color);
	}
	&.b-black,
	&.black{
		@include btn-outline($black, $black-color);
	}
	&.b-white{
		@include btn-outline($white, $dark-black);
	}
	&.b-light{
		@include btn-outline($light, $light-color);
	}

	&.red,
	&.b-red { @include btn-outline($red-500, $dark-white); }

	&.bred-50,
	&.b-bred-50 { @include btn-outline($red-50, $dark-black); }

	&.red-100,
	&.b-red-100 { @include btn-outline($red-100, $dark-black); }

	&.red-200,
	&.b-red-200 { @include btn-outline($red-200, $dark-black); }

	&.red-300,
	&.b-red-300 { @include btn-outline($red-300, $dark-black); }

	&.red-400,
	&.b-red-400 { @include btn-outline($red-400, $dark-black); }

	&.red-500,
	&.b-red-500 { @include btn-outline($red-500, $dark-white); }

	&.red-600,
	&.b-red-600 { @include btn-outline($red-600, $dark-white); }

	&.red-700,
	&.b-red-700 { @include btn-outline($red-700, $dark-white); }

	&.red-800,
	&.b-red-800 { @include btn-outline($red-800, $dark-white); }

	&.red-900,
	&.b-red-900 { @include btn-outline($red-900, $dark-white); }

	&.red-A100,
	&.b-red-A100 { @include btn-outline($red-A100, $dark-black); }

	&.red-A200,
	&.b-red-A200 { @include btn-outline($red-A200, $dark-white); }

	&.red-A400,
	&.b-red-A400 { @include btn-outline($red-A400, $dark-white); }

	&.red-A700,
	&.b-red-A700 { @include btn-outline($red-A700, $dark-white); }


	&.pink,
	&.b-pink { @include btn-outline($pink-500, $dark-white); }

	&.pink-50,
	&.b-pink-50 { @include btn-outline($pink-50, $dark-black); }

	&.pink-100,
	&.b-pink-100 { @include btn-outline($pink-100, $dark-black); }

	&.pink-200,
	&.b-pink-200 { @include btn-outline($pink-200, $dark-black); }

	&.pink-300,
	&.b-pink-300 { @include btn-outline($pink-300, $dark-black); }

	&.pink-400,
	&.b-pink-400 { @include btn-outline($pink-400, $dark-black); }

	&.pink-500,
	&.b-pink-500 { @include btn-outline($pink-500, $dark-white); }

	&.pink-600,
	&.b-pink-600 { @include btn-outline($pink-600, $dark-white); }

	&.pink-700,
	&.b-pink-700 { @include btn-outline($pink-700, $dark-white); }

	&.pink-800,
	&.b-pink-800 { @include btn-outline($pink-800, $dark-white); }

	&.pink-900,
	&.b-pink-900 { @include btn-outline($pink-900, $dark-white); }

	&.pink-A100,
	&.b-pink-A100 { @include btn-outline($pink-A100, $dark-black); }

	&.pink-A200,
	&.b-pink-A200 { @include btn-outline($pink-A200, $dark-white); }

	&.pink-A400,
	&.b-pink-A400 { @include btn-outline($pink-A400, $dark-white); }

	&.pink-A700,
	&.b-pink-A700 { @include btn-outline($pink-A700, $dark-white); }


	&.purple,
	&.b-purple { @include btn-outline($purple-500, $dark-white); }

	&.purple-50,
	&.b-purple-50 { @include btn-outline($purple-50, $dark-black); }

	&.purple-100,
	&.b-purple-100 { @include btn-outline($purple-100, $dark-black); }

	&.purple-200,
	&.b-purple-200 { @include btn-outline($purple-200, $dark-black); }

	&.purple-300,
	&.b-purple-300 { @include btn-outline($purple-300, $dark-white); }

	&.purple-400,
	&.b-purple-400 { @include btn-outline($purple-400, $dark-white); }

	&.purple-500,
	&.b-purple-500 { @include btn-outline($purple-500, $dark-white); }

	&.purple-600,
	&.b-purple-600 { @include btn-outline($purple-600, $dark-white); }

	&.purple-700,
	&.b-purple-700 { @include btn-outline($purple-700, $dark-white); }

	&.purple-800,
	&.b-purple-800 { @include btn-outline($purple-800, $dark-white); }

	&.purple-900,
	&.b-purple-900 { @include btn-outline($purple-900, $dark-white); }

	&.purple-A100,
	&.b-purple-A100 { @include btn-outline($purple-A100, $dark-black); }

	&.purple-A200,
	&.b-purple-A200 { @include btn-outline($purple-A200, $dark-white); }

	&.purple-A400,
	&.b-purple-A400 { @include btn-outline($purple-A400, $dark-white); }

	&.purple-A700,
	&.b-purple-A700 { @include btn-outline($purple-A700, $dark-white); }


	&.deep-purple,
	&.b-deep-purple { @include btn-outline($deep-purple-500, $dark-white); }

	&.deep-purple-50,
	&.b-deep-purple-50 { @include btn-outline($deep-purple-50, $dark-black); }

	&.deep-purple-100,
	&.b-deep-purple-100 { @include btn-outline($deep-purple-100, $dark-black); }

	&.deep-purple-200,
	&.b-deep-purple-200 { @include btn-outline($deep-purple-200, $dark-black); }

	&.deep-purple-300,
	&.b-deep-purple-300 { @include btn-outline($deep-purple-300, $dark-white); }

	&.deep-purple-400,
	&.b-deep-purple-400 { @include btn-outline($deep-purple-400, $dark-white); }

	&.deep-purple-500,
	&.b-deep-purple-500 { @include btn-outline($deep-purple-500, $dark-white); }

	&.deep-purple-600,
	&.b-deep-purple-600 { @include btn-outline($deep-purple-600, $dark-white); }

	&.deep-purple-700,
	&.b-deep-purple-700 { @include btn-outline($deep-purple-700, $dark-white); }

	&.deep-purple-800,
	&.b-deep-purple-800 { @include btn-outline($deep-purple-800, $dark-white); }

	&.deep-purple-900,
	&.b-deep-purple-900 { @include btn-outline($deep-purple-900, $dark-white); }

	&.deep-purple-A100,
	&.b-deep-purple-A100 { @include btn-outline($deep-purple-A100, $dark-black); }

	&.deep-purple-A200,
	&.b-deep-purple-A200 { @include btn-outline($deep-purple-A200, $dark-white); }

	&.deep-purple-A400,
	&.b-deep-purple-A400 { @include btn-outline($deep-purple-A400, $dark-white); }

	&.deep-purple-A700,
	&.b-deep-purple-A700 { @include btn-outline($deep-purple-A700, $dark-white); }


	&.indigo,
	&.b-indigo { @include btn-outline($indigo-500, $dark-white); }

	&.indigo-50,
	&.b-indigo-50 { @include btn-outline($indigo-50, $dark-black); }

	&.indigo-100,
	&.b-indigo-100 { @include btn-outline($indigo-100, $dark-black); }

	&.indigo-200,
	&.b-indigo-200 { @include btn-outline($indigo-200, $dark-black); }

	&.indigo-300,
	&.b-indigo-300 { @include btn-outline($indigo-300, $dark-white); }

	&.indigo-400,
	&.b-indigo-400 { @include btn-outline($indigo-400, $dark-white); }

	&.indigo-500,
	&.b-indigo-500 { @include btn-outline($indigo-500, $dark-white); }

	&.indigo-600,
	&.b-indigo-600 { @include btn-outline($indigo-600, $dark-white); }

	&.indigo-700,
	&.b-indigo-700 { @include btn-outline($indigo-700, $dark-white); }

	&.indigo-800,
	&.b-indigo-800 { @include btn-outline($indigo-800, $dark-white); }

	&.indigo-900,
	&.b-indigo-900 { @include btn-outline($indigo-900, $dark-white); }

	&.indigo-A100,
	&.b-indigo-A100 { @include btn-outline($indigo-A100, $dark-black); }

	&.indigo-A200,
	&.b-indigo-A200 { @include btn-outline($indigo-A200, $dark-white); }

	&.indigo-A400,
	&.b-indigo-A400 { @include btn-outline($indigo-A400, $dark-white); }

	&.indigo-A700,
	&.b-indigo-A700 { @include btn-outline($indigo-A700, $dark-white); }


	&.blue,
	&.b-blue { @include btn-outline($blue-500, $dark-white); }

	&.blue-50,
	&.b-blue-50 { @include btn-outline($blue-50, $dark-black); }

	&.blue-100,
	&.b-blue-100 { @include btn-outline($blue-100, $dark-black); }

	&.blue-200,
	&.b-blue-200 { @include btn-outline($blue-200, $dark-black); }

	&.blue-300,
	&.b-blue-300 { @include btn-outline($blue-300, $dark-black); }

	&.blue-400,
	&.b-blue-400 { @include btn-outline($blue-400, $dark-black); }

	&.blue-500,
	&.b-blue-500 { @include btn-outline($blue-500, $dark-white); }

	&.blue-600,
	&.b-blue-600 { @include btn-outline($blue-600, $dark-white); }

	&.blue-700,
	&.b-blue-700 { @include btn-outline($blue-700, $dark-white); }

	&.blue-800,
	&.b-blue-800 { @include btn-outline($blue-800, $dark-white); }

	&.blue-900,
	&.b-blue-900 { @include btn-outline($blue-900, $dark-white); }

	&.blue-A100,
	&.b-blue-A100 { @include btn-outline($blue-A100, $dark-black); }

	&.blue-A200,
	&.b-blue-A200 { @include btn-outline($blue-A200, $dark-white); }

	&.blue-A400,
	&.b-blue-A400 { @include btn-outline($blue-A400, $dark-white); }

	&.blue-A700,
	&.b-blue-A700 { @include btn-outline($blue-A700, $dark-white); }


	&.light-blue,
	&.b-light-blue { @include btn-outline($light-blue-500, $dark-white); }

	&.light-blue-50,
	&.b-light-blue-50 { @include btn-outline($light-blue-50, $dark-black); }

	&.light-blue-100,
	&.b-light-blue-100 { @include btn-outline($light-blue-100, $dark-black); }

	&.light-blue-200,
	&.b-light-blue-200 { @include btn-outline($light-blue-200, $dark-black); }

	&.light-blue-300,
	&.b-light-blue-300 { @include btn-outline($light-blue-300, $dark-black); }

	&.light-blue-400,
	&.b-light-blue-400 { @include btn-outline($light-blue-400, $dark-black); }

	&.light-blue-500,
	&.b-light-blue-500 { @include btn-outline($light-blue-500, $dark-white); }

	&.light-blue-600,
	&.b-light-blue-600 { @include btn-outline($light-blue-600, $dark-white); }

	&.light-blue-700,
	&.b-light-blue-700 { @include btn-outline($light-blue-700, $dark-white); }

	&.light-blue-800,
	&.b-light-blue-800 { @include btn-outline($light-blue-800, $dark-white); }

	&.light-blue-900,
	&.b-light-blue-900 { @include btn-outline($light-blue-900, $dark-white); }

	&.light-blue-A100,
	&.b-light-blue-A100 { @include btn-outline($light-blue-A100, $dark-black); }

	&.light-blue-A200,
	&.b-light-blue-A200 { @include btn-outline($light-blue-A200, $dark-black); }

	&.light-blue-A400,
	&.b-light-blue-A400 { @include btn-outline($light-blue-A400, $dark-black); }

	&.light-blue-A700,
	&.b-light-blue-A700 { @include btn-outline($light-blue-A700, $dark-white); }


	&.cyan,
	&.b-cyan { @include btn-outline($cyan-500, $dark-white); }

	&.cyan-50,
	&.b-cyan-50 { @include btn-outline($cyan-50, $dark-black); }

	&.cyan-100,
	&.b-cyan-100 { @include btn-outline($cyan-100, $dark-black); }

	&.cyan-200,
	&.b-cyan-200 { @include btn-outline($cyan-200, $dark-black); }

	&.cyan-300,
	&.b-cyan-300 { @include btn-outline($cyan-300, $dark-black); }

	&.cyan-400,
	&.b-cyan-400 { @include btn-outline($cyan-400, $dark-black); }

	&.cyan-500,
	&.b-cyan-500 { @include btn-outline($cyan-500, $dark-white); }

	&.cyan-600,
	&.b-cyan-600 { @include btn-outline($cyan-600, $dark-white); }

	&.cyan-700,
	&.b-cyan-700 { @include btn-outline($cyan-700, $dark-white); }

	&.cyan-800,
	&.b-cyan-800 { @include btn-outline($cyan-800, $dark-white); }

	&.cyan-900,
	&.b-cyan-900 { @include btn-outline($cyan-900, $dark-white); }

	&.cyan-A100,
	&.b-cyan-A100 { @include btn-outline($cyan-A100, $dark-black); }

	&.cyan-A200,
	&.b-cyan-A200 { @include btn-outline($cyan-A200, $dark-black); }

	&.cyan-A400,
	&.b-cyan-A400 { @include btn-outline($cyan-A400, $dark-black); }

	&.cyan-A700,
	&.b-cyan-A700 { @include btn-outline($cyan-A700, $dark-black); }


	&.teal,
	&.b-teal { @include btn-outline($teal-500, $dark-white); }

	&.teal-50,
	&.b-teal-50 { @include btn-outline($teal-50, $dark-black); }

	&.teal-100,
	&.b-teal-100 { @include btn-outline($teal-100, $dark-black); }

	&.teal-200,
	&.b-teal-200 { @include btn-outline($teal-200, $dark-black); }

	&.teal-300,
	&.b-teal-300 { @include btn-outline($teal-300, $dark-black); }

	&.teal-400,
	&.b-teal-400 { @include btn-outline($teal-400, $dark-black); }

	&.teal-500,
	&.b-teal-500 { @include btn-outline($teal-500, $dark-white); }

	&.teal-600,
	&.b-teal-600 { @include btn-outline($teal-600, $dark-white); }

	&.teal-700,
	&.b-teal-700 { @include btn-outline($teal-700, $dark-white); }

	&.teal-800,
	&.b-teal-800 { @include btn-outline($teal-800, $dark-white); }

	&.teal-900,
	&.b-teal-900 { @include btn-outline($teal-900, $dark-white); }

	&.teal-A100,
	&.b-teal-A100 { @include btn-outline($teal-A100, $dark-black); }

	&.teal-A200,
	&.b-teal-A200 { @include btn-outline($teal-A200, $dark-black); }

	&.teal-A400,
	&.b-teal-A400 { @include btn-outline($teal-A400, $dark-black); }

	&.teal-A700,
	&.b-teal-A700 { @include btn-outline($teal-A700, $dark-black); }


	&.green,
	&.b-green { @include btn-outline($green-500, $dark-white); }

	&.green-50,
	&.b-green-50 { @include btn-outline($green-50, $dark-black); }

	&.green-100,
	&.b-green-100 { @include btn-outline($green-100, $dark-black); }

	&.green-200,
	&.b-green-200 { @include btn-outline($green-200, $dark-black); }

	&.green-300,
	&.b-green-300 { @include btn-outline($green-300, $dark-black); }

	&.green-400,
	&.b-green-400 { @include btn-outline($green-400, $dark-black); }

	&.green-500,
	&.b-green-500 { @include btn-outline($green-500, $dark-white); }

	&.green-600,
	&.b-green-600 { @include btn-outline($green-600, $dark-white); }

	&.green-700,
	&.b-green-700 { @include btn-outline($green-700, $dark-white); }

	&.green-800,
	&.b-green-800 { @include btn-outline($green-800, $dark-white); }

	&.green-900,
	&.b-green-900 { @include btn-outline($green-900, $dark-white); }

	&.green-A100,
	&.b-green-A100 { @include btn-outline($green-A100, $dark-black); }

	&.green-A200,
	&.b-green-A200 { @include btn-outline($green-A200, $dark-black); }

	&.green-A400,
	&.b-green-A400 { @include btn-outline($green-A400, $dark-black); }

	&.green-A700,
	&.b-green-A700 { @include btn-outline($green-A700, $dark-black); }


	&.light-green,
	&.b-light-green { @include btn-outline($light-green-500, $dark-black); }

	&.light-green-50,
	&.b-light-green-50 { @include btn-outline($light-green-50, $dark-black); }

	&.light-green-100,
	&.b-light-green-100 { @include btn-outline($light-green-100, $dark-black); }

	&.light-green-200,
	&.b-light-green-200 { @include btn-outline($light-green-200, $dark-black); }

	&.light-green-300,
	&.b-light-green-300 { @include btn-outline($light-green-300, $dark-black); }

	&.light-green-400,
	&.b-light-green-400 { @include btn-outline($light-green-400, $dark-black); }

	&.light-green-500,
	&.b-light-green-500 { @include btn-outline($light-green-500, $dark-black); }

	&.light-green-600,
	&.b-light-green-600 { @include btn-outline($light-green-600, $dark-black); }

	&.light-green-700,
	&.b-light-green-700 { @include btn-outline($light-green-700, $dark-black); }

	&.light-green-800,
	&.b-light-green-800 { @include btn-outline($light-green-800, $dark-white); }

	&.light-green-900,
	&.b-light-green-900 { @include btn-outline($light-green-900, $dark-white); }

	&.light-green-A100,
	&.b-light-green-A100 { @include btn-outline($light-green-A100, $dark-black); }

	&.light-green-A200,
	&.b-light-green-A200 { @include btn-outline($light-green-A200, $dark-black); }

	&.light-green-A400,
	&.b-light-green-A400 { @include btn-outline($light-green-A400, $dark-black); }

	&.light-green-A700,
	&.b-light-green-A700 { @include btn-outline($light-green-A700, $dark-black); }


	&.lime,
	&.b-lime { @include btn-outline($lime-500, $dark-black); }

	&.lime-50,
	&.b-lime-50 { @include btn-outline($lime-50, $dark-black); }

	&.lime-100,
	&.b-lime-100 { @include btn-outline($lime-100, $dark-black); }

	&.lime-200,
	&.b-lime-200 { @include btn-outline($lime-200, $dark-black); }

	&.lime-300,
	&.b-lime-300 { @include btn-outline($lime-300, $dark-black); }

	&.lime-400,
	&.b-lime-400 { @include btn-outline($lime-400, $dark-black); }

	&.lime-500,
	&.b-lime-500 { @include btn-outline($lime-500, $dark-black); }

	&.lime-600,
	&.b-lime-600 { @include btn-outline($lime-600, $dark-black); }

	&.lime-700,
	&.b-lime-700 { @include btn-outline($lime-700, $dark-black); }

	&.lime-800,
	&.b-lime-800 { @include btn-outline($lime-800, $dark-black); }

	&.lime-900,
	&.b-lime-900 { @include btn-outline($lime-900, $dark-white); }

	&.lime-A100,
	&.b-lime-A100 { @include btn-outline($lime-A100, $dark-black); }

	&.lime-A200,
	&.b-lime-A200 { @include btn-outline($lime-A200, $dark-black); }

	&.lime-A400,
	&.b-lime-A400 { @include btn-outline($lime-A400, $dark-black); }

	&.lime-A700,
	&.b-lime-A700 { @include btn-outline($lime-A700, $dark-black); }


	&.yellow,
	&.b-yellow { @include btn-outline($yellow-500, $dark-black); }

	&.yellow-50,
	&.b-yellow-50 { @include btn-outline($yellow-50, $dark-black); }

	&.yellow-100,
	&.b-yellow-100 { @include btn-outline($yellow-100, $dark-black); }

	&.yellow-200,
	&.b-yellow-200 { @include btn-outline($yellow-200, $dark-black); }

	&.yellow-300,
	&.b-yellow-300 { @include btn-outline($yellow-300, $dark-black); }

	&.yellow-400,
	&.b-yellow-400 { @include btn-outline($yellow-400, $dark-black); }

	&.yellow-500,
	&.b-yellow-500 { @include btn-outline($yellow-500, $dark-black); }

	&.yellow-600,
	&.b-yellow-600 { @include btn-outline($yellow-600, $dark-black); }

	&.yellow-700,
	&.b-yellow-700 { @include btn-outline($yellow-700, $dark-black); }

	&.yellow-800,
	&.b-yellow-800 { @include btn-outline($yellow-800, $dark-black); }

	&.yellow-900,
	&.b-yellow-900 { @include btn-outline($yellow-900, $dark-black); }

	&.yellow-A100,
	&.b-yellow-A100 { @include btn-outline($yellow-A100, $dark-black); }

	&.yellow-A200,
	&.b-yellow-A200 { @include btn-outline($yellow-A200, $dark-black); }

	&.yellow-A400,
	&.b-yellow-A400 { @include btn-outline($yellow-A400, $dark-black); }

	&.yellow-A700,
	&.b-yellow-A700 { @include btn-outline($yellow-A700, $dark-black); }


	&.amber,
	&.b-amber { @include btn-outline($amber-500, $dark-black); }

	&.amber-50,
	&.b-amber-50 { @include btn-outline($amber-50, $dark-black); }

	&.amber-100,
	&.b-amber-100 { @include btn-outline($amber-100, $dark-black); }

	&.amber-200,
	&.b-amber-200 { @include btn-outline($amber-200, $dark-black); }

	&.amber-300,
	&.b-amber-300 { @include btn-outline($amber-300, $dark-black); }

	&.amber-400,
	&.b-amber-400 { @include btn-outline($amber-400, $dark-black); }

	&.amber-500,
	&.b-amber-500 { @include btn-outline($amber-500, $dark-black); }

	&.amber-600,
	&.b-amber-600 { @include btn-outline($amber-600, $dark-black); }

	&.amber-700,
	&.b-amber-700 { @include btn-outline($amber-700, $dark-black); }

	&.amber-800,
	&.b-amber-800 { @include btn-outline($amber-800, $dark-black); }

	&.amber-900,
	&.b-amber-900 { @include btn-outline($amber-900, $dark-black); }

	&.amber-A100,
	&.b-amber-A100 { @include btn-outline($amber-A100, $dark-black); }

	&.amber-A200,
	&.b-amber-A200 { @include btn-outline($amber-A200, $dark-black); }

	&.amber-A400,
	&.b-amber-A400 { @include btn-outline($amber-A400, $dark-black); }

	&.amber-A700,
	&.b-amber-A700 { @include btn-outline($amber-A700, $dark-black); }


	&.orange,
	&.b-orange { @include btn-outline($orange-500, $dark-black); }

	&.orange-50,
	&.b-orange-50 { @include btn-outline($orange-50, $dark-black); }

	&.orange-100,
	&.b-orange-100 { @include btn-outline($orange-100, $dark-black); }

	&.orange-200,
	&.b-orange-200 { @include btn-outline($orange-200, $dark-black); }

	&.orange-300,
	&.b-orange-300 { @include btn-outline($orange-300, $dark-black); }

	&.orange-400,
	&.b-orange-400 { @include btn-outline($orange-400, $dark-black); }

	&.orange-500,
	&.b-orange-500 { @include btn-outline($orange-500, $dark-black); }

	&.orange-600,
	&.b-orange-600 { @include btn-outline($orange-600, $dark-black); }

	&.orange-700,
	&.b-orange-700 { @include btn-outline($orange-700, $dark-black); }

	&.orange-800,
	&.b-orange-800 { @include btn-outline($orange-800, $dark-white); }

	&.orange-900,
	&.b-orange-900 { @include btn-outline($orange-900, $dark-white); }

	&.orange-A100,
	&.b-orange-A100 { @include btn-outline($orange-A100, $dark-black); }

	&.orange-A200,
	&.b-orange-A200 { @include btn-outline($orange-A200, $dark-black); }

	&.orange-A400,
	&.b-orange-A400 { @include btn-outline($orange-A400, $dark-black); }

	&.orange-A700,
	&.b-orange-A700 { @include btn-outline($orange-A700, $dark-black); }


	&.deep-orange,
	&.b-deep-orange { @include btn-outline($deep-orange-500, $dark-white); }

	&.deep-orange-50,
	&.b-deep-orange-50 { @include btn-outline($deep-orange-50, $dark-black); }

	&.deep-orange-100,
	&.b-deep-orange-100 { @include btn-outline($deep-orange-100, $dark-black); }

	&.deep-orange-200,
	&.b-deep-orange-200 { @include btn-outline($deep-orange-200, $dark-black); }

	&.deep-orange-300,
	&.b-deep-orange-300 { @include btn-outline($deep-orange-300, $dark-black); }

	&.deep-orange-400,
	&.b-deep-orange-400 { @include btn-outline($deep-orange-400, $dark-black); }

	&.deep-orange-500,
	&.b-deep-orange-500 { @include btn-outline($deep-orange-500, $dark-white); }

	&.deep-orange-600,
	&.b-deep-orange-600 { @include btn-outline($deep-orange-600, $dark-white); }

	&.deep-orange-700,
	&.b-deep-orange-700 { @include btn-outline($deep-orange-700, $dark-white); }

	&.deep-orange-800,
	&.b-deep-orange-800 { @include btn-outline($deep-orange-800, $dark-white); }

	&.deep-orange-900,
	&.b-deep-orange-900 { @include btn-outline($deep-orange-900, $dark-white); }

	&.deep-orange-A100,
	&.b-deep-orange-A100 { @include btn-outline($deep-orange-A100, $dark-black); }

	&.deep-orange-A200,
	&.b-deep-orange-A200 { @include btn-outline($deep-orange-A200, $dark-black); }

	&.deep-orange-A400,
	&.b-deep-orange-A400 { @include btn-outline($deep-orange-A400, $dark-white); }

	&.deep-orange-A700,
	&.b-deep-orange-A700 { @include btn-outline($deep-orange-A700, $dark-white); }


	&.brown,
	&.b-brown { @include btn-outline($brown-500, $dark-white); }

	&.brown-50,
	&.b-brown-50 { @include btn-outline($brown-50, $dark-black); }

	&.brown-100,
	&.b-brown-100 { @include btn-outline($brown-100, $dark-black); }

	&.brown-200,
	&.b-brown-200 { @include btn-outline($brown-200, $dark-black); }

	&.brown-300,
	&.b-brown-300 { @include btn-outline($brown-300, $dark-white); }

	&.brown-400,
	&.b-brown-400 { @include btn-outline($brown-400, $dark-white); }

	&.brown-500,
	&.b-brown-500 { @include btn-outline($brown-500, $dark-white); }

	&.brown-600,
	&.b-brown-600 { @include btn-outline($brown-600, $dark-white); }

	&.brown-700,
	&.b-brown-700 { @include btn-outline($brown-700, $dark-white); }

	&.brown-800,
	&.b-brown-800 { @include btn-outline($brown-800, $dark-white); }

	&.brown-900,
	&.b-brown-900 { @include btn-outline($brown-900, $dark-white); }


	&.blue-grey,
	&.b-blue-grey { @include btn-outline($blue-grey-500, $dark-white); }

	&.blue-grey-50,
	&.b-blue-grey-50 { @include btn-outline($blue-grey-50, $dark-black); }

	&.blue-grey-100,
	&.b-blue-grey-100 { @include btn-outline($blue-grey-100, $dark-black); }

	&.blue-grey-200,
	&.b-blue-grey-200 { @include btn-outline($blue-grey-200, $dark-black); }

	&.blue-grey-300,
	&.b-blue-grey-300 { @include btn-outline($blue-grey-300, $dark-black); }

	&.blue-grey-400,
	&.b-blue-grey-400 { @include btn-outline($blue-grey-400, $dark-white); }

	&.blue-grey-500,
	&.b-blue-grey-500 { @include btn-outline($blue-grey-500, $dark-white); }

	&.blue-grey-600,
	&.b-blue-grey-600 { @include btn-outline($blue-grey-600, $dark-white); }

	&.blue-grey-700,
	&.b-blue-grey-700 { @include btn-outline($blue-grey-700, $dark-white); }

	&.blue-grey-800,
	&.b-blue-grey-800 { @include btn-outline($blue-grey-800, $dark-white); }

	&.blue-grey-900,
	&.b-blue-grey-900 { @include btn-outline($blue-grey-900, $dark-white); }


	&.grey-50,
	&.b-grey-50 { @include btn-outline($grey-50, $dark-black); }

	&.grey-100,
	&.b-grey-100 { @include btn-outline($grey-100, $dark-black); }

	&.grey-200,
	&.b-grey-200 { @include btn-outline($grey-200, $dark-black); }

	&.grey-300,
	&.b-grey-300 { @include btn-outline($grey-300, $dark-black); }

	&.grey-400,
	&.b-grey-400 { @include btn-outline($grey-400, $dark-black); }

	&.grey-500,
	&.b-grey-500 { @include btn-outline($grey-500, $dark-black); }

	&.grey-600,
	&.b-grey-600 { @include btn-outline($grey-600, $dark-white); }

	&.grey-700,
	&.b-grey-700 { @include btn-outline($grey-700, $dark-white); }

	&.grey-800,
	&.b-grey-800 { @include btn-outline($grey-800, $dark-white); }

	&.grey-900,
	&.b-grey-900 { @include btn-outline($grey-900, $dark-white); }

}

.btn-icon{
	text-align: center;
	padding: 0 !important;
	width: 2.375rem;
	height: 2.375rem;
	line-height: 2.375rem;
	position: relative;
	overflow: hidden;
	i{
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		display:block;
		line-height: inherit;
		border-radius: inherit;
		@include transition(all 0.3s);
	}
	&.btn-xs{
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1.5rem;
	}
	&.btn-sm{
		width: 1.775rem;
		height: 1.775rem;
		line-height: 1.775rem;
	}
	&.btn-lg{
		width: 3.167rem;
		height: 3.167rem;
		line-height: 3.167rem;
	}
	&.btn-social{
		i:last-child{
			top: 100%;
		}
	}
	&.btn-social:hover,
	&.btn-social:focus,
	&.btn-social.active{
		i:first-child{
			top: -100%;
		}
		i:last-child{
			top: 0;
			color: #fff;
		}
	}
	&.btn-social-colored{
		i:first-child{
			color: #fff;
		}
	}
}

.btn.white,
.btn.btn-default,
.btn.btn-secondary{
	border-color: $border-color;
}

.btn-default{
	background-color: #fff;
	color: $dark-black;
}

.btn-groups .btn{
  margin-bottom: .25rem;
}

.btn-fw{
	min-width: 7rem;
}
