#translations {
  overflow: hidden;
}

/* .transpopup {
  overflow: auto;
  max-width: 95%;
  max-height: 95%;
  display: flex;
  padding-#{$direction_2}: 20px;
  flex-direction: column;
  background-color: #f7f7f7;
  border: 1px solid #c2e1f5 !important;
} */

.popover {
    max-width: none !important;
}

.editable-click {
  text-decoration: none;
  border-bottom: dashed 1px #0088cc;
}

a.editable-click {
  text-decoration: none;
  border-bottom: dashed 1px #0088cc;
  &:hover {
    text-decoration: none;
    border-bottom: dashed 1px #0088cc;
  }
}

.translation-manager {
  .editable-click {
    text-decoration: none;
    border-bottom: none;
  }
  a.editable-click {
    text-decoration: none;
    border-bottom: none;
    &:hover {
      text-decoration: none;
      border-bottom: none;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  .editable-click {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #0088cc;
  }
  a.editable-click {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #0088cc;
    &:hover {
      text-decoration: none;
      border-bottom: none;
      border-bottom: dashed 1px #0088cc;
    }
  }
}

.translation-manager h1 .editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
}

h1 a.editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
  &:hover {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #FF00cc !important;
  }
}

.translation-manager h2 .editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
}

h2 a.editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
  &:hover {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #FF00cc !important;
  }
}

.translation-manager h3 .editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
}

h3 a.editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
  &:hover {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #FF00cc !important;
  }
}

.translation-manager h4 .editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
}

h4 a.editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
  &:hover {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #FF00cc !important;
  }
}

.translation-manager h5 .editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
}

h5 a.editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
  &:hover {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #FF00cc !important;
  }
}

.translation-manager h6 .editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
}

h6 a.editable-click {
  text-decoration: none;
  border-bottom: none;
  border-bottom: dashed 1px #FF00cc !important;
  &:hover {
    text-decoration: none;
    border-bottom: none;
    border-bottom: dashed 1px #FF00cc !important;
  }
}
/* 
.table.translation-stats > {
  thead > tr > th {
    margin: 0 0 0 !important;
    padding: 2px 6px 2px 6px;
  }
  tbody > tr > td {
    margin: 0 0 0 !important;
    padding: 2px 6px 2px 6px;
    &.group {
      text-align: #{$direction_1};
      font-weight: bold;
      padding-#{$direction_1}: 8px;
    }
  }
}

.table-translations td.unused-key {
  color: #999999;
}

.table.translation-stats > {
  thead > tr > th.deleted {
    color: #c200c1;
    font-weight: bold;
  }
  tbody > tr > td {
    &.deleted, &.group.deleted > a {
      color: #c200c1;
      font-weight: bold;
    }
  }
  thead > tr > th.missing, tbody > tr > td.group.missing > a {
    color: #d00030;
  }
  thead > tr > th.changed, tbody > tr > td.group.changed > a {
    color: #00A030;
  }
  thead > tr > th.cached, tbody > tr > td.group.cached > a {
    color: #3399f3;
  }
} */

/* textarea.editable-input {
  width: 600px !important;
  display: block !important;
  white-space: pre-wrap;
} */
/* 
.editable-container {
  &.editable-inline {
    display: block !important;
    width: 400px !important;
  }
  .editable-error-block {
    display: block !important;
    max-width: 600px !important;
  }
}
 */
.editable-buttons .editable-translate {
  margin-#{$direction_1}: 1px;
}

/* .table-translations thead tr {
  background-color: #f0f0f0;
}

.table-striped > tbody > tr {
  &:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
} */

.table-striped tr.editing,
.table-translations tr.editing {
  background-color: rgba(255, 255, 100, 0.3);
}

/* .table-striped > tbody > tr.editing {
  &:nth-of-type(even) {
    background-color: #f7f9da;
  }
  &:nth-of-type(odd) {
    background-color: #fffdd9;
  }
} */

/*.table-translations tr.deleted-translation {*/
/*text-decoration: line-through;*/
/*}*/

.table-translations > tbody > tr {
  &.deleted-translation:not(.editing) {
    &:nth-of-type(even) {
      background-color: #edd3ed;
    }
    &:nth-of-type(odd) {
      background-color: #ffe0ff;
    }
    .delete-key {
      display: none;
    }
  }

  &:not(.deleted-translation):not(.editing) {
    .undelete-key {
      display: none;
    }
  }
}

a.status-1 {
  font-weight: bold;
}

del {
  color: #C80010;
}

ins {
  color: #108030;
}

#accordion.panel-group .panel > .panel-heading a {
  &:not(.collapsed):not(.btn):before {
    content: "▼ ";
    display: inline;
  }
  &.collapsed:not(.in):before {
    content: "▶ ";
    display: inline;
  }
}

.panel-heading > {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
}

.key-filter {
  float: #{$direction_2};
  font-weight: normal;
  &.have-filtered {
    font-weight: bold;
    color: #be0031;
  }
}

.panel .table:last-child {
  margin-bottom: 0;
}

.regex-error {
  color: #c70155;
}

#accordion.panel-group {
  margin-bottom: 2px;
}

label.regex-error {
  color: #c70155;
  margin-top: -30px;
  /*height: 30px;*/
}

input.bg-highlight {
  background-color: #d9edf7;
}

.has-highlight {
  .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline, &.radio label, &.checkbox label, &.radio-inline label, &.checkbox-inline label {
    color: #337ab7;
  }
  .form-control {
    border-color: #337ab7;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &:focus {
      border-color: #286090;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #337ab7;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #337ab7;
    }
  }
  .input-group-addon {
    color: #337ab7;
    background-color: #d9edf7;
    border-color: #337ab7;
  }
  .form-control-feedback {
    color: #337ab7;
  }
}

.translation-filter {
  &.has-feedback label, &.has-highlight label, &.has-error label, &.has-success label, &.has-warning label {
    font-weight: bold;
  }
}