/*
Dropdown menu 
*/
.dropdown-menu{
  font-size: 0.875rem;
  color: $dark-black;
  border-radius: $border-radius-base;
  border: 1px solid rgba(0, 0, 0, 0.1);
  @include transform-origin(top #{$direction_1});

  > li:not(.dropdown-item) > a{
    display: block;
    padding: 3px 20px;
    &:hover,
    &:focus{
      background-color: $min-black;
    }
  }
  > .divider{
    height: 1px;
    margin: .5rem 0;
    overflow: hidden;
    background-color: $border-color;
  }

  .active{
    background-color: $min-black;
  }
  &.pull-#{$direction_1}{
    #{$direction_1}: auto !important;
    #{$direction_2}: 100%;
  }
  &.pull-#{$direction_2}{
    #{$direction_1}: auto;
    #{$direction_2}: 0;
    @include transform-origin(top #{$direction_2});
  }
  &.pull-up{
    top: 0;
  }
  &.pull-down{
    top: 100% !important;
    #{$direction_1}: 0 !important;
  }
  .dropup & {
    &{
      @include transform-origin(bottom #{$direction_1});
      &.pull-#{$direction_2}{
        @include transform-origin(bottom #{$direction_2});
      }
    }
  }

  &.datepicker,
  &.timepicker{
    padding: 6px;
    font-size: 0.8rem;
    .btn{
      font-size: 0.8rem;
      &.btn-primary{
        background-color: $info;
        color: #fff;
      }
    }
    .btn:not(:hover){
      box-shadow: 0 0 1px rgba(0,0,0,0);
    }
  }
}

.dropdown-item{
  i{
    width: 1.2rem;
    margin-#{$direction_1}: -0.5rem;
    margin-#{$direction_2}: .5rem;
    text-align: center;
    opacity: 0.7;
  }
}

.dropdown-item,
.dropdown-item.active{
  color: inherit;
  &:hover,
  &:focus{
    color: inherit;
    background-color: $min-black;
  }
}

.dropdown-divider{
  background-color: $border-color;
}

.dropdown-menu-scale{
  display: block;
  opacity: 0;
  @include scale(0.8);
  @include transition(all 250ms cubic-bezier(0.24,0.22,0.015,1.56));
  @include backface-visibility(hidden);
  pointer-events: none;
}

.active > .dropdown-menu-scale{
  opacity: 1;
  @include scale(1);
  pointer-events: auto;
  display: block !important;
}

.dropdown-header{
  padding: 8px 16px;
}

.dropdown-submenu{
  position: relative;
  &:hover,
  &:focus{
    > .dropdown-menu {
      display: block;
    }
  }
  .dropdown-menu{
    #{$direction_1}: 100%;
    top: 0;
    margin-top: -6px;
    margin-#{$direction_1}: -1px;
  }
  .dropup & {
    > .dropdown-menu{
      top: auto;
      bottom: 0;
      margin-bottom: -6px;
    }
  }
}

.popover{
  color: $dark-black;
}

@include media-breakpoint-down(lg) {
  .dropdown-menu{
    &.pull-none-lg{
      #{$direction_1}: 0;
    }
    &.pull-#{$direction_2}-lg{
      #{$direction_1}: auto;
      #{$direction_2}: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .dropdown-menu{
    &.pull-none-md{
      #{$direction_1}: 0;
    }
    &.pull-#{$direction_2}-md{
      #{$direction_1}: auto;
      #{$direction_2}: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dropdown-menu{
    &.pull-none-sm{
      #{$direction_1}: 0;
    }
    &.pull-#{$direction_2}-sm{
      #{$direction_1}: auto;
      #{$direction_2}: 0;
    }
  }
}

@include media-breakpoint-down(xs) {
  .dropdown-menu{
    &.pull-none-xs{
      #{$direction_1}: 0;
    }
    &.pull-#{$direction_2}-xs{
      #{$direction_1}: auto;
      #{$direction_2}: 0;
    }
  }
}
