/*
Progress 
*/
.progress[value]::-webkit-progress-bar{
  background-color: $min-black;
}

.progress {
	border-radius: .25rem;
	overflow: hidden;
	background-color: $min-black;
}

.progress-bar {
    @include transition(all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1));
    display: inline-block;
    float: #{$direction_1};
    height: 100%;
    border-radius: .25rem;
    text-align: center;
    background-color: #0074d9;
    font-size: 0.8em;
}

.progress.splitted {
    position: relative;
    .progress-bar {
        border-radius: 0;
    }
    .split {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        height: 100%;
        background-color: $light;
    }
    .split-1 {
        #{$direction_1}: calc(25% - 1px);
    }
    .split-2 {
        #{$direction_1}: calc(50% - 1px);
    }
    .split-3 {
        #{$direction_1}: calc(75% - 1px);
    }
}

.grey .progress.splitted .split {
  background-color: $grey;
}

.dark .progress.splitted .split {
  background-color: $dark;
}

.black .progress.splitted .split {
  background-color: $black;
}

.progress-xxs{
  height: 4px;
}

.progress-xs{
  height: 8px;
}

.progress-sm{
  height: 12px;
  font-size: 0.8em;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image:      -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image:         linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
          background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
       -o-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
