
//theme variables
$accent-1: #0dd1f3 !default;
$accent-2: $blue !default;
$accent-3: #00fff6 !default;

//theme slider size
$ff_slider_height: 140vh !default;

.accent-1 {
  @include color-variant($accent-1, $dark-white);
}
.btn {
	&.b-accent-1 { @include btn-outline($accent-1, $dark-white); }
}
@include nav-text-wariant('accent-1', $accent-1);
@include border-wariant('accent-1', $accent-1);
@include text-wariant('accent-1', $accent-1);


@import "layout/_header.scss";
@import "layout/_layout.scss";
@import "layout/_footer.scss";


//parts

@import "parts/_home.scss";