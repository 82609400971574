/*
Timeline
*/
.timeline{
	margin: 0;
	padding: 0;
}
.tl-item{
	display: block;
	@include clearfix();
}
.visible-#{$direction_1}{
	display: none;
}
.tl-wrap{
	display: block;
	margin-#{$direction_1}: 6em;
	@if ($rtl == true) {
		padding: 15px 20px 15px 0;
	} @else {
		padding: 15px 0 15px 20px;
	}
	border-style: solid;
	border-color: $border-color;
	@if ($rtl == true) {
		border-width: 0 2px 0 0;
	} @else {
		border-width: 0 0 0 2px;
	}
	@include clearfix();

	&:before{
		@include transition(all .3s ease-in-out);
		position: relative;
		content: "";
		float: #{$direction_1};
		top: 15px;
		margin-#{$direction_1}: -26px;
		width: 10px;
		height: 10px;
		border-color: inherit;
		border-width: 3px;
		border-radius: 50%;
		border-style: solid;
		background: $light;
		box-shadow: 0 0 0 4px $body-bg;		
	}

	&.x-sign:before{
		position: relative;
		content: '\00d7';
		float: left;
		top: 7px;
		margin-left: -31px;
		width: 20px;
		height: 30px;
		line-height: 25px;
		font-size: 32px;
		font-family: Helvetica Arial sans-serif;
		font-weight: bold;
		text-align: center;
		border-color: inherit;
		border-width: 0;
		border-radius: 0;
		border-style: none;
		box-shadow: none;
		color: inherit;
	}
	&.x-sign:hover:before{
		background: $light !important;
		@include transform(scale(-0.5));
	}

	&.b-primary:hover:before {
	  background: $primary;
	}
	&.b-success:hover:before {
	  background: $success;
	}
	&.b-info:hover:before {
	  background: $info;
	}
	&.b-warning:hover:before {
	  background: $warning;
	}
	&.b-danger:hover:before {
	  background: $danger;
	}
	&.b-accent:hover:before {
	  background: $accent;
	}
	&.b-dark:hover:before {
	  background: $dark;
	}
	&.b-light:hover:before {
	  background: $light;
	}
	&.b-warn:hover:before {
	  background: $warn;
	}
	&.white:hover:before {
	  background: #fff;
	}
	&.b-black:hover:before {
	    background: $black;
	}
	&.b-grey:hover:before {
	    background: $grey;
	}

	&.b-red:hover:before { background: $red-500; }
	&.b-bred-50:hover:before { background: $red-50; }
	&.b-red-100:hover:before { background: $red-100; }
	&.b-red-200:hover:before { background: $red-200; }
	&.b-red-300:hover:before { background: $red-300; }
	&.b-red-400:hover:before { background: $red-400; }
	&.b-red-500:hover:before { background: $red-500; }
	&.b-red-600:hover:before { background: $red-600; }
	&.b-red-700:hover:before { background: $red-700; }
	&.b-red-800:hover:before { background: $red-800; }
	&.b-red-900:hover:before { background: $red-900; }
	&.b-red-A100:hover:before { background: $red-A100; }
	&.b-red-A200:hover:before { background: $red-A200; }
	&.b-red-A400:hover:before { background: $red-A400; }
	&.b-red-A700:hover:before { background: $red-A700; }

	&.b-pink:hover:before { background: $pink-500; }
	&.b-pink-50:hover:before { background: $pink-50; }
	&.b-pink-100:hover:before { background: $pink-100; }
	&.b-pink-200:hover:before { background: $pink-200; }
	&.b-pink-300:hover:before { background: $pink-300; }
	&.b-pink-400:hover:before { background: $pink-400; }
	&.b-pink-500:hover:before { background: $pink-500; }
	&.b-pink-600:hover:before { background: $pink-600; }
	&.b-pink-700:hover:before { background: $pink-700; }
	&.b-pink-800:hover:before { background: $pink-800; }
	&.b-pink-900:hover:before { background: $pink-900; }
	&.b-pink-A100:hover:before { background: $pink-A100; }
	&.b-pink-A200:hover:before { background: $pink-A200; }
	&.b-pink-A400:hover:before { background: $pink-A400; }
	&.b-pink-A700:hover:before { background: $pink-A700; }

	&.b-purple:hover:before { background: $purple-500; }
	&.b-purple-50:hover:before { background: $purple-50; }
	&.b-purple-100:hover:before { background: $purple-100; }
	&.b-purple-200:hover:before { background: $purple-200; }
	&.b-purple-300:hover:before { background: $purple-300; }
	&.b-purple-400:hover:before { background: $purple-400; }
	&.b-purple-500:hover:before { background: $purple-500; }
	&.b-purple-600:hover:before { background: $purple-600; }
	&.b-purple-700:hover:before { background: $purple-700; }
	&.b-purple-800:hover:before { background: $purple-800; }
	&.b-purple-900:hover:before { background: $purple-900; }
	&.b-purple-A100:hover:before { background: $purple-A100; }
	&.b-purple-A200:hover:before { background: $purple-A200; }
	&.b-purple-A400:hover:before { background: $purple-A400; }
	&.b-purple-A700:hover:before { background: $purple-A700; }

	&.b-deep-purple:hover:before { background: $deep-purple-500; }
	&.b-deep-purple-50:hover:before { background: $deep-purple-50; }
	&.b-deep-purple-100:hover:before { background: $deep-purple-100; }
	&.b-deep-purple-200:hover:before { background: $deep-purple-200; }
	&.b-deep-purple-300:hover:before { background: $deep-purple-300; }
	&.b-deep-purple-400:hover:before { background: $deep-purple-400; }
	&.b-deep-purple-500:hover:before { background: $deep-purple-500; }
	&.b-deep-purple-600:hover:before { background: $deep-purple-600; }
	&.b-deep-purple-700:hover:before { background: $deep-purple-700; }
	&.b-deep-purple-800:hover:before { background: $deep-purple-800; }
	&.b-deep-purple-900:hover:before { background: $deep-purple-900; }
	&.b-deep-purple-A100:hover:before { background: $deep-purple-A100; }
	&.b-deep-purple-A200:hover:before { background: $deep-purple-A200; }
	&.b-deep-purple-A400:hover:before { background: $deep-purple-A400; }
	&.b-deep-purple-A700:hover:before { background: $deep-purple-A700; }

	&.b-indigo:hover:before { background: $indigo-500; }
	&.b-indigo-50:hover:before { background: $indigo-50; }
	&.b-indigo-100:hover:before { background: $indigo-100; }
	&.b-indigo-200:hover:before { background: $indigo-200; }
	&.b-indigo-300:hover:before { background: $indigo-300; }
	&.b-indigo-400:hover:before { background: $indigo-400; }
	&.b-indigo-500:hover:before { background: $indigo-500; }
	&.b-indigo-600:hover:before { background: $indigo-600; }
	&.b-indigo-700:hover:before { background: $indigo-700; }
	&.b-indigo-800:hover:before { background: $indigo-800; }
	&.b-indigo-900:hover:before { background: $indigo-900; }
	&.b-indigo-A100:hover:before { background: $indigo-A100; }
	&.b-indigo-A200:hover:before { background: $indigo-A200; }
	&.b-indigo-A400:hover:before { background: $indigo-A400; }
	&.b-indigo-A700:hover:before { background: $indigo-A700; }

	&.b-blue:hover:before { background: $blue-500; }
	&.b-blue-50:hover:before { background: $blue-50; }
	&.b-blue-100:hover:before { background: $blue-100; }
	&.b-blue-200:hover:before { background: $blue-200; }
	&.b-blue-300:hover:before { background: $blue-300; }
	&.b-blue-400:hover:before { background: $blue-400; }
	&.b-blue-500:hover:before { background: $blue-500; }
	&.b-blue-600:hover:before { background: $blue-600; }
	&.b-blue-700:hover:before { background: $blue-700; }
	&.b-blue-800:hover:before { background: $blue-800; }
	&.b-blue-900:hover:before { background: $blue-900; }
	&.b-blue-A100:hover:before { background: $blue-A100; }
	&.b-blue-A200:hover:before { background: $blue-A200; }
	&.b-blue-A400:hover:before { background: $blue-A400; }
	&.b-blue-A700:hover:before { background: $blue-A700; }

	&.b-light-blue:hover:before { background: $light-blue-500; }
	&.b-light-blue-50:hover:before { background: $light-blue-50; }
	&.b-light-blue-100:hover:before { background: $light-blue-100; }
	&.b-light-blue-200:hover:before { background: $light-blue-200; }
	&.b-light-blue-300:hover:before { background: $light-blue-300; }
	&.b-light-blue-400:hover:before { background: $light-blue-400; }
	&.b-light-blue-500:hover:before { background: $light-blue-500; }
	&.b-light-blue-600:hover:before { background: $light-blue-600; }
	&.b-light-blue-700:hover:before { background: $light-blue-700; }
	&.b-light-blue-800:hover:before { background: $light-blue-800; }
	&.b-light-blue-900:hover:before { background: $light-blue-900; }
	&.b-light-blue-A100:hover:before { background: $light-blue-A100; }
	&.b-light-blue-A200:hover:before { background: $light-blue-A200; }
	&.b-light-blue-A400:hover:before { background: $light-blue-A400; }
	&.b-light-blue-A700:hover:before { background: $light-blue-A700; }

	&.b-cyan:hover:before { background: $cyan-500; }
	&.b-cyan-50:hover:before { background: $cyan-50; }
	&.b-cyan-100:hover:before { background: $cyan-100; }
	&.b-cyan-200:hover:before { background: $cyan-200; }
	&.b-cyan-300:hover:before { background: $cyan-300; }
	&.b-cyan-400:hover:before { background: $cyan-400; }
	&.b-cyan-500:hover:before { background: $cyan-500; }
	&.b-cyan-600:hover:before { background: $cyan-600; }
	&.b-cyan-700:hover:before { background: $cyan-700; }
	&.b-cyan-800:hover:before { background: $cyan-800; }
	&.b-cyan-900:hover:before { background: $cyan-900; }
	&.b-cyan-A100:hover:before { background: $cyan-A100; }
	&.b-cyan-A200:hover:before { background: $cyan-A200; }
	&.b-cyan-A400:hover:before { background: $cyan-A400; }
	&.b-cyan-A700:hover:before { background: $cyan-A700; }

	&.b-teal:hover:before { background: $teal-500; }
	&.b-teal-50:hover:before { background: $teal-50; }
	&.b-teal-100:hover:before { background: $teal-100; }
	&.b-teal-200:hover:before { background: $teal-200; }
	&.b-teal-300:hover:before { background: $teal-300; }
	&.b-teal-400:hover:before { background: $teal-400; }
	&.b-teal-500:hover:before { background: $teal-500; }
	&.b-teal-600:hover:before { background: $teal-600; }
	&.b-teal-700:hover:before { background: $teal-700; }
	&.b-teal-800:hover:before { background: $teal-800; }
	&.b-teal-900:hover:before { background: $teal-900; }
	&.b-teal-A100:hover:before { background: $teal-A100; }
	&.b-teal-A200:hover:before { background: $teal-A200; }
	&.b-teal-A400:hover:before { background: $teal-A400; }
	&.b-teal-A700:hover:before { background: $teal-A700; }

	&.b-green:hover:before { background: $green-500; }
	&.b-green-50:hover:before { background: $green-50; }
	&.b-green-100:hover:before { background: $green-100; }
	&.b-green-200:hover:before { background: $green-200; }
	&.b-green-300:hover:before { background: $green-300; }
	&.b-green-400:hover:before { background: $green-400; }
	&.b-green-500:hover:before { background: $green-500; }
	&.b-green-600:hover:before { background: $green-600; }
	&.b-green-700:hover:before { background: $green-700; }
	&.b-green-800:hover:before { background: $green-800; }
	&.b-green-900:hover:before { background: $green-900; }
	&.b-green-A100:hover:before { background: $green-A100; }
	&.b-green-A200:hover:before { background: $green-A200; }
	&.b-green-A400:hover:before { background: $green-A400; }
	&.b-green-A700:hover:before { background: $green-A700; }

	&.b-light-green:hover:before { background: $light-green-500; }
	&.b-light-green-50:hover:before { background: $light-green-50; }
	&.b-light-green-100:hover:before { background: $light-green-100; }
	&.b-light-green-200:hover:before { background: $light-green-200; }
	&.b-light-green-300:hover:before { background: $light-green-300; }
	&.b-light-green-400:hover:before { background: $light-green-400; }
	&.b-light-green-500:hover:before { background: $light-green-500; }
	&.b-light-green-600:hover:before { background: $light-green-600; }
	&.b-light-green-700:hover:before { background: $light-green-700; }
	&.b-light-green-800:hover:before { background: $light-green-800; }
	&.b-light-green-900:hover:before { background: $light-green-900; }
	&.b-light-green-A100:hover:before { background: $light-green-A100; }
	&.b-light-green-A200:hover:before { background: $light-green-A200; }
	&.b-light-green-A400:hover:before { background: $light-green-A400; }
	&.b-light-green-A700:hover:before { background: $light-green-A700; }

	&.b-lime:hover:before { background: $lime-500; }
	&.b-lime-50:hover:before { background: $lime-50; }
	&.b-lime-100:hover:before { background: $lime-100; }
	&.b-lime-200:hover:before { background: $lime-200; }
	&.b-lime-300:hover:before { background: $lime-300; }
	&.b-lime-400:hover:before { background: $lime-400; }
	&.b-lime-500:hover:before { background: $lime-500; }
	&.b-lime-600:hover:before { background: $lime-600; }
	&.b-lime-700:hover:before { background: $lime-700; }
	&.b-lime-800:hover:before { background: $lime-800; }
	&.b-lime-900:hover:before { background: $lime-900; }
	&.b-lime-A100:hover:before { background: $lime-A100; }
	&.b-lime-A200:hover:before { background: $lime-A200; }
	&.b-lime-A400:hover:before { background: $lime-A400; }
	&.b-lime-A700:hover:before { background: $lime-A700; }

	&.b-yellow:hover:before { background: $yellow-500; }
	&.b-yellow-50:hover:before { background: $yellow-50; }
	&.b-yellow-100:hover:before { background: $yellow-100; }
	&.b-yellow-200:hover:before { background: $yellow-200; }
	&.b-yellow-300:hover:before { background: $yellow-300; }
	&.b-yellow-400:hover:before { background: $yellow-400; }
	&.b-yellow-500:hover:before { background: $yellow-500; }
	&.b-yellow-600:hover:before { background: $yellow-600; }
	&.b-yellow-700:hover:before { background: $yellow-700; }
	&.b-yellow-800:hover:before { background: $yellow-800; }
	&.b-yellow-900:hover:before { background: $yellow-900; }
	&.b-yellow-A100:hover:before { background: $yellow-A100; }
	&.b-yellow-A200:hover:before { background: $yellow-A200; }
	&.b-yellow-A400:hover:before { background: $yellow-A400; }
	&.b-yellow-A700:hover:before { background: $yellow-A700; }

	&.b-amber:hover:before { background: $amber-500; }
	&.b-amber-50:hover:before { background: $amber-50; }
	&.b-amber-100:hover:before { background: $amber-100; }
	&.b-amber-200:hover:before { background: $amber-200; }
	&.b-amber-300:hover:before { background: $amber-300; }
	&.b-amber-400:hover:before { background: $amber-400; }
	&.b-amber-500:hover:before { background: $amber-500; }
	&.b-amber-600:hover:before { background: $amber-600; }
	&.b-amber-700:hover:before { background: $amber-700; }
	&.b-amber-800:hover:before { background: $amber-800; }
	&.b-amber-900:hover:before { background: $amber-900; }
	&.b-amber-A100:hover:before { background: $amber-A100; }
	&.b-amber-A200:hover:before { background: $amber-A200; }
	&.b-amber-A400:hover:before { background: $amber-A400; }
	&.b-amber-A700:hover:before { background: $amber-A700; }

	&.b-orange:hover:before { background: $orange-500; }
	&.b-orange-50:hover:before { background: $orange-50; }
	&.b-orange-100:hover:before { background: $orange-100; }
	&.b-orange-200:hover:before { background: $orange-200; }
	&.b-orange-300:hover:before { background: $orange-300; }
	&.b-orange-400:hover:before { background: $orange-400; }
	&.b-orange-500:hover:before { background: $orange-500; }
	&.b-orange-600:hover:before { background: $orange-600; }
	&.b-orange-700:hover:before { background: $orange-700; }
	&.b-orange-800:hover:before { background: $orange-800; }
	&.b-orange-900:hover:before { background: $orange-900; }
	&.b-orange-A100:hover:before { background: $orange-A100; }
	&.b-orange-A200:hover:before { background: $orange-A200; }
	&.b-orange-A400:hover:before { background: $orange-A400; }
	&.b-orange-A700:hover:before { background: $orange-A700; }

	&.b-deep-orange:hover:before { background: $deep-orange-500; }
	&.b-deep-orange-50:hover:before { background: $deep-orange-50; }
	&.b-deep-orange-100:hover:before { background: $deep-orange-100; }
	&.b-deep-orange-200:hover:before { background: $deep-orange-200; }
	&.b-deep-orange-300:hover:before { background: $deep-orange-300; }
	&.b-deep-orange-400:hover:before { background: $deep-orange-400; }
	&.b-deep-orange-500:hover:before { background: $deep-orange-500; }
	&.b-deep-orange-600:hover:before { background: $deep-orange-600; }
	&.b-deep-orange-700:hover:before { background: $deep-orange-700; }
	&.b-deep-orange-800:hover:before { background: $deep-orange-800; }
	&.b-deep-orange-900:hover:before { background: $deep-orange-900; }
	&.b-deep-orange-A100:hover:before { background: $deep-orange-A100; }
	&.b-deep-orange-A200:hover:before { background: $deep-orange-A200; }
	&.b-deep-orange-A400:hover:before { background: $deep-orange-A400; }
	&.b-deep-orange-A700:hover:before { background: $deep-orange-A700; }

	&.b-brown:hover:before { background: $brown-500; }
	&.b-brown-50:hover:before { background: $brown-50; }
	&.b-brown-100:hover:before { background: $brown-100; }
	&.b-brown-200:hover:before { background: $brown-200; }
	&.b-brown-300:hover:before { background: $brown-300; }
	&.b-brown-400:hover:before { background: $brown-400; }
	&.b-brown-500:hover:before { background: $brown-500; }
	&.b-brown-600:hover:before { background: $brown-600; }
	&.b-brown-700:hover:before { background: $brown-700; }
	&.b-brown-800:hover:before { background: $brown-800; }
	&.b-brown-900:hover:before { background: $brown-900; }

	&.b-blue-grey:hover:before { background: $blue-grey-500; }
	&.b-blue-grey-50:hover:before { background: $blue-grey-50; }
	&.b-blue-grey-100:hover:before { background: $blue-grey-100; }
	&.b-blue-grey-200:hover:before { background: $blue-grey-200; }
	&.b-blue-grey-300:hover:before { background: $blue-grey-300; }
	&.b-blue-grey-400:hover:before { background: $blue-grey-400; }
	&.b-blue-grey-500:hover:before { background: $blue-grey-500; }
	&.b-blue-grey-600:hover:before { background: $blue-grey-600; }
	&.b-blue-grey-700:hover:before { background: $blue-grey-700; }
	&.b-blue-grey-800:hover:before { background: $blue-grey-800; }
	&.b-blue-grey-900:hover:before { background: $blue-grey-900; }

	&.b-grey-50:hover:before { background: $grey-50; }
	&.b-grey-100:hover:before { background: $grey-100; }
	&.b-grey-200:hover:before { background: $grey-200; }
	&.b-grey-300:hover:before { background: $grey-300; }
	&.b-grey-400:hover:before { background: $grey-400; }
	&.b-grey-500:hover:before { background: $grey-500; }
	&.b-grey-600:hover:before { background: $grey-600; }
	&.b-grey-700:hover:before { background: $grey-700; }
	&.b-grey-800:hover:before { background: $grey-800; }
	&.b-grey-900:hover:before { background: $grey-900; }
	
}

.tl-date{
	position: relative;
	top: 10px;
	float: #{$direction_1};
	margin-#{$direction_1}: -7.5em;
	display: block;
	width: 4.5em;
	text-align: #{$direction_2};
}

.tl-icon{
	position: relative;
	float: #{$direction_1};
	margin-#{$direction_1}: -7.5em;
	display: inline-block;
	top: 10px;
	width: 4.5em;
	text-align: #{$direction_2};
}

.tl-content{
	display: inline-block;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	&.block{
		display: block;
		width: 100%;
	}
	&.panel{
		margin-bottom: 0;
	}
}

.tl-header{
	display: block;
	width: 12em;
	text-align: center;
	margin-#{$direction_1}: 1px;
}

.timeline-center{
	.tl-item{
		margin-#{$direction_1}: 50%;
		.tl-wrap{
			margin-#{$direction_1}: -2px;
		}
	}
	.tl-header{
		width: auto;
		margin-#{$direction_1}: -1px;
	}
	.tl-#{$direction_1}{
		margin-#{$direction_1}: 0;
		margin-#{$direction_2}: 50%;
		.hidden-#{$direction_1}{
			display: none !important;
		}
		.visible-#{$direction_1}{
			display: inherit;
		}		
		.tl-wrap{
			float: #{$direction_2};
			margin-#{$direction_2}: 0px;
			border-#{$direction_1}-width: 0;
			border-#{$direction_2}-width: 2px;
			padding-#{$direction_1}: 0;
			padding-#{$direction_2}: 20px;
			&:before{
				float: #{$direction_2};
				margin-#{$direction_1}: 0;
				margin-#{$direction_2}: -26px;
			}
		}
		.tl-date{
			float: #{$direction_2};
			margin-#{$direction_1}: 0;
			margin-#{$direction_2}: -8.5em;
			text-align: #{$direction_1};	
		}
	}
}
