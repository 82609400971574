
/*---------------------------
2. UI Components
---------------------------*/

@import "_ui.alert.scss";
@import "_ui.arrow.scss";
@import "_ui.avatar.scss";
@import "_ui.col.scss";
@import "_ui.box.scss";
@import "_ui.check.scss";
@import "_ui.flexbox.scss";
@import "_ui.layout.scss";
@import "_ui.layout.boxed.scss";
@import "_ui.list.scss";
@import "_ui.loading.scss";
@import "_ui.nav.scss";
@import "_ui.nav.text.scss";
@import "_ui.nav.plus.scss";
@import "_ui.nav.active.scss";
@import "_ui.icon.scss";
@import "_ui.item.scss";
@import "_ui.page.scss";
@import "_ui.plugin.scss";
@import "_ui.streamline.scss";
@import "_ui.switch.scss";
@import "_ui.timeline.scss";
@import "_ui.color.dark.scss";
@import "_ui.color.scss";

//day night
@import "_ui.day_night_switch.scss";