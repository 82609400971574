/*
Dark theme
*/
.dark{
  .white,
  .box{
    background-color: lighten( $dark, $color-dk-percent );
  }
  &.app{
    background-color: $dark;
  }
}
.black{
  .white,
  .box{
    background-color: lighten( $black, $color-dk-percent );
  }
  &.app{
    background-color: $black;
  }
}
.grey{
  .white,
  .box{
    background-color: lighten( $grey, $color-dk-percent );;
  }
  &.app{
    background-color: $grey;
  }
}


.dark,
.black,
.grey{
  .form-control{
    background: transparent;
    background-color: transparent;
    color: inherit;
    &:focus{
      border-color: $border-color;
    }
  }
  .input-group-btn{
    .btn,
    .btn-default {
      background-color: $border-color-lt !important;
      color: inherit !important;
    }
  }
  .list-group{
    background-color: transparent;
  }


  input[type=range] {

    &::-webkit-slider-runnable-track {
      background: $range-track-color-dark;
    }

    &::-moz-range-track {
      background: $range-track-color-dark;
    }

    &::-ms-fill-lower {
      background: $range-track-color-dark;
    }

    &::-ms-fill-upper {
      background: $range-track-color-dark;
    }

    // Range Label
    & ~ span {
      background: $range-label-bg-dark;
      color: $range-label-color-dark;
      &:after {
        border-#{$direction_2}: 7px solid $range-label-bg-dark;
      }
    }
  }
}

.dark{
  .b-white{
    color: lighten( $dark, $color-dk-percent );
    border-color: lighten( $dark, $color-dk-percent );
  }
}
.black{
  .b-white{
    color: lighten( $black, $color-dk-percent );
    border-color: lighten( $black, $color-dk-percent );
  }
}
.grey{
  .b-white{
    color: lighten( $grey, $color-dk-percent );
    border-color: lighten( $grey, $color-dk-percent );
  }
}

.dark{
  .btn.b-white:hover, .btn.b-white:focus {
    color: inherit !important;
    background-color: lighten( $dark, $color-dk-percent );
  }
}

.black{
  .btn.b-white:hover, .btn.b-white:focus {
    color: inherit !important;
    background-color: lighten( $black, $color-dk-percent );
  }
}

.grey{
  .btn.b-white:hover, .btn.b-white:focus {
    color: inherit !important;
    background-color: lighten( $grey, $color-dk-percent );
  }
}
