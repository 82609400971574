/*
Icon
*/
.ui-icon{
  display: inline-block;
  text-align: center;
  height: 1em;
  background: transparent !important;

  font-size: inherit;
  vertical-align: inherit;
  line-height: 1em;

  &[class*="ion-"],
  &[class*=" ion-"] {
    vertical-align: inherit;
  }

  &:before{
    position: relative;
    z-index: 1;
  }
  &:after{
    content: "";
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    border-radius: 2em;
    width: 2em;
    height: 2em;
    top: -1.5em;
    display: block;
    position: relative;
    z-index: 0;
    background-clip: padding-box;
  }
  &.b-2x:after{
    border-width: 2px;
  }
  &.b-3x:after{
    border-width: 3px;
  }
  &.ui-icon-sm:after{
    width: 1.7em;
    height: 1.7em;
    top: -1.35em;
  }
  &.primary:after{
    background-color: $primary;
  }
  &.success:after{
    background-color: $success;
  }
  &.info:after{
    background-color: $info;
  }
  &.warning:after{
    background-color: $warning;
  }
  &.danger:after{
    background-color: $danger;
  }
  &.accent:after{
    background-color: $accent;
  }
  &.dark:after{
    background-color: $dark;
  }
  &.light:after{
    background-color: $light;
  }
  &.warn:after{
    background-color: $warn;
  }
  &.white:after{
    background-color: #fff;
  }
  &.black:after{
    background-color: #000;
  }
  &.grey:after{
    background-color: $grey;
  }

  &.red:after { background-color: $red-500; }
  &.bred-50:after { background-color: $red-50; }
  &.red-100:after { background-color: $red-100; }
  &.red-200:after { background-color: $red-200; }
  &.red-300:after { background-color: $red-300; }
  &.red-400:after { background-color: $red-400; }
  &.red-500:after { background-color: $red-500; }
  &.red-600:after { background-color: $red-600; }
  &.red-700:after { background-color: $red-700; }
  &.red-800:after { background-color: $red-800; }
  &.red-900:after { background-color: $red-900; }
  &.red-A100:after { background-color: $red-A100; }
  &.red-A200:after { background-color: $red-A200; }
  &.red-A400:after { background-color: $red-A400; }
  &.red-A700:after { background-color: $red-A700; }

  &.pink:after { background-color: $pink-500; }
  &.pink-50:after { background-color: $pink-50; }
  &.pink-100:after { background-color: $pink-100; }
  &.pink-200:after { background-color: $pink-200; }
  &.pink-300:after { background-color: $pink-300; }
  &.pink-400:after { background-color: $pink-400; }
  &.pink-500:after { background-color: $pink-500; }
  &.pink-600:after { background-color: $pink-600; }
  &.pink-700:after { background-color: $pink-700; }
  &.pink-800:after { background-color: $pink-800; }
  &.pink-900:after { background-color: $pink-900; }
  &.pink-A100:after { background-color: $pink-A100; }
  &.pink-A200:after { background-color: $pink-A200; }
  &.pink-A400:after { background-color: $pink-A400; }
  &.pink-A700:after { background-color: $pink-A700; }

  &.purple:after { background-color: $purple-500; }
  &.purple-50:after { background-color: $purple-50; }
  &.purple-100:after { background-color: $purple-100; }
  &.purple-200:after { background-color: $purple-200; }
  &.purple-300:after { background-color: $purple-300; }
  &.purple-400:after { background-color: $purple-400; }
  &.purple-500:after { background-color: $purple-500; }
  &.purple-600:after { background-color: $purple-600; }
  &.purple-700:after { background-color: $purple-700; }
  &.purple-800:after { background-color: $purple-800; }
  &.purple-900:after { background-color: $purple-900; }
  &.purple-A100:after { background-color: $purple-A100; }
  &.purple-A200:after { background-color: $purple-A200; }
  &.purple-A400:after { background-color: $purple-A400; }
  &.purple-A700:after { background-color: $purple-A700; }

  &.deep-purple:after { background-color: $deep-purple-500; }
  &.deep-purple-50:after { background-color: $deep-purple-50; }
  &.deep-purple-100:after { background-color: $deep-purple-100; }
  &.deep-purple-200:after { background-color: $deep-purple-200; }
  &.deep-purple-300:after { background-color: $deep-purple-300; }
  &.deep-purple-400:after { background-color: $deep-purple-400; }
  &.deep-purple-500:after { background-color: $deep-purple-500; }
  &.deep-purple-600:after { background-color: $deep-purple-600; }
  &.deep-purple-700:after { background-color: $deep-purple-700; }
  &.deep-purple-800:after { background-color: $deep-purple-800; }
  &.deep-purple-900:after { background-color: $deep-purple-900; }
  &.deep-purple-A100:after { background-color: $deep-purple-A100; }
  &.deep-purple-A200:after { background-color: $deep-purple-A200; }
  &.deep-purple-A400:after { background-color: $deep-purple-A400; }
  &.deep-purple-A700:after { background-color: $deep-purple-A700; }

  &.indigo:after { background-color: $indigo-500; }
  &.indigo-50:after { background-color: $indigo-50; }
  &.indigo-100:after { background-color: $indigo-100; }
  &.indigo-200:after { background-color: $indigo-200; }
  &.indigo-300:after { background-color: $indigo-300; }
  &.indigo-400:after { background-color: $indigo-400; }
  &.indigo-500:after { background-color: $indigo-500; }
  &.indigo-600:after { background-color: $indigo-600; }
  &.indigo-700:after { background-color: $indigo-700; }
  &.indigo-800:after { background-color: $indigo-800; }
  &.indigo-900:after { background-color: $indigo-900; }
  &.indigo-A100:after { background-color: $indigo-A100; }
  &.indigo-A200:after { background-color: $indigo-A200; }
  &.indigo-A400:after { background-color: $indigo-A400; }
  &.indigo-A700:after { background-color: $indigo-A700; }

  &.blue:after { background-color: $blue-500; }
  &.blue-50:after { background-color: $blue-50; }
  &.blue-100:after { background-color: $blue-100; }
  &.blue-200:after { background-color: $blue-200; }
  &.blue-300:after { background-color: $blue-300; }
  &.blue-400:after { background-color: $blue-400; }
  &.blue-500:after { background-color: $blue-500; }
  &.blue-600:after { background-color: $blue-600; }
  &.blue-700:after { background-color: $blue-700; }
  &.blue-800:after { background-color: $blue-800; }
  &.blue-900:after { background-color: $blue-900; }
  &.blue-A100:after { background-color: $blue-A100; }
  &.blue-A200:after { background-color: $blue-A200; }
  &.blue-A400:after { background-color: $blue-A400; }
  &.blue-A700:after { background-color: $blue-A700; }

  &.light-blue:after { background-color: $light-blue-500; }
  &.light-blue-50:after { background-color: $light-blue-50; }
  &.light-blue-100:after { background-color: $light-blue-100; }
  &.light-blue-200:after { background-color: $light-blue-200; }
  &.light-blue-300:after { background-color: $light-blue-300; }
  &.light-blue-400:after { background-color: $light-blue-400; }
  &.light-blue-500:after { background-color: $light-blue-500; }
  &.light-blue-600:after { background-color: $light-blue-600; }
  &.light-blue-700:after { background-color: $light-blue-700; }
  &.light-blue-800:after { background-color: $light-blue-800; }
  &.light-blue-900:after { background-color: $light-blue-900; }
  &.light-blue-A100:after { background-color: $light-blue-A100; }
  &.light-blue-A200:after { background-color: $light-blue-A200; }
  &.light-blue-A400:after { background-color: $light-blue-A400; }
  &.light-blue-A700:after { background-color: $light-blue-A700; }

  &.cyan:after { background-color: $cyan-500; }
  &.cyan-50:after { background-color: $cyan-50; }
  &.cyan-100:after { background-color: $cyan-100; }
  &.cyan-200:after { background-color: $cyan-200; }
  &.cyan-300:after { background-color: $cyan-300; }
  &.cyan-400:after { background-color: $cyan-400; }
  &.cyan-500:after { background-color: $cyan-500; }
  &.cyan-600:after { background-color: $cyan-600; }
  &.cyan-700:after { background-color: $cyan-700; }
  &.cyan-800:after { background-color: $cyan-800; }
  &.cyan-900:after { background-color: $cyan-900; }
  &.cyan-A100:after { background-color: $cyan-A100; }
  &.cyan-A200:after { background-color: $cyan-A200; }
  &.cyan-A400:after { background-color: $cyan-A400; }
  &.cyan-A700:after { background-color: $cyan-A700; }

  &.teal:after { background-color: $teal-500; }
  &.teal-50:after { background-color: $teal-50; }
  &.teal-100:after { background-color: $teal-100; }
  &.teal-200:after { background-color: $teal-200; }
  &.teal-300:after { background-color: $teal-300; }
  &.teal-400:after { background-color: $teal-400; }
  &.teal-500:after { background-color: $teal-500; }
  &.teal-600:after { background-color: $teal-600; }
  &.teal-700:after { background-color: $teal-700; }
  &.teal-800:after { background-color: $teal-800; }
  &.teal-900:after { background-color: $teal-900; }
  &.teal-A100:after { background-color: $teal-A100; }
  &.teal-A200:after { background-color: $teal-A200; }
  &.teal-A400:after { background-color: $teal-A400; }
  &.teal-A700:after { background-color: $teal-A700; }

  &.green:after { background-color: $green-500; }
  &.green-50:after { background-color: $green-50; }
  &.green-100:after { background-color: $green-100; }
  &.green-200:after { background-color: $green-200; }
  &.green-300:after { background-color: $green-300; }
  &.green-400:after { background-color: $green-400; }
  &.green-500:after { background-color: $green-500; }
  &.green-600:after { background-color: $green-600; }
  &.green-700:after { background-color: $green-700; }
  &.green-800:after { background-color: $green-800; }
  &.green-900:after { background-color: $green-900; }
  &.green-A100:after { background-color: $green-A100; }
  &.green-A200:after { background-color: $green-A200; }
  &.green-A400:after { background-color: $green-A400; }
  &.green-A700:after { background-color: $green-A700; }

  &.light-green:after { background-color: $light-green-500; }
  &.light-green-50:after { background-color: $light-green-50; }
  &.light-green-100:after { background-color: $light-green-100; }
  &.light-green-200:after { background-color: $light-green-200; }
  &.light-green-300:after { background-color: $light-green-300; }
  &.light-green-400:after { background-color: $light-green-400; }
  &.light-green-500:after { background-color: $light-green-500; }
  &.light-green-600:after { background-color: $light-green-600; }
  &.light-green-700:after { background-color: $light-green-700; }
  &.light-green-800:after { background-color: $light-green-800; }
  &.light-green-900:after { background-color: $light-green-900; }
  &.light-green-A100:after { background-color: $light-green-A100; }
  &.light-green-A200:after { background-color: $light-green-A200; }
  &.light-green-A400:after { background-color: $light-green-A400; }
  &.light-green-A700:after { background-color: $light-green-A700; }

  &.lime:after { background-color: $lime-500; }
  &.lime-50:after { background-color: $lime-50; }
  &.lime-100:after { background-color: $lime-100; }
  &.lime-200:after { background-color: $lime-200; }
  &.lime-300:after { background-color: $lime-300; }
  &.lime-400:after { background-color: $lime-400; }
  &.lime-500:after { background-color: $lime-500; }
  &.lime-600:after { background-color: $lime-600; }
  &.lime-700:after { background-color: $lime-700; }
  &.lime-800:after { background-color: $lime-800; }
  &.lime-900:after { background-color: $lime-900; }
  &.lime-A100:after { background-color: $lime-A100; }
  &.lime-A200:after { background-color: $lime-A200; }
  &.lime-A400:after { background-color: $lime-A400; }
  &.lime-A700:after { background-color: $lime-A700; }

  &.yellow:after { background-color: $yellow-500; }
  &.yellow-50:after { background-color: $yellow-50; }
  &.yellow-100:after { background-color: $yellow-100; }
  &.yellow-200:after { background-color: $yellow-200; }
  &.yellow-300:after { background-color: $yellow-300; }
  &.yellow-400:after { background-color: $yellow-400; }
  &.yellow-500:after { background-color: $yellow-500; }
  &.yellow-600:after { background-color: $yellow-600; }
  &.yellow-700:after { background-color: $yellow-700; }
  &.yellow-800:after { background-color: $yellow-800; }
  &.yellow-900:after { background-color: $yellow-900; }
  &.yellow-A100:after { background-color: $yellow-A100; }
  &.yellow-A200:after { background-color: $yellow-A200; }
  &.yellow-A400:after { background-color: $yellow-A400; }
  &.yellow-A700:after { background-color: $yellow-A700; }

  &.amber:after { background-color: $amber-500; }
  &.amber-50:after { background-color: $amber-50; }
  &.amber-100:after { background-color: $amber-100; }
  &.amber-200:after { background-color: $amber-200; }
  &.amber-300:after { background-color: $amber-300; }
  &.amber-400:after { background-color: $amber-400; }
  &.amber-500:after { background-color: $amber-500; }
  &.amber-600:after { background-color: $amber-600; }
  &.amber-700:after { background-color: $amber-700; }
  &.amber-800:after { background-color: $amber-800; }
  &.amber-900:after { background-color: $amber-900; }
  &.amber-A100:after { background-color: $amber-A100; }
  &.amber-A200:after { background-color: $amber-A200; }
  &.amber-A400:after { background-color: $amber-A400; }
  &.amber-A700:after { background-color: $amber-A700; }

  &.orange:after { background-color: $orange-500; }
  &.orange-50:after { background-color: $orange-50; }
  &.orange-100:after { background-color: $orange-100; }
  &.orange-200:after { background-color: $orange-200; }
  &.orange-300:after { background-color: $orange-300; }
  &.orange-400:after { background-color: $orange-400; }
  &.orange-500:after { background-color: $orange-500; }
  &.orange-600:after { background-color: $orange-600; }
  &.orange-700:after { background-color: $orange-700; }
  &.orange-800:after { background-color: $orange-800; }
  &.orange-900:after { background-color: $orange-900; }
  &.orange-A100:after { background-color: $orange-A100; }
  &.orange-A200:after { background-color: $orange-A200; }
  &.orange-A400:after { background-color: $orange-A400; }
  &.orange-A700:after { background-color: $orange-A700; }

  &.deep-orange:after { background-color: $deep-orange-500; }
  &.deep-orange-50:after { background-color: $deep-orange-50; }
  &.deep-orange-100:after { background-color: $deep-orange-100; }
  &.deep-orange-200:after { background-color: $deep-orange-200; }
  &.deep-orange-300:after { background-color: $deep-orange-300; }
  &.deep-orange-400:after { background-color: $deep-orange-400; }
  &.deep-orange-500:after { background-color: $deep-orange-500; }
  &.deep-orange-600:after { background-color: $deep-orange-600; }
  &.deep-orange-700:after { background-color: $deep-orange-700; }
  &.deep-orange-800:after { background-color: $deep-orange-800; }
  &.deep-orange-900:after { background-color: $deep-orange-900; }
  &.deep-orange-A100:after { background-color: $deep-orange-A100; }
  &.deep-orange-A200:after { background-color: $deep-orange-A200; }
  &.deep-orange-A400:after { background-color: $deep-orange-A400; }
  &.deep-orange-A700:after { background-color: $deep-orange-A700; }

  &.brown:after { background-color: $brown-500; }
  &.brown-50:after { background-color: $brown-50; }
  &.brown-100:after { background-color: $brown-100; }
  &.brown-200:after { background-color: $brown-200; }
  &.brown-300:after { background-color: $brown-300; }
  &.brown-400:after { background-color: $brown-400; }
  &.brown-500:after { background-color: $brown-500; }
  &.brown-600:after { background-color: $brown-600; }
  &.brown-700:after { background-color: $brown-700; }
  &.brown-800:after { background-color: $brown-800; }
  &.brown-900:after { background-color: $brown-900; }

  &.blue-grey:after { background-color: $blue-grey-500; }
  &.blue-grey-50:after { background-color: $blue-grey-50; }
  &.blue-grey-100:after { background-color: $blue-grey-100; }
  &.blue-grey-200:after { background-color: $blue-grey-200; }
  &.blue-grey-300:after { background-color: $blue-grey-300; }
  &.blue-grey-400:after { background-color: $blue-grey-400; }
  &.blue-grey-500:after { background-color: $blue-grey-500; }
  &.blue-grey-600:after { background-color: $blue-grey-600; }
  &.blue-grey-700:after { background-color: $blue-grey-700; }
  &.blue-grey-800:after { background-color: $blue-grey-800; }
  &.blue-grey-900:after { background-color: $blue-grey-900; }

  &.grey-50:after { background-color: $grey-50; }
  &.grey-100:after { background-color: $grey-100; }
  &.grey-200:after { background-color: $grey-200; }
  &.grey-300:after { background-color: $grey-300; }
  &.grey-400:after { background-color: $grey-400; }
  &.grey-500:after { background-color: $grey-500; }
  &.grey-600:after { background-color: $grey-600; }
  &.grey-700:after { background-color: $grey-700; }
  &.grey-800:after { background-color: $grey-800; }
  &.grey-900:after { background-color: $grey-900; }

}

i[class*="ion-"],
i[class*=" ion-"]{
  font-size: 18px;
  vertical-align: -1px;

  &.ion-lg {
    font-size: (4em / 3);
    line-height: (3em / 4);
    vertical-align: -15%;
  }
  &.ion-2x { font-size: 2em; }
  &.ion-3x { font-size: 3em; }
  &.ion-4x { font-size: 4em; }
  &.ion-5x { font-size: 5em; }
}

i[class*="s-l-i"],
i[class*=" s-l-i"]{
  font-size: 16px;
  vertical-align: -1px;
}
