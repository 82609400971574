/*
Arrow
*/
.arrow {
  position: absolute;
  z-index: 10;
  &:before,
  &:after {
    position: absolute;
    #{$direction_1}: 0;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-width: $arrow-width+1;
    border-color: transparent;
    border-style: solid;
  }
  &:after{
    border-width: $arrow-width;
  }
  &.pull-in{
    z-index: -1;
  }

  &.top {
    top: -$arrow-width - 1;
    #{$direction_1}: 50%;
    margin-#{$direction_1}: -$arrow-width - 1;
    &.pull-in{
      top: -$arrow-width/2;
    }
    &:before{      
      border-bottom-color: $arrow-outer-color;
      border-top-width: 0;
    }
    &:after {
      top: 1px;
      #{$direction_1}: 1px;
      border-top-color: transparent;
      border-#{$direction_2}-color: transparent;      
      border-bottom-color: inherit;
      border-#{$direction_1}-color: transparent;
      border-top-width: 0;      
    }
  }

  &.#{$direction_2} {
    top: 50%;
    #{$direction_2}: 0;    
    margin-top: -$arrow-width - 1;
    &.pull-in{
      #{$direction_2}: $arrow-width/2;
    }
    &:before{      
      border-#{$direction_1}-color: $arrow-outer-color;
      border-#{$direction_2}-width: 0;
    }
    &:after {
      top: 1px;
      #{$direction_1}: 0;
      border-top-color: transparent;
      border-#{$direction_2}-color: transparent;
      border-bottom-color: transparent;
      border-#{$direction_1}-color: inherit;
      border-#{$direction_2}-width: 0;
    }
  }

  &.bottom {
    bottom: 0;
    #{$direction_1}: 50%;
    margin-#{$direction_1}: -$arrow-width - 1;
    &.pull-in{
      bottom: $arrow-width/2;
    }
    &:before{      
      border-top-color: $arrow-outer-color;
      border-bottom-width: 0;
    }
    &:after {
      top: 0px;
      #{$direction_1}: 1px;
      border-top-color: inherit;
      border-#{$direction_2}-color: transparent;      
      border-bottom-color: transparent;
      border-#{$direction_1}-color: transparent;
      border-bottom-width: 0;
    }
  }

  &.#{$direction_1} {
    top: 50%;
    #{$direction_1}: -$arrow-width - 1;
    margin-top: -$arrow-width - 1;
    &.pull-in{
      #{$direction_1}: -$arrow-width/2;
    }
    &:before{      
      border-#{$direction_2}-color: $arrow-outer-color;
      border-#{$direction_1}-width: 0;
    }
    &:after {
      top: 1px;
      #{$direction_1}: 1px;
      border-top-color: transparent;
      border-#{$direction_2}-color: inherit;
      border-bottom-color: transparent;
      border-#{$direction_1}-color: transparent;
      border-#{$direction_1}-width: 0;
    }
  }

  &.pull-#{$direction_1}{
    #{$direction_1}: $arrow-width + 10;
  }
  &.pull-#{$direction_2}{
    #{$direction_1}: auto;
    #{$direction_2}: $arrow-width*2 + 10;
  }
  &.pull-top{
    top: $arrow-width + 10;
  }
  &.pull-bottom{
    top: auto;
    bottom: $arrow-width*2 + 10;
  }

  &.b-primary,
  &.b-info,
  &.b-success,
  &.b-warning,
  &.b-danger,
  &.b-accent,
  &.b-dark{
    &:before{
      border-color: transparent;
    }
  }

}
