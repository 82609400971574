@mixin w-box($size, $q, $display-inline, $text-center) {

  $name: -#{$size};
  @if ($q != false) {
    $name: -#{$size}-q;
  }

  $size: #{$size}px;

  .w#{$name} {
    width: $size;
    @if($q) {
      height: $size;
    }

    @if($display-inline == true) {
      display: inline-block;
    }

    @if($text-center == true) {
      text-align: center;
    }
  }
}


/*
Size
*/

@include w-box(8, false, true, true);
@include w-box(16, false, true, true);
@include w-box(20, false, true, true);
@include w-box(24, false, true, true);
@include w-box(28, false, true, true);
@include w-box(32, false, true, true);
@include w-box(40, false, true, true);
@include w-box(48, false, true, true);
@include w-box(56, false, true, true);
@include w-box(64, false, true, true);
@include w-box(80, false, true, true);
@include w-box(96, false, true, true);
@include w-box(100, false, true, true);
@include w-box(120, false, true, true);
@include w-box(128, false, true, true);
@include w-box(140, false, true, true);
@include w-box(150, false, true, true);
@include w-box(200, false, true, true);
@include w-box(256, false, true, true);
@include w-box(300, false, true, true);
@include w-box(400, false, true, true);
@include w-box(500, false, true, true);
@include w-box(600, false, true, true);


@include w-box(8, true, false, false);
@include w-box(16, true, false, false);
@include w-box(20, true, false, false);
@include w-box(24, true, false, false);
@include w-box(28, true, false, false);
@include w-box(32, true, false, false);
@include w-box(40, true, false, false);
@include w-box(48, true, false, false);
@include w-box(56, true, false, false);
@include w-box(64, true, false, false);
@include w-box(80, true, false, false);
@include w-box(96, true, false, false);
@include w-box(100, true, false, false);
@include w-box(120, true, false, false);
@include w-box(128, true, false, false);
@include w-box(140, true, false, false);
@include w-box(150, true, false, false);
@include w-box(200, true, false, false);
@include w-box(256, true, false, false);
@include w-box(300, true, false, false);
@include w-box(400, true, false, false);
@include w-box(500, true, false, false);
@include w-box(600, true, false, false);


.w-xxs{
  width: 60px;
}
.w-xs{
  width: 90px;
}
.w-sm{
  width: 120px;
}
.w{
  width: 200px;
}
.w-md{
  width: 240px;
}
.w-lg{
  width: 280px;
}
.w-xl{
  width: 320px;
}
.w-xxl{
  width: 344px;
}

.w-full{
  width: 100%;
}
.w-auto{
  width: auto;
}
.h-auto{
  height: auto;
}
.h-full{
  height: 100%;
}
.h-v{
  height: 100vh;
}
.h-v-5{
  height: 50vh;
}

.mh-0{
  min-height: 0;
}
.mh-full{
  min-height: 100%;
}
.mh-v{
  min-height: 100vh;
}
.mh-v-5{
  min-height: 50vh;
}


@include media-breakpoint-down(lg) {
  .w-auto-lg{
    width: auto;
  }
  .w-full-lg{
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .w-auto-md{
    width: auto;
  }
  .w-full-md{
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .w-auto-sm{
    width: auto;
  }
  .w-full-sm{
    width: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .w-auto-xs{
    width: auto;
  }
  .w-full-xs{
    width: 100%;
  }
}


// width from 0% to 100%

@for $i from 0 through 100 {
  .w-#{$i}-p {
    width: 0% + $i;
  }
}
