/*
Border
*/
@mixin border-wariant($parent, $bg){
  $color: mix($bg, #fff, 10%);
  .b-#{$parent}{
    border-color: $bg;
  }
  .b-t-#{$parent}{
    border-top-color: $bg;
  }
  .b-r-#{$parent}{
    border-#{$direction_2}-color: $bg;
  }
  .b-b-#{$parent}{
    border-bottom-color: $bg;
  }
  .b-l-#{$parent}{
    border-#{$direction_1}-color: $bg;
  }
}

.no-border{
  border-color: transparent;
  border-width: 0;
}
.no-borders{
  border-width:0 !important;
}

.b{
  border-color: $border-color;
  background-clip: padding-box;
  &.rounded {
    border-radius: $border-radius-base;
  }
}
.b-a{
  border: 1px solid $border-color;
  &.rounded {
    border-radius: $border-radius-base;
  }
}
.b-t{
  border-top: 1px solid $border-color;
}
.b-r{
  border-#{$direction_2}: 1px solid $border-color;
}
.b-b{
  border-bottom: 1px solid $border-color;
}
.b-l{
  border-#{$direction_1}: 1px solid $border-color;
}

.b-2x{
  border-width: 2px;
}
.b-3x{
  border-width: 3px;
}
.b-4x{
  border-width: 4px;
}
.b-5x{
  border-width: 5px;
}

.b-t-2x{
  border-top-width: 2px !important;
}
.b-t-3x{
  border-top-width: 3px !important;
}
.b-t-4x{
  border-top-width: 4px !important;
}
.b-t-5x{
  border-top-width: 5px !important;
}

.b-r-2x{
  border-#{$direction_2}-width: 2px !important;
}
.b-r-3x{
  border-#{$direction_2}-width: 3px !important;
}
.b-r-4x{
  border-#{$direction_2}-width: 4px !important;
}
.b-r-5x{
  border-#{$direction_2}-width: 5px !important;
}

.b-b-2x{
  border-bottom-width: 2px !important;
}
.b-b-3x{
  border-bottom-width: 3px !important;
}
.b-b-4x{
  border-bottom-width: 4px !important;
}
.b-b-5x{
  border-bottom-width: 5px !important;
}

.b-l-2x{
  border-#{$direction_1}-width: 2px !important;
}
.b-l-3x{
  border-#{$direction_1}-width: 3px !important;
}
.b-l-4x{
  border-#{$direction_1}-width: 4px !important;
}
.b-l-5x{
  border-#{$direction_1}-width: 5px !important;
}

.b-light{
  border-color: $light;
}
.b-white{
  border-color: #fff;
}

@include border-wariant(primary, $primary);
@include border-wariant(accent, $accent);
@include border-wariant(warn, $warn);
@include border-wariant(success, $success);
@include border-wariant(info, $info);
@include border-wariant(warning, $warning);
@include border-wariant(danger, $danger);
@include border-wariant(light, $light);
@include border-wariant("grey", $grey);
@include border-wariant(dark, $dark);
@include border-wariant("black", $black);
@include border-wariant("white", $white);
@include border-wariant("blue", $blue);
@include border-wariant(default, $border-color);

@include border-wariant('red', $red-500);
@include border-wariant('red-50', $red-50);
@include border-wariant('red-100', $red-100);
@include border-wariant('red-200', $red-200);
@include border-wariant('red-300', $red-300);
@include border-wariant('red-400', $red-400);
@include border-wariant('red-500', $red-500);
@include border-wariant('red-600', $red-600);
@include border-wariant('red-700', $red-700);
@include border-wariant('red-800', $red-800);
@include border-wariant('red-900', $red-900);
@include border-wariant('red-A100', $red-A100);
@include border-wariant('red-A200', $red-A200);
@include border-wariant('red-A400', $red-A400);
@include border-wariant('red-A700', $red-A700);

@include border-wariant('pink', $pink-500);
@include border-wariant('pink-50', $pink-50);
@include border-wariant('pink-100', $pink-100);
@include border-wariant('pink-200', $pink-200);
@include border-wariant('pink-300', $pink-300);
@include border-wariant('pink-400', $pink-400);
@include border-wariant('pink-500', $pink-500);
@include border-wariant('pink-600', $pink-600);
@include border-wariant('pink-700', $pink-700);
@include border-wariant('pink-800', $pink-800);
@include border-wariant('pink-900', $pink-900);
@include border-wariant('pink-A100', $pink-A100);
@include border-wariant('pink-A200', $pink-A200);
@include border-wariant('pink-A400', $pink-A400);
@include border-wariant('pink-A700', $pink-A700);

@include border-wariant('purple', $purple-500);
@include border-wariant('purple-50', $purple-50);
@include border-wariant('purple-100', $purple-100);
@include border-wariant('purple-200', $purple-200);
@include border-wariant('purple-300', $purple-300);
@include border-wariant('purple-400', $purple-400);
@include border-wariant('purple-500', $purple-500);
@include border-wariant('purple-600', $purple-600);
@include border-wariant('purple-700', $purple-700);
@include border-wariant('purple-800', $purple-800);
@include border-wariant('purple-900', $purple-900);
@include border-wariant('purple-A100', $purple-A100);
@include border-wariant('purple-A200', $purple-A200);
@include border-wariant('purple-A400', $purple-A400);
@include border-wariant('purple-A700', $purple-A700);

@include border-wariant('deep-purple', $deep-purple-500);
@include border-wariant('deep-purple-50', $deep-purple-50);
@include border-wariant('deep-purple-100', $deep-purple-100);
@include border-wariant('deep-purple-200', $deep-purple-200);
@include border-wariant('deep-purple-300', $deep-purple-300);
@include border-wariant('deep-purple-400', $deep-purple-400);
@include border-wariant('deep-purple-500', $deep-purple-500);
@include border-wariant('deep-purple-600', $deep-purple-600);
@include border-wariant('deep-purple-700', $deep-purple-700);
@include border-wariant('deep-purple-800', $deep-purple-800);
@include border-wariant('deep-purple-900', $deep-purple-900);
@include border-wariant('deep-purple-A100', $deep-purple-A100);
@include border-wariant('deep-purple-A200', $deep-purple-A200);
@include border-wariant('deep-purple-A400', $deep-purple-A400);
@include border-wariant('deep-purple-A700', $deep-purple-A700);

@include border-wariant('indigo', $indigo-500);
@include border-wariant('indigo-50', $indigo-50);
@include border-wariant('indigo-100', $indigo-100);
@include border-wariant('indigo-200', $indigo-200);
@include border-wariant('indigo-300', $indigo-300);
@include border-wariant('indigo-400', $indigo-400);
@include border-wariant('indigo-500', $indigo-500);
@include border-wariant('indigo-600', $indigo-600);
@include border-wariant('indigo-700', $indigo-700);
@include border-wariant('indigo-800', $indigo-800);
@include border-wariant('indigo-900', $indigo-900);
@include border-wariant('indigo-A100', $indigo-A100);
@include border-wariant('indigo-A200', $indigo-A200);
@include border-wariant('indigo-A400', $indigo-A400);
@include border-wariant('indigo-A700', $indigo-A700);

@include border-wariant('blue-50', $blue-50);
@include border-wariant('blue-100', $blue-100);
@include border-wariant('blue-200', $blue-200);
@include border-wariant('blue-300', $blue-300);
@include border-wariant('blue-400', $blue-400);
@include border-wariant('blue-500', $blue-500);
@include border-wariant('blue-600', $blue-600);
@include border-wariant('blue-700', $blue-700);
@include border-wariant('blue-800', $blue-800);
@include border-wariant('blue-900', $blue-900);
@include border-wariant('blue-A100', $blue-A100);
@include border-wariant('blue-A200', $blue-A200);
@include border-wariant('blue-A400', $blue-A400);
@include border-wariant('blue-A700', $blue-A700);

@include border-wariant('light-blue', $light-blue-500);
@include border-wariant('light-blue-50', $light-blue-50);
@include border-wariant('light-blue-100', $light-blue-100);
@include border-wariant('light-blue-200', $light-blue-200);
@include border-wariant('light-blue-300', $light-blue-300);
@include border-wariant('light-blue-400', $light-blue-400);
@include border-wariant('light-blue-500', $light-blue-500);
@include border-wariant('light-blue-600', $light-blue-600);
@include border-wariant('light-blue-700', $light-blue-700);
@include border-wariant('light-blue-800', $light-blue-800);
@include border-wariant('light-blue-900', $light-blue-900);
@include border-wariant('light-blue-A100', $light-blue-A100);
@include border-wariant('light-blue-A200', $light-blue-A200);
@include border-wariant('light-blue-A400', $light-blue-A400);
@include border-wariant('light-blue-A700', $light-blue-A700);

@include border-wariant('cyan', $cyan-500);
@include border-wariant('cyan-50', $cyan-50);
@include border-wariant('cyan-100', $cyan-100);
@include border-wariant('cyan-200', $cyan-200);
@include border-wariant('cyan-300', $cyan-300);
@include border-wariant('cyan-400', $cyan-400);
@include border-wariant('cyan-500', $cyan-500);
@include border-wariant('cyan-600', $cyan-600);
@include border-wariant('cyan-700', $cyan-700);
@include border-wariant('cyan-800', $cyan-800);
@include border-wariant('cyan-900', $cyan-900);
@include border-wariant('cyan-A100', $cyan-A100);
@include border-wariant('cyan-A200', $cyan-A200);
@include border-wariant('cyan-A400', $cyan-A400);
@include border-wariant('cyan-A700', $cyan-A700);

@include border-wariant('teal', $teal-500);
@include border-wariant('teal-50', $teal-50);
@include border-wariant('teal-100', $teal-100);
@include border-wariant('teal-200', $teal-200);
@include border-wariant('teal-300', $teal-300);
@include border-wariant('teal-400', $teal-400);
@include border-wariant('teal-500', $teal-500);
@include border-wariant('teal-600', $teal-600);
@include border-wariant('teal-700', $teal-700);
@include border-wariant('teal-800', $teal-800);
@include border-wariant('teal-900', $teal-900);
@include border-wariant('teal-A100', $teal-A100);
@include border-wariant('teal-A200', $teal-A200);
@include border-wariant('teal-A400', $teal-A400);
@include border-wariant('teal-A700', $teal-A700);

@include border-wariant('green', $green-500);
@include border-wariant('green-50', $green-50);
@include border-wariant('green-100', $green-100);
@include border-wariant('green-200', $green-200);
@include border-wariant('green-300', $green-300);
@include border-wariant('green-400', $green-400);
@include border-wariant('green-500', $green-500);
@include border-wariant('green-600', $green-600);
@include border-wariant('green-700', $green-700);
@include border-wariant('green-800', $green-800);
@include border-wariant('green-900', $green-900);
@include border-wariant('green-A100', $green-A100);
@include border-wariant('green-A200', $green-A200);
@include border-wariant('green-A400', $green-A400);
@include border-wariant('green-A700', $green-A700);

@include border-wariant('light-green', $light-green-500);
@include border-wariant('light-green-50', $light-green-50);
@include border-wariant('light-green-100', $light-green-100);
@include border-wariant('light-green-200', $light-green-200);
@include border-wariant('light-green-300', $light-green-300);
@include border-wariant('light-green-400', $light-green-400);
@include border-wariant('light-green-500', $light-green-500);
@include border-wariant('light-green-600', $light-green-600);
@include border-wariant('light-green-700', $light-green-700);
@include border-wariant('light-green-800', $light-green-800);
@include border-wariant('light-green-900', $light-green-900);
@include border-wariant('light-green-A100', $light-green-A100);
@include border-wariant('light-green-A200', $light-green-A200);
@include border-wariant('light-green-A400', $light-green-A400);
@include border-wariant('light-green-A700', $light-green-A700);

@include border-wariant('lime', $lime-500);
@include border-wariant('lime-50', $lime-50);
@include border-wariant('lime-100', $lime-100);
@include border-wariant('lime-200', $lime-200);
@include border-wariant('lime-300', $lime-300);
@include border-wariant('lime-400', $lime-400);
@include border-wariant('lime-500', $lime-500);
@include border-wariant('lime-600', $lime-600);
@include border-wariant('lime-700', $lime-700);
@include border-wariant('lime-800', $lime-800);
@include border-wariant('lime-900', $lime-900);
@include border-wariant('lime-A100', $lime-A100);
@include border-wariant('lime-A200', $lime-A200);
@include border-wariant('lime-A400', $lime-A400);
@include border-wariant('lime-A700', $lime-A700);

@include border-wariant('yellow', $yellow-500);
@include border-wariant('yellow-50', $yellow-50);
@include border-wariant('yellow-100', $yellow-100);
@include border-wariant('yellow-200', $yellow-200);
@include border-wariant('yellow-300', $yellow-300);
@include border-wariant('yellow-400', $yellow-400);
@include border-wariant('yellow-500', $yellow-500);
@include border-wariant('yellow-600', $yellow-600);
@include border-wariant('yellow-700', $yellow-700);
@include border-wariant('yellow-800', $yellow-800);
@include border-wariant('yellow-900', $yellow-900);
@include border-wariant('yellow-A100', $yellow-A100);
@include border-wariant('yellow-A200', $yellow-A200);
@include border-wariant('yellow-A400', $yellow-A400);
@include border-wariant('yellow-A700', $yellow-A700);

@include border-wariant('amber', $amber-500);
@include border-wariant('amber-50', $amber-50);
@include border-wariant('amber-100', $amber-100);
@include border-wariant('amber-200', $amber-200);
@include border-wariant('amber-300', $amber-300);
@include border-wariant('amber-400', $amber-400);
@include border-wariant('amber-500', $amber-500);
@include border-wariant('amber-600', $amber-600);
@include border-wariant('amber-700', $amber-700);
@include border-wariant('amber-800', $amber-800);
@include border-wariant('amber-900', $amber-900);
@include border-wariant('amber-A100', $amber-A100);
@include border-wariant('amber-A200', $amber-A200);
@include border-wariant('amber-A400', $amber-A400);
@include border-wariant('amber-A700', $amber-A700);

@include border-wariant('orange', $orange-500);
@include border-wariant('orange-50', $orange-50);
@include border-wariant('orange-100', $orange-100);
@include border-wariant('orange-200', $orange-200);
@include border-wariant('orange-300', $orange-300);
@include border-wariant('orange-400', $orange-400);
@include border-wariant('orange-500', $orange-500);
@include border-wariant('orange-600', $orange-600);
@include border-wariant('orange-700', $orange-700);
@include border-wariant('orange-800', $orange-800);
@include border-wariant('orange-900', $orange-900);
@include border-wariant('orange-A100', $orange-A100);
@include border-wariant('orange-A200', $orange-A200);
@include border-wariant('orange-A400', $orange-A400);
@include border-wariant('orange-A700', $orange-A700);

@include border-wariant('deep-orange', $deep-orange-500);
@include border-wariant('deep-orange-50', $deep-orange-50);
@include border-wariant('deep-orange-100', $deep-orange-100);
@include border-wariant('deep-orange-200', $deep-orange-200);
@include border-wariant('deep-orange-300', $deep-orange-300);
@include border-wariant('deep-orange-400', $deep-orange-400);
@include border-wariant('deep-orange-500', $deep-orange-500);
@include border-wariant('deep-orange-600', $deep-orange-600);
@include border-wariant('deep-orange-700', $deep-orange-700);
@include border-wariant('deep-orange-800', $deep-orange-800);
@include border-wariant('deep-orange-900', $deep-orange-900);
@include border-wariant('deep-orange-A100', $deep-orange-A100);
@include border-wariant('deep-orange-A200', $deep-orange-A200);
@include border-wariant('deep-orange-A400', $deep-orange-A400);
@include border-wariant('deep-orange-A700', $deep-orange-A700);

@include border-wariant('brown', $brown-500);
@include border-wariant('brown-50', $brown-50);
@include border-wariant('brown-100', $brown-100);
@include border-wariant('brown-200', $brown-200);
@include border-wariant('brown-300', $brown-300);
@include border-wariant('brown-400', $brown-400);
@include border-wariant('brown-500', $brown-500);
@include border-wariant('brown-600', $brown-600);
@include border-wariant('brown-700', $brown-700);
@include border-wariant('brown-800', $brown-800);
@include border-wariant('brown-900', $brown-900);

@include border-wariant('blue-grey', $blue-grey-500);
@include border-wariant('blue-grey-50', $blue-grey-50);
@include border-wariant('blue-grey-100', $blue-grey-100);
@include border-wariant('blue-grey-200', $blue-grey-200);
@include border-wariant('blue-grey-300', $blue-grey-300);
@include border-wariant('blue-grey-400', $blue-grey-400);
@include border-wariant('blue-grey-500', $blue-grey-500);
@include border-wariant('blue-grey-600', $blue-grey-600);
@include border-wariant('blue-grey-700', $blue-grey-700);
@include border-wariant('blue-grey-800', $blue-grey-800);
@include border-wariant('blue-grey-900', $blue-grey-900);

@include border-wariant('grey-50', $grey-50);
@include border-wariant('grey-100', $grey-100);
@include border-wariant('grey-200', $grey-200);
@include border-wariant('grey-300', $grey-300);
@include border-wariant('grey-400', $grey-400);
@include border-wariant('grey-500', $grey-500);
@include border-wariant('grey-600', $grey-600);
@include border-wariant('grey-700', $grey-700);
@include border-wariant('grey-800', $grey-800);
@include border-wariant('grey-900', $grey-900);


.no-b-t{
  border-top-width: 0;
}
.no-b-r{
  border-right-width: 0;
}
.no-b-b{
  border-bottom-width: 0;
}
.no-b-l{
  border-left-width: 0;
}

.b-dashed{
  border-style: dashed !important;
}

.b-transparent{
  border-color: transparent !important;
}

@include media-breakpoint-down(md)  {
  .no-border-md{border-width: 0;}
}

@include media-breakpoint-down(sm)  {
  .no-border-sm{border-width: 0;}
}

@include media-breakpoint-down(xs)  {
  .no-border-xs{border-width: 0;}
}

.b-bs-c {
  @include box-sizing(content-box);
}

.b-r-500 {
  border-radius: 500px;
}

.b-r-50p {
  border-radius: 50%;
}