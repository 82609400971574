.rating {
	unicode-bidi: bidi-override;
	@if($rtl == true) {
		direction: ltr;
	} @else {
		direction: rtl;
	}
	font-size: 30px;
	span.star {
		font-family: 'FontAwesome';
		font-weight: normal;
		font-style: normal;
		display: inline-block;
		&:hover {
			cursor: pointer;
		}
		&:before {
			content: "\f006";
			padding-right: 5px;
		}
		&:hover,
		&.selected {
			&:before, ~ span.star:before {
				content: "\f005";
				// color: #e3cf7a;
			}
		}
	}
}