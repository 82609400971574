/*
Text
*/
@mixin text-wariant($parent, $bg){
  a.#{$parent}:hover{
    background-color: darken($bg, $color-percent);
  }
  a.text-#{$parent}:hover{
    color: darken($bg, $color-percent);
  }
  .text-#{$parent},
  .text-#{$parent}-f-h:hover,
  .text-#{$parent}-hover a:hover,
  .text-#{$parent}-hover .active > a{
    color: $bg !important;
  }
}

@include text-wariant('primary', $primary);
@include text-wariant('warn', $warn);
@include text-wariant('accent', $accent);
@include text-wariant('success', $success);
@include text-wariant('info', $info);
@include text-wariant('warning', $warning);
@include text-wariant('danger', $danger);
@include text-wariant('dark', $dark);
@include text-wariant('blue', $blue);

@include text-wariant('red', $red-500);
@include text-wariant('red-50', $red-50);
@include text-wariant('red-100', $red-100);
@include text-wariant('red-200', $red-200);
@include text-wariant('red-300', $red-300);
@include text-wariant('red-400', $red-400);
@include text-wariant('red-500', $red-500);
@include text-wariant('red-600', $red-600);
@include text-wariant('red-700', $red-700);
@include text-wariant('red-800', $red-800);
@include text-wariant('red-900', $red-900);
@include text-wariant('red-A100', $red-A100);
@include text-wariant('red-A200', $red-A200);
@include text-wariant('red-A400', $red-A400);
@include text-wariant('red-A700', $red-A700);

@include text-wariant('pink', $pink-500);
@include text-wariant('pink-50', $pink-50);
@include text-wariant('pink-100', $pink-100);
@include text-wariant('pink-200', $pink-200);
@include text-wariant('pink-300', $pink-300);
@include text-wariant('pink-400', $pink-400);
@include text-wariant('pink-500', $pink-500);
@include text-wariant('pink-600', $pink-600);
@include text-wariant('pink-700', $pink-700);
@include text-wariant('pink-800', $pink-800);
@include text-wariant('pink-900', $pink-900);
@include text-wariant('pink-A100', $pink-A100);
@include text-wariant('pink-A200', $pink-A200);
@include text-wariant('pink-A400', $pink-A400);
@include text-wariant('pink-A700', $pink-A700);

@include text-wariant('purple', $purple-500);
@include text-wariant('purple-50', $purple-50);
@include text-wariant('purple-100', $purple-100);
@include text-wariant('purple-200', $purple-200);
@include text-wariant('purple-300', $purple-300);
@include text-wariant('purple-400', $purple-400);
@include text-wariant('purple-500', $purple-500);
@include text-wariant('purple-600', $purple-600);
@include text-wariant('purple-700', $purple-700);
@include text-wariant('purple-800', $purple-800);
@include text-wariant('purple-900', $purple-900);
@include text-wariant('purple-A100', $purple-A100);
@include text-wariant('purple-A200', $purple-A200);
@include text-wariant('purple-A400', $purple-A400);
@include text-wariant('purple-A700', $purple-A700);

@include text-wariant('deep-purple', $deep-purple-500);
@include text-wariant('deep-purple-50', $deep-purple-50);
@include text-wariant('deep-purple-100', $deep-purple-100);
@include text-wariant('deep-purple-200', $deep-purple-200);
@include text-wariant('deep-purple-300', $deep-purple-300);
@include text-wariant('deep-purple-400', $deep-purple-400);
@include text-wariant('deep-purple-500', $deep-purple-500);
@include text-wariant('deep-purple-600', $deep-purple-600);
@include text-wariant('deep-purple-700', $deep-purple-700);
@include text-wariant('deep-purple-800', $deep-purple-800);
@include text-wariant('deep-purple-900', $deep-purple-900);
@include text-wariant('deep-purple-A100', $deep-purple-A100);
@include text-wariant('deep-purple-A200', $deep-purple-A200);
@include text-wariant('deep-purple-A400', $deep-purple-A400);
@include text-wariant('deep-purple-A700', $deep-purple-A700);

@include text-wariant('indigo', $indigo-500);
@include text-wariant('indigo-50', $indigo-50);
@include text-wariant('indigo-100', $indigo-100);
@include text-wariant('indigo-200', $indigo-200);
@include text-wariant('indigo-300', $indigo-300);
@include text-wariant('indigo-400', $indigo-400);
@include text-wariant('indigo-500', $indigo-500);
@include text-wariant('indigo-600', $indigo-600);
@include text-wariant('indigo-700', $indigo-700);
@include text-wariant('indigo-800', $indigo-800);
@include text-wariant('indigo-900', $indigo-900);
@include text-wariant('indigo-A100', $indigo-A100);
@include text-wariant('indigo-A200', $indigo-A200);
@include text-wariant('indigo-A400', $indigo-A400);
@include text-wariant('indigo-A700', $indigo-A700);

@include text-wariant('blue', $blue-500);
@include text-wariant('blue-50', $blue-50);
@include text-wariant('blue-100', $blue-100);
@include text-wariant('blue-200', $blue-200);
@include text-wariant('blue-300', $blue-300);
@include text-wariant('blue-400', $blue-400);
@include text-wariant('blue-500', $blue-500);
@include text-wariant('blue-600', $blue-600);
@include text-wariant('blue-700', $blue-700);
@include text-wariant('blue-800', $blue-800);
@include text-wariant('blue-900', $blue-900);
@include text-wariant('blue-A100', $blue-A100);
@include text-wariant('blue-A200', $blue-A200);
@include text-wariant('blue-A400', $blue-A400);
@include text-wariant('blue-A700', $blue-A700);

@include text-wariant('light-blue', $light-blue-500);
@include text-wariant('light-blue-50', $light-blue-50);
@include text-wariant('light-blue-100', $light-blue-100);
@include text-wariant('light-blue-200', $light-blue-200);
@include text-wariant('light-blue-300', $light-blue-300);
@include text-wariant('light-blue-400', $light-blue-400);
@include text-wariant('light-blue-500', $light-blue-500);
@include text-wariant('light-blue-600', $light-blue-600);
@include text-wariant('light-blue-700', $light-blue-700);
@include text-wariant('light-blue-800', $light-blue-800);
@include text-wariant('light-blue-900', $light-blue-900);
@include text-wariant('light-blue-A100', $light-blue-A100);
@include text-wariant('light-blue-A200', $light-blue-A200);
@include text-wariant('light-blue-A400', $light-blue-A400);
@include text-wariant('light-blue-A700', $light-blue-A700);

@include text-wariant('cyan', $cyan-500);
@include text-wariant('cyan-50', $cyan-50);
@include text-wariant('cyan-100', $cyan-100);
@include text-wariant('cyan-200', $cyan-200);
@include text-wariant('cyan-300', $cyan-300);
@include text-wariant('cyan-400', $cyan-400);
@include text-wariant('cyan-500', $cyan-500);
@include text-wariant('cyan-600', $cyan-600);
@include text-wariant('cyan-700', $cyan-700);
@include text-wariant('cyan-800', $cyan-800);
@include text-wariant('cyan-900', $cyan-900);
@include text-wariant('cyan-A100', $cyan-A100);
@include text-wariant('cyan-A200', $cyan-A200);
@include text-wariant('cyan-A400', $cyan-A400);
@include text-wariant('cyan-A700', $cyan-A700);

@include text-wariant('teal', $teal-500);
@include text-wariant('teal-50', $teal-50);
@include text-wariant('teal-100', $teal-100);
@include text-wariant('teal-200', $teal-200);
@include text-wariant('teal-300', $teal-300);
@include text-wariant('teal-400', $teal-400);
@include text-wariant('teal-500', $teal-500);
@include text-wariant('teal-600', $teal-600);
@include text-wariant('teal-700', $teal-700);
@include text-wariant('teal-800', $teal-800);
@include text-wariant('teal-900', $teal-900);
@include text-wariant('teal-A100', $teal-A100);
@include text-wariant('teal-A200', $teal-A200);
@include text-wariant('teal-A400', $teal-A400);
@include text-wariant('teal-A700', $teal-A700);

@include text-wariant('green', $green-500);
@include text-wariant('green-50', $green-50);
@include text-wariant('green-100', $green-100);
@include text-wariant('green-200', $green-200);
@include text-wariant('green-300', $green-300);
@include text-wariant('green-400', $green-400);
@include text-wariant('green-500', $green-500);
@include text-wariant('green-600', $green-600);
@include text-wariant('green-700', $green-700);
@include text-wariant('green-800', $green-800);
@include text-wariant('green-900', $green-900);
@include text-wariant('green-A100', $green-A100);
@include text-wariant('green-A200', $green-A200);
@include text-wariant('green-A400', $green-A400);
@include text-wariant('green-A700', $green-A700);

@include text-wariant('light-green', $light-green-500);
@include text-wariant('light-green-50', $light-green-50);
@include text-wariant('light-green-100', $light-green-100);
@include text-wariant('light-green-200', $light-green-200);
@include text-wariant('light-green-300', $light-green-300);
@include text-wariant('light-green-400', $light-green-400);
@include text-wariant('light-green-500', $light-green-500);
@include text-wariant('light-green-600', $light-green-600);
@include text-wariant('light-green-700', $light-green-700);
@include text-wariant('light-green-800', $light-green-800);
@include text-wariant('light-green-900', $light-green-900);
@include text-wariant('light-green-A100', $light-green-A100);
@include text-wariant('light-green-A200', $light-green-A200);
@include text-wariant('light-green-A400', $light-green-A400);
@include text-wariant('light-green-A700', $light-green-A700);

@include text-wariant('lime', $lime-500);
@include text-wariant('lime-50', $lime-50);
@include text-wariant('lime-100', $lime-100);
@include text-wariant('lime-200', $lime-200);
@include text-wariant('lime-300', $lime-300);
@include text-wariant('lime-400', $lime-400);
@include text-wariant('lime-500', $lime-500);
@include text-wariant('lime-600', $lime-600);
@include text-wariant('lime-700', $lime-700);
@include text-wariant('lime-800', $lime-800);
@include text-wariant('lime-900', $lime-900);
@include text-wariant('lime-A100', $lime-A100);
@include text-wariant('lime-A200', $lime-A200);
@include text-wariant('lime-A400', $lime-A400);
@include text-wariant('lime-A700', $lime-A700);

@include text-wariant('yellow', $yellow-500);
@include text-wariant('yellow-50', $yellow-50);
@include text-wariant('yellow-100', $yellow-100);
@include text-wariant('yellow-200', $yellow-200);
@include text-wariant('yellow-300', $yellow-300);
@include text-wariant('yellow-400', $yellow-400);
@include text-wariant('yellow-500', $yellow-500);
@include text-wariant('yellow-600', $yellow-600);
@include text-wariant('yellow-700', $yellow-700);
@include text-wariant('yellow-800', $yellow-800);
@include text-wariant('yellow-900', $yellow-900);
@include text-wariant('yellow-A100', $yellow-A100);
@include text-wariant('yellow-A200', $yellow-A200);
@include text-wariant('yellow-A400', $yellow-A400);
@include text-wariant('yellow-A700', $yellow-A700);

@include text-wariant('amber', $amber-500);
@include text-wariant('amber-50', $amber-50);
@include text-wariant('amber-100', $amber-100);
@include text-wariant('amber-200', $amber-200);
@include text-wariant('amber-300', $amber-300);
@include text-wariant('amber-400', $amber-400);
@include text-wariant('amber-500', $amber-500);
@include text-wariant('amber-600', $amber-600);
@include text-wariant('amber-700', $amber-700);
@include text-wariant('amber-800', $amber-800);
@include text-wariant('amber-900', $amber-900);
@include text-wariant('amber-A100', $amber-A100);
@include text-wariant('amber-A200', $amber-A200);
@include text-wariant('amber-A400', $amber-A400);
@include text-wariant('amber-A700', $amber-A700);

@include text-wariant('orange', $orange-500);
@include text-wariant('orange-50', $orange-50);
@include text-wariant('orange-100', $orange-100);
@include text-wariant('orange-200', $orange-200);
@include text-wariant('orange-300', $orange-300);
@include text-wariant('orange-400', $orange-400);
@include text-wariant('orange-500', $orange-500);
@include text-wariant('orange-600', $orange-600);
@include text-wariant('orange-700', $orange-700);
@include text-wariant('orange-800', $orange-800);
@include text-wariant('orange-900', $orange-900);
@include text-wariant('orange-A100', $orange-A100);
@include text-wariant('orange-A200', $orange-A200);
@include text-wariant('orange-A400', $orange-A400);
@include text-wariant('orange-A700', $orange-A700);

@include text-wariant('deep-orange', $deep-orange-500);
@include text-wariant('deep-orange-50', $deep-orange-50);
@include text-wariant('deep-orange-100', $deep-orange-100);
@include text-wariant('deep-orange-200', $deep-orange-200);
@include text-wariant('deep-orange-300', $deep-orange-300);
@include text-wariant('deep-orange-400', $deep-orange-400);
@include text-wariant('deep-orange-500', $deep-orange-500);
@include text-wariant('deep-orange-600', $deep-orange-600);
@include text-wariant('deep-orange-700', $deep-orange-700);
@include text-wariant('deep-orange-800', $deep-orange-800);
@include text-wariant('deep-orange-900', $deep-orange-900);
@include text-wariant('deep-orange-A100', $deep-orange-A100);
@include text-wariant('deep-orange-A200', $deep-orange-A200);
@include text-wariant('deep-orange-A400', $deep-orange-A400);
@include text-wariant('deep-orange-A700', $deep-orange-A700);

@include text-wariant('brown', $brown-500);
@include text-wariant('brown-50', $brown-50);
@include text-wariant('brown-100', $brown-100);
@include text-wariant('brown-200', $brown-200);
@include text-wariant('brown-300', $brown-300);
@include text-wariant('brown-400', $brown-400);
@include text-wariant('brown-500', $brown-500);
@include text-wariant('brown-600', $brown-600);
@include text-wariant('brown-700', $brown-700);
@include text-wariant('brown-800', $brown-800);
@include text-wariant('brown-900', $brown-900);

@include text-wariant('blue-grey', $blue-grey-500);
@include text-wariant('blue-grey-50', $blue-grey-50);
@include text-wariant('blue-grey-100', $blue-grey-100);
@include text-wariant('blue-grey-200', $blue-grey-200);
@include text-wariant('blue-grey-300', $blue-grey-300);
@include text-wariant('blue-grey-400', $blue-grey-400);
@include text-wariant('blue-grey-500', $blue-grey-500);
@include text-wariant('blue-grey-600', $blue-grey-600);
@include text-wariant('blue-grey-700', $blue-grey-700);
@include text-wariant('blue-grey-800', $blue-grey-800);
@include text-wariant('blue-grey-900', $blue-grey-900);

@include text-wariant('grey-50', $grey-50);
@include text-wariant('grey-100', $grey-100);
@include text-wariant('grey-200', $grey-200);
@include text-wariant('grey-300', $grey-300);
@include text-wariant('grey-400', $grey-400);
@include text-wariant('grey-500', $grey-500);
@include text-wariant('grey-600', $grey-600);
@include text-wariant('grey-700', $grey-700);
@include text-wariant('grey-800', $grey-800);
@include text-wariant('grey-900', $grey-900);


@mixin util-text($sufix) {

  .text-white#{$sufix} {
    color: $full-white !important;
  }
  .text-white-lt#{$sufix}{
    color: $light-white;
  }
  .text-white-dk#{$sufix}{
    color: $dark-white;
  }
  .text-black#{$sufix} {
    color: $full-black;
  }
  .text-black-lt#{$sufix} {
    color: $light-black;
  }
  .text-black-dk#{$sufix} {
    color: $dark-black;
  }
  .text-muted#{$sufix} {
    color: inherit !important;
    opacity: 0.6;
  }
  a.text-muted#{$sufix}{
    &:hover,
    &:focus{
      opacity: 1;
    }
  }
  .text-color#{$sufix}{
    color: $text-color;
  }

  ._100#{$sufix}{
    font-weight: 100;
  }
  ._200#{$sufix}{
    font-weight: 200;
  }
  ._300#{$sufix}{
    font-weight: 300;
  }
  ._400#{$sufix}{
    font-weight: 400;
  }
  ._500#{$sufix}{
    font-weight: 500;
  }
  ._600#{$sufix}{
    font-weight: 600;
  }
  ._700#{$sufix}{
    font-weight: 700;
  }
  ._800#{$sufix}{
    font-weight: 800;
  }

  .t-a-r#{$sufix}{
    text-align: right;
  }
  .t-a-l#{$sufix}{
    text-align: left;
  }

  .text#{$sufix}{
    font-size: 1rem;
  }
  .text-xxs#{$sufix}{
    font-size: $font-size-xs;
  }
  .text-xs#{$sufix}{
    font-size: $font-size-xs;
  }
  .text-sm#{$sufix}{
    font-size: $font-size-sm;
  }
  .text-md#{$sufix}{
    font-size: $font-size-md;
  }
  .text-lg#{$sufix}{
    font-size: $font-size-lg;
  }

  .text-1p25x#{$sufix}{
    font-size: 1.25em !important;
  }
  .text-1p5x#{$sufix}{
    font-size: 1.5em !important;
  }
  .text-2x#{$sufix}{
    font-size: 2em !important;
  }
  .text-3x#{$sufix}{
    font-size: 3em !important;
  }
  .text-4x#{$sufix}{
    font-size: 4em !important;
  }

  .l-h-0#{$sufix}{
    line-height: 0;
  }
  .l-h#{$sufix}{
    line-height: $line-height;
  }
  .l-h-d5x#{$sufix}{
    line-height: .5;
  }
  .l-h-d75x#{$sufix}{
    line-height: .75;
  }
  .l-h-d8x#{$sufix}{
    line-height: .8;
  }
  .l-h-d9x#{$sufix}{
    line-height: .9;
  }
  .l-h-1x#{$sufix}{
    line-height: 1;
  }
  .l-h-1d5x#{$sufix}{
    line-height: 1.5em;
  }
  .l-h-1d65x#{$sufix}{
    line-height: 1.65em;
  }
  .l-h-1d75x#{$sufix}{
    line-height: 1.75em;
  }
  .l-h-1d8x#{$sufix}{
    line-height: 1.8em;
  }
  .l-h-1d9x#{$sufix}{
    line-height: 1.9em;
  }
  .l-h-2x#{$sufix}{
    line-height: 2em;
  }

  .l-s-1x#{$sufix}{
    letter-spacing: 1px;
  }
  .l-s-2x#{$sufix}{
    letter-spacing: 2px;
  }
  .l-s-3x#{$sufix}{
    letter-spacing: 3px;
  }
  .l-s-4x#{$sufix}{
    letter-spacing: 4px;
  }

  .l-s-n-1x#{$sufix}{
    letter-spacing: -1px;
  }
  .l-s-n-2x#{$sufix}{
    letter-spacing: -2px;
  }
  .l-s-n-3x#{$sufix}{
    letter-spacing: -3px;
  }
  .l-s-n-4x#{$sufix}{
    letter-spacing: -4px;
  }

  .h-1x#{$sufix}{
    height: $line-height;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .h-2x#{$sufix}{
    height: $line-height * $font-size * 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .h-3x#{$sufix}{
    height: $line-height * $font-size * 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .h-4x#{$sufix}{
    height: $line-height * $font-size * 4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .text-u-c#{$sufix}{
    text-transform: uppercase;
  }
  .text-l-t#{$sufix}{
    text-decoration: line-through;
  }
  .text-u-l#{$sufix}{
    text-decoration: underline;
  }

  .text-ellipsis#{$sufix}{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }

  .text-shadow#{$sufix}{
    text-shadow: 0 1px 0 rgba(0,0,0,.1);
  }

}



@include util-text('');

@include media-breakpoint-down(md) {

  @include util-text('-down-md');

}


@include media-breakpoint-down(sm) {
  .display-1 {
    font-size: 4.5rem;
  }

  .display-2 {
    font-size: 4rem;
  }

  .display-3 {
    font-size: 3rem;
  }

  .display-4 {
    font-size: 2rem;
  }

  @include util-text('-down-sm');

}


@include media-breakpoint-down(xs) {
  @include util-text('-down-xs');
}

.t-ww-normal {
  word-wrap: normal;
  white-space: initial;
}
.t-ww-break-word {
  word-wrap: break-word;
  word-break: break-all;
  white-space:normal;
}

