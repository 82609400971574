/*
F5EB42 - sun inner
E4C74D - sun outer
FFFFFF - cloud inner
D4D4D2 - cloud outer
81C0D5 - parent outer
C0E6F6 - parent inner
FFFDF2 - moon inner
DEE1C5 - moon outer
FCFCFC - stars
*/

/////////////////////////////////////////////////
// Mixins
/////////////////////////////////////////////////
@mixin crater($top, $distance_x, $size) {
  content: '';
  position: absolute;
  top: $top;
  #{$direction_1}: $distance_x;
  width: $size;
  height: $size;
  background-color: #EFEEDA;
  border-radius: 100%;
  border: 3px solid #DEE1C5;
}

@mixin cloudBubble($top, $distance_x, $width, $height, $deg) {
  content: '';
  display: block;
  position: absolute;
  top: $top;
  #{$direction_2}: $distance_x;
  width: $width;
  height: $height;
  border: 3px solid #D4D4D2;
  border-radius: 100%;
  border-#{$direction_2}-color: transparent;
  border-bottom-color: transparent;
  @if ($rtl == true)  {
    transform: rotateZ(-$deg);  
  } @else {
    transform: rotateZ($deg);
  }
  background-color: #fff;
}

.day_night_switch {
  padding-top: 12px;
  padding-#{$direction_1}: 10px;
  text-align: center;

  /////////////////////////////////////////////////
  // Day State
  /////////////////////////////////////////////////
  .toggle {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 30px;
    border-radius: 40px;
    box-sizing: content-box;
  }

  .toggle:before,
  .toggle:after {
    content: '';
    display: table;
  }

  .toggle:after {
    clear: both;
  }

  .toggle-bg {
    position: absolute;
    width: 100%;  
    height: 100%;
    background-color: #C0E6F6;
    border-radius: 40px;
    border: 3px solid #81C0D5;
    transition: all .1s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    box-sizing: border-box;
  }

  .toggle-input {
    position: absolute;
    top: 0;
    #{$direction_1}: 0;
    width: 100%;  
    height: 100%;
    border: 1px solid red;
    border-radius: 40px;
    z-index: 2;
    opacity: 0;
  }

  .toggle-switch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    #{$direction_2}: 4px;
    width: 42px;
    height: 42px;
    background-color: #F5EB42;
    border: 4px solid #E4C74D;
    border-radius: 50%;
    transition: all .2s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    box-sizing: border-box;
  }

  .toggle-switch-figure {  
    position: absolute;
    bottom: 0;
    #{$direction_2}: 50%;
    display: block;
    width: 38px;
    height: 14px;
    border: 3px solid #D4D4D2;
    border-radius: 20px;  
    background-color: #fff;
    transition: all .12s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    &:after {
     @include cloudBubble(-10px,3px, 14px, 14px, 70deg);
    }
    &:before {
     @include cloudBubble(-12px, 10px, 20px, 20px, 30deg);
    }
  }

  .toggle-switch-figureAlt {
    @include crater(4px, 0px, 10px);
    @if ($rtl == true) {
      box-shadow: 
      -35px -9px 0 -3px #FCFCFC,
      -46px -6px 0 -4px #FCFCFC,
      -54px 4px 0 -1px #FCFCFC,
      -43px 7px 0 -4px #FCFCFC,
      -46px 16px 0 -3px #FCFCFC,
      -40px 26px 0 -2px #FCFCFC,
      -52px 22px 0 -4px #FCFCFC;
    }  @else {
      box-shadow: 
      35px -9px 0 -3px #FCFCFC,
      46px -6px 0 -4px #FCFCFC,
      54px 4px 0 -1px #FCFCFC,
      43px 7px 0 -4px #FCFCFC,
      46px 16px 0 -3px #FCFCFC,
      40px 26px 0 -2px #FCFCFC,
      52px 22px 0 -4px #FCFCFC;
    }  
    transition: all .12s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transform: scale(0);
  }

  .toggle-switch-figureAlt:before {
    @include crater(-2px, 18px, 16px);
  }

  .toggle-switch-figureAlt:after {
    @include crater(22px, 12px, 8px);
  }

  /////////////////////////////////////////////////
  // Night State
  /////////////////////////////////////////////////
  .toggle-input:checked ~ .toggle-switch {
    #{$direction_2}: calc(100% - 48px);
    border-color: #DEE1C5;
    background-color: #FFFDF2;
  }

  .toggle-input:checked ~ .toggle-bg {
    background-color: #484848;
    border-color: #202020;
  }

  .toggle-input:checked ~ .toggle-switch .toggle-switch-figure {
    margin-#{$direction_1}: 40px;
    opacity: 0;
    transform: scale(0.1);
  }

  .toggle-input:checked ~ .toggle-switch .toggle-switch-figureAlt {
    transform: scale(1);
  }
}

