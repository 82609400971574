body {
	overflow-x: hidden;

	background-image: url('../../images/bg.jpg');
	background-size: cover;
	

	background-repeat: no-repeat;
	background-position: 50% 0px;

	// transition: 0s linear;
	// transition-property: background-position;

	background-attachment: fixed;
}


@include media-breakpoint-down(sm) {
  body {
  	background-attachment: fixed;
  }
}