/*
Table 
*/
.table{
  .panel &{
    border-color: $table-border-color !important;
  }
  > tbody,
  > tfoot {
    > tr {
      > td,
      > th {
        padding-#{$direction_1}: 16px;
        padding-#{$direction_2}: 16px;
        border-color: $table-border-color;
      }
    }
  }
  > thead > tr > th{
    padding:10px 16px;
    border-color: $table-border-color;
  }
}
.table-condensed{
    thead>tr>th,
    tbody>tr>th,
    tfoot>tr>th,
    thead>tr>td,
    tbody>tr>td,
    tfoot>tr>td{
      padding: 5px;
    }
}
.table-bordered{
  border-color: $table-border-color;
}
.table-striped{
  > tbody {
    > tr{
      &:nth-child(odd){
        background-color: $table-striped-color;
        background-clip: padding-box;
      }
    }
  }
  > thead {
    > th{
      background-color: $table-striped-color;
      border-#{$direction_2}: 1px solid $table-border-color;
      &:last-child{
        border-#{$direction_2}: none
      }
    }
  }
}

.table-hover tr:hover,
.table tr.active,
.table td.active,
.table th.active{
  background-color: $min-black !important;
}
