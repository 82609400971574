/*
Base 
*/

html{
  background: transparent;

  @if ($rtl == true) {
    direction: rtl;
  }
}
body {
  color: $text-color;
  font-size: $font-size;
  background-color: $body-bg;

  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0 !important;
}

a {
  color: $url-color;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
}
a:hover,
a:focus {
  color: $hover-color;
  text-decoration: none;
}

button{
  color: inherit;
}

pre {
  color: inherit;
  background-color: $light;
  border-color: $border-color;
}

code{
  background-color: $border-color;
}

blockquote{
  border-color: $border-color;
}

small{
  font-size: 90%;
}

sup{
  position: relative; 
  top: -1em; 
  font-size: 75%
}

.pull-center{
  position: absolute;
  #{$direction_1}: 50%;
  top: 50%;
  @if ($rtl == true) {
    transform: translateX(50%) translateY(-50%);
    -ms-transform: translateX(50%) translateY(-50%);
  } @else {
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
  }
}

.close{
  font-size: 1.3rem;
}

.img-responsive{
  width: 100%;
  height: auto;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.text-center-important{
  text-align: center !important;
}

.text-left-important{
  text-align: left !important;
}

.text-right-important{
  text-align: right !important;
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}

.pull-none {
  float: none;
}

.center-block{
  margin: 0 auto;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

@mixin pulls($size, $sufix) {
  .pull-none-#{$size}#{$sufix}{
    float: none !important;
  }
  .pull-right-#{$size}#{$sufix}{
    float: right !important;
  }
  .pull-left-#{$size}#{$sufix}{
    float: left !important;
  }
}

@include media-breakpoint-down(lg){
  @include pulls('lg', '');
}

@include media-breakpoint-down(md){
  @include pulls('md', '');
}

@include media-breakpoint-down(sm){
  @include pulls('sm', '');
}

@include media-breakpoint-down(xs){
  @include pulls('xs', '');
}


@include media-breakpoint-up(lg){
  @include pulls('lg', '-up');
}

@include media-breakpoint-up(md){
  @include pulls('md', '-up');
}

@include media-breakpoint-up(sm){
  @include pulls('sm', '-up');
}

@include media-breakpoint-up(xs){
  @include pulls('xs', '-up');
}
