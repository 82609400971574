.thumbnail {
	width: 100%;
	margin-top: 15px;
	margin-bottom: 15px;
	height: 200px;
	overflow: hidden;
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(../../images/no-image.jpg);
	border: 1px solid $min-black;
	
	&.type_video,
	&.type_file {
		background: none;
	}

	a.remove {
		display: none;
		position: absolute;
		top: 5px;
		#{$direction_2}: 5px;
		cursor: pointer;
		padding: 0;
		padding: 0 5px;
		text-align: center;
		color: $white;
	}
	a.edit {
		@extend a.remove;
		#{$direction_1}: 5px;
		#{$direction_2}: auto;
		i {
			position: relative;
			right: -2px;
		}
	}
	&:hover {
		a {
			display: inline;
		}
	}

	div.icon_thumb {
		color: #545a5f;
		text-align: center;
		i.fa {
			font-size: 42px;
			line-height: 120px;
		}

		position: absolute;
		left:0; right:0;
		margin:auto;
		top: 50%;
		transform: translateY(-50%);
	}
	&[data-edit] {
		cursor: pointer;
	}
}



.thumbnails-grid {
	padding: 10px;
	list-style: none;

	.thumbnail_li {
		// float: #{$direction_1};
		// width: 33.33%;
		padding: ceil($grid-gutter-width-base / 2);

		.thumbnail {
			width: 100%;
			height: 200px;
			margin: 0;
		}
	}
	.sortable-placeholder {
		@extend .thumbnail_li;
		float: #{$direction_1};
		border: dashed 1px #ddd;
		height: 210px;
		
	}
}