

.section-title {
	margin: 0 0 $grid-gutter-width-base * 1.5;
	text-align: center;
	span {
		padding-bottom: ceil($grid-gutter-width-base / 3);
		border-bottom: 2px $accent-1 solid;
	}
}

.firstfold {
	margin: auto;
	position: relative;
	min-height: $ff_slider_height;

	// @include box-shadow(0 10px 0 0 $accent-3);


	#firstfold-carousel {
		min-height: $ff_slider_height;
		height: 100%;

		.owl-stage-outer,
		.owl-stage,
		.owl-item {
			min-height: $ff_slider_height;
			height: 100%;
		}

		.slide {
			background-position: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			width: 100%;
			min-height: $ff_slider_height;
			height: 100%;

			.slideContent {
				position: absolute;
				top: 0;
				// transform: translateY(-50%);
				width: 100%;
				text-align: center;
				color: $black;
				@include text-shadow(2px 2px 0 0 $dark);

				font-size: 1.2em;
				line-height: 20px;

				.container_wrapper {
					background: rgba($white, .3);
				}
				.container {
					margin: auto;
					h1 {
						text-transform: uppercase;
						margin: auto;
						font-size: 1.5em;
						margin-bottom: 30px;
					}

					p {
						margin: 0;
					}

					.btn-slider {
						font-size: 1em;
						margin-top: $grid-gutter-width-base;
						border: 2px solid $accent-1;
						background: rgba($accent-1, .7);
						color: $black;
						border-radius: 0px;

						padding: 0 ceil($grid-gutter-width-base / 2);
						line-height: $grid-gutter-width-base * 1.5;
						@include transition(all 600ms ease-in-out);

						&:hover {
							background: rgba($accent-1, 1);
						}
					}
				}
			}
		}
	}
	.owl-dots-wrapper {
		position: absolute;
		top: 15px;
		#{$direction-1}: 10px;
		z-index: 1;
	}


	.bars{
		width: 100%;
	  	position: absolute;
	  	bottom: 0;
	  	z-index: 1;

	  	.mask {
	  		width: 100%;
	  	}

		.ground {
	    	fill:  $accent-1;
	    	opacity: .25;
	    	// mix-blend-mode: multiply;
	  	}
		
		.bar{
	    	width: 50%;
	    	height: 100%;
	    	fill: $accent-1;
	    	x: 30%;
	    	opacity: .5;

	    	&.bar1 {
	    		fill: $cyan;
	    		width: 40%;
	    	}

		    &.bar2{
		    	fill: $blue;
		    } 
		    
		    &.bar3{
		    	x: 20%;
		    	width: 10%;
		    	fill: $indigo;
		    	animation-duration: 15s;
		    	animation-iteration-count: infinite;
		    	// animation-name: slide_1;
		    }
		    &.bar4 {
		    	fill: $blue;
		    	x: 40%;
		    	animation-duration: 15s;
		    	animation-iteration-count: infinite;
		    	// animation-name: slide_2;
		    }
		}
	}

	&:hover {
		.bars .bar {
			&.bar3{
				animation-name: slide_1;
			}

			&.bar4{
				animation-name: slide_2;
			}
		}
	}
}

.after_firstfold {
	position: relative;
	z-index: 2;
	top: -50px;
	margin-bottom: -50px;
	.bars-cover {
		margin-bottom: -10px;
		max-width: 100%;
		.grey-200 {
			fill: $grey-200;
		}
		&.bottom {
			position: relative;
			top: -5px;
			margin-bottom: -5px;
		}
	}
	.boxes {
		// position: relative;
		transform: translateY(-110px);
		&.m-b-correction {
			margin-bottom: -110px;
		}
	}

	.after_blocks {
		position: relative;
		&.m-t-correction {
			clear: both;
			position: relative;
			top: -110px;
			margin-bottom: -110px;
		}
	}

	.welcome-block {
		position: relative;		
		min-height: 450px;
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba($accent-1, .1);
			mix-blend-mode: multiply;
		}

		@include text-shadow(2px 2px 0 rgba($black , .15));

		h1 {
			background: rgba($accent-1, .25);
			@include text-shadow(3px 3px 0 rgba($black , .1));
		}
	}
}

.reviews {

	// overflow: hidden;

	position: relative;
	top: -50px;
	margin-bottom: -50px;

	.reviews_content {
		@include background-image(linear-gradient(to bottom, $accent-1 0%,$accent-2 100%));
		@include transform(skew(0, -4.5deg));
		position: relative;
		z-index: 10;

		.add-review-btn {
			position: absolute;
			top: 15px;
			#{$direction-2}: 15px;
			z-index: 1
		}
	}

	.owl-dots-wrapper {
		position: absolute;
		top: 15px;
		#{$direction-1}: 10px;
		z-index: 1;
	}
}


.contacts {
	top: -50px;
	margin-bottom: -50px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	//background-image: pxt-image-url("contacts.jpg");


	.informations {
		.email
		.tel,
		.fax {
			letter-spacing: 3px;
			div {
				direction: ltr;
			}
		}
	}

	.contact_submit {
		// font-size: 1.5em;

		.form-group {
			.form-control {
				border-color: $grey-200;
				border-radius: 0;
				@include box-shadow(none);
			}

			.input-group-addon {
				// @extend .form-control;
				border-color: $grey-200;
				border-radius: 0;
				@include box-shadow(none);
			}

			.selectize-input {
				border-color: $grey-200;
				border-radius: 0;
				@include box-shadow(none);
				
				padding-top: 3px;
				padding-bottom: 3px;
				input {
					width: calc(100% - 30px) !important;
				}
			}

			.selectize-dropdown {
				position: absolute;
				border-color: $grey-200;
				border-radius: 0;
				@include box-shadow(none);
			}

			.btn {
				width: 100%;
				border-radius: 0px;
			}
		}
	}
}

.after_contacts-map {
	min-height: 400px;
	position: relative;

	&::before {
		position: absolute;
		content: '';
		width: 100%;
		height: 30%;
		top: -2px;
		@include background-image(linear-gradient(to bottom,  $white 5%,rgba($white,0) 100%));
		z-index: 10;
	}

}



// animations

@keyframes slide_1 {
  0% {
    transform: translateX(2%);
  }
  25% {
    transform: translateX(-12%);
  }
  50% {
    transform: translateX(40%);
    width: 20%;
  }
  65% {
    transform: translateX(60%);
  }
  100% {
    transform: translateX(2%);
  }
}

@keyframes slide_2 {
  0% {
    transform: translateX(-12%);
  }
  15% {
    transform: translateX(32%);
    width: 10%;
  }
  40% {
    transform: translateX(10%);
    width: 40%;
  }
  55% {
    transform: translateX(20%);
    width: 15%;
  }
  100% {
    transform: translateX(-12%);
  }
}
