/*
Nav plus
*/
.nav-border{
	.nav > li.active:after{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		border-#{$direction_1}-color: inherit;
		border-#{$direction_1}-width: 3px;
		border-#{$direction_1}-style: solid;
	}
}

.nav-color{

	.nav {
		> li {
			&.active > a:before{
				width: 100%;
				#{$direction_1}: 0;
				#{$direction_2}: 0;
			}

			> a {

				> *{
					position: relative;
				}
				&:before{
					content: '';
					position: absolute;
					z-index: 0;
					#{$direction_1}: 1rem;
					width: $aside-nav-height;
					height: 100%;
					border: $aside-nav-height/2 solid transparent;
					@include transition(width 0.4s ease 0s);
				}
				&[class*="b-"]:before,
				&[class*=" b-"]:before{
					border-color: inherit;
				}

			}

			li  {
				a {
					&[class*="b-"],
					&[class*=" b-"]{
						border-top-width: 3px;
						border-top-style: solid;

						border-radius: 0;
						@include transition(border 0.4s ease 0s);
					}
				}

				&.active {
					//border-#{$direction_1}: 2px solid $light-black;
					position: relative;
					&:after {
						content: '';
						position: absolute;
						top: 8px;
						#{$direction_1}: 0;
						width: 0;
						height: 0;
						border-style: solid;
						@if($rtl == true) {
							border-width: 6px 8px 6px 0;
							border-color: transparent $black-white transparent transparent;
						} @else {
							border-width: 6px 0 6px 8px;
							border-color: transparent transparent transparent $black-white;
						}
					}
					> a {
						border-top-width: 0.3rem;
					}

					&.has-children:after {
						display: none;
					}
				}
			}
		}
	}
}

.nav-stacked{
	.nav{
		> li {
			&:not(.nav-header){
				padding: $aside-nav-stacked-margin 0.5rem;
			}
			> a{
				line-height: $aside-nav-stacked-height;
				padding: 0 0.5rem;
				.nav-icon{
					width: $aside-nav-stacked-height;
				}
				.nav-text{
					padding: ($aside-nav-stacked-height - 1.125)/2 0;
				}
				&:before{
					#{$direction_1}: 0.5rem;
					border-radius: 3px;
					width: $aside-nav-stacked-height;
					border-width: $aside-nav-stacked-height/2;
				}
			}
			li {
				background: transparent;
				.nav-icon{
					margin-#{$direction_2}: $aside-nav-icon-margin / 2;
				}
				a {
					padding-#{$direction_1}: 0.1rem + $aside-nav-icon-margin;
				}
				li a{
					padding-#{$direction_1}: 0.5rem + $aside-nav-icon-margin;
				}
				li li a{
					padding-#{$direction_1}: 1rem + $aside-nav-icon-margin;
				}
			}
		}
		li{
			a{
				border-radius: 3px;
			}
		}
	}
	&.nav-stacked-rounded{
		.nav > li > a,
		.nav > li > a:before{
			border-radius: 500px;
		}
		.nav li li a,
		.nav li.active{
			background: transparent;
		}
	}
}

.nav-light{
	.nav{
		.nav-icon i svg,
		.nav-icon i img{
			display:block;
		}
	}
}

.nav-center{
	text-align: center;
	.nav-icon{
		display: none;
	}
	.nav-caret{
		position: absolute;
		#{$direction_2}: 10px;
	}
	.nav-label{
		position: absolute;
		padding-#{$direction_1}: 10px;
	}
	.nav a{
		padding-#{$direction_1}: 16px !important;
	}
	.nav-fold .pull-#{$direction_1}{
		float: none !important;
		margin-bottom: 10px;
		display: inline-block;
	}
}

@include media-breakpoint-up(lg){
	.folded {
		&,
		.scroll{
			width: $aside-folded-width;
		}
		&.md{
			&,
			.scroll{
				width: $aside-folded-md-width;
			}
		}
		.hidden-folded{
			display: none;
		}
		.nav li ul{
			display: none;
		}
		.nav > li > a{
			text-align: center;
			padding-#{$direction_1}: 4px;
    		padding-#{$direction_2}: 4px;
			.nav-caret,
			.nav-text{
				display: none;
				padding-#{$direction_1}: 4px;
				padding-#{$direction_2}: 4px;
			}
			.nav-label{
				position: absolute;
				top: -6px;
				#{$direction_2}: 6px;
			}
			.nav-icon{
				float: none;
				line-height: $aside-nav-folded-height;
				margin: 0;
				position: relative;
				width: $aside-nav-folded-height;
			}
			&:before{
				#{$direction_1}: 0;
			}
		}
		.nav li.active{
			background: transparent;
		}
		&.show-text{
			.nav > li > a{
				.nav-text{
					display:block;
					margin-top: -18px;
					font-size: 0.85em;
				}
			}
		}
		.navbar{
			text-align: center;
			padding-#{$direction_1}: 4px;
			padding-#{$direction_2}: 4px;
		}
		.navbar-brand{
			float: none;
			margin: 0;
		}
		.nav-fold{
			padding: 8px 16px;
			.pull-#{$direction_1}{
				float: none !important;
				margin: 0;
				display: block;
				text-align: center;
				img{
					margin: 0 auto;
				}
			}
		}
		.nav-stacked-rounded .nav > li > a{
			width: $aside-nav-folded-height;
			margin: 0 auto;
			padding: 0;
			.nav-icon{
				width: $aside-nav-folded-height;
			}
		}
		.nav-color .nav > li > a{
			&:before{
				width: 100%;
				border-width: $aside-nav-folded-height/2;
				background-image: linear-gradient(to #{$direction_2} bottom, rgba(0, 0, 0, 0), rgba(0,0,0,0.15));
			}
		}
	}

	.folded.nav-expand{
		@include transition(width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1));
		@include backface-visibility(hidden);
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		&:hover,
		&:focus,
		&.active{
			&,
			.scroll{
				width: $aside-width !important;
			}
			> *{
				width: $aside-width;
			}
			.hidden-folded{
				display: block;
			}
			.hidden-folded.inline{
				display: inline-block;
			}
			.nav li ul{
				display: inherit;
			}
			.nav > li > a{
				width: auto !important;
				margin: 0 !important;
				text-align: #{$direction_1};
				padding-#{$direction_1}: 1rem;
				padding-#{$direction_2}: 1rem;
				.nav-caret,
				.nav-text{
					display: block;
				}
				.nav-text{
					font-size: 1em;
					margin: 0;
				}
				.nav-label{
					position: static;
				}
				.nav-icon{
					float: #{$direction_1};
					width: $aside-nav-height;
					line-height: $aside-nav-height;
					margin-#{$direction_1}: 0;
					margin-#{$direction_2}: $aside-nav-icon-margin;
				}
			}
			.navbar{
				text-align: #{$direction_1};
				padding-#{$direction_1}: 1rem;
				padding-#{$direction_2}: 1rem;
			}
			.nav-color{
				.nav > li > a:before{
					width: $aside-nav-height;
					#{$direction_1}: 1rem;
					border-width: $aside-nav-height/2;
				}
				.nav > li.active > a:before{
					width: 100%;
					#{$direction_1}: 0;
				}
			}
			.nav-stacked{
				.nav{
					> li {
						> a{
							padding: 0 0.5rem;
							&:before{
								#{$direction_1}: 0.5rem;
								width: $aside-nav-stacked-height;
								border-width: $aside-nav-stacked-height/2;
							}
							.nav-icon{
								width: $aside-nav-stacked-height;
								line-height: $aside-nav-stacked-height;
							}
						}
					}
				}
			}
			.nav-fold{
				padding: 12px 16px;
				.pull-#{$direction_1}{
					float: #{$direction_1} !important;
					img{
						height: auto;
						&.w-40{
							width: 40px;
						}
						&.w-48{
							width: 48px;
						}
					}
				}
			}
		}
	}

	.nav-dropup,
	.folded.nav-dropdown{
		&,
		[flex],
		[data-flex],
		> *,
		.row-body{
			overflow: visible !important;
		}
		.nav > li {
			&:hover,
			&:focus{
				> ul {
					display: block;
				}
			}
			> ul {
				display: none;
				overflow: visible;
				max-height: 999px;
				color: $dark-white;
				background-color: $black;
				border-radius: 2px;
				@if ($rtl == true) {
					@include border-right-radius(0);
				} @else {
					@include border-left-radius(0);
				}
				box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
				position: absolute;
				#{$direction_1}: 100%;
				top: 0;
				padding: 6px 0;
				a {
					padding-#{$direction_1}: 16px !important;
					padding-#{$direction_2}: 16px !important;
					min-width: 160px;
				}
				li.active ul {
					display: block;
				}
				.nav-text{
					padding: 6px 0 !important;
				}
			}
			.nav-mega{
				width: 320px;
				> li{
					width: 160px;
					float: #{$direction_1};
				}
				&.nav-mega-3{
					width: 480px;
				}
			}
		}
	}
}
