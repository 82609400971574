.c-alias {
	cursor: alias;
}

.c-all-scroll {
	cursor: all-scroll;
}

.c-auto {
	cursor: auto;
}

.c-cell {
	cursor: cell;
}

.c-context-menu {
	cursor: context-menu;
}

.c-col-resize {
	cursor: col-resize;
}

.c-copy {
	cursor: copy;
}

.c-crosshair {
	cursor: crosshair;
}

.c-default {
	cursor: default;
}

.c-e-resize {
	cursor: e-resize;
}

.c-ew-resize {
	cursor: ew-resize;
}

.c-grab {
	cursor: grab;
}

.c-grabbing {
	cursor: grabbing;
}

.c-help {
	cursor: help;
}

.c-move {
	cursor: move;
}

.c-n-resize {
	cursor: n-resize;
}

.c-ne-resize {
	cursor: ne-resize;
}

.c-nesw-resize {
	cursor: nesw-resize;
}

.c-ns-resize {
	cursor: ns-resize;
}

.c-nw-resize {
	cursor: nw-resize;
}

.c-nwse-resize {
	cursor: nwse-resize;
}

.c-no-drop {
	cursor: no-drop;
}

.c-none {
	cursor: none;
}

.c-not-allowed {
	cursor: not-allowed;
}

.c-pointer {
	cursor: pointer;
}

.c-progress {
	cursor: progress;
}

.c-row-resize {
	cursor: row-resize;
}

.c-s-resize {
	cursor: s-resize;
}

.c-se-resize {
	cursor: se-resize;
}

.c-sw-resize {
	cursor: sw-resize;
}

.c-text {
	cursor: text;
}

.c-vertical-text {
	cursor: vertical-text;
}

.c-w-resize {
	cursor: w-resize;
}

.c-wait {
	cursor: wait;
}

.c-zoom-in {
	cursor: zoom-in;
}

.c-zoom-out {
	cursor: zoom-out;
}

.c-initial {
	cursor: initial;
}

.c-inherit {
	cursor: inherit;
}
